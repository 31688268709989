import { _AFTER_NAVIGATION } from 'Actions/ActionTypes';
import { put, takeEvery } from 'redux-saga/effects';
import { clearPageErrorAction } from 'Actions/Notification/PageErrorActions';
import { clearPendingNavigationAction } from 'Actions/Navigation/NavigationActions';

function* navigationSaga() {
  yield put(clearPageErrorAction());
  yield put(clearPendingNavigationAction());
}

export function* watchNavigation() {
  yield takeEvery(_AFTER_NAVIGATION, navigationSaga);
}
