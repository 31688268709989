import Modal from 'Components/Util/Modal';
import React from 'react';
import { ApplicationList } from '../Presentation/ApplicationList';
import { ApplicationIdentity } from 'Models/Application/ApplicationStore';
import { ApplicationDeploymentDetails } from 'Models/Application/ApplicationDeployment';
import { RootState } from 'Reducers/RootReducer';
import { applicationInstallationSelectApplicationAction } from 'Actions/Form/ApplicationInstallationFormActions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

type Props = {
  close: () => void;
  applications: ApplicationIdentity[];
  installedApplications: ApplicationDeploymentDetails[];
  selectApplication: (app: ApplicationIdentity) => void;
};

export function _ApplicationListFormPage(props: Props) {
  function isInstalled(appId: string) {
    return props.installedApplications.some(deployment => deployment.appId == appId);
  }
  return (
    <Modal show={true} title="Install Application" onCancel={props.close} large={true}>
      <ApplicationList
        applications={props.applications.filter(app => !isInstalled(app.id))}
        onAppClick={appId => {
          props.selectApplication(props.applications.find(app => app.id === appId)!);
        }}
      />
    </Modal>
  );
}

function mapStateToProps(state: RootState) {
  return {
    applications: state.applicationList,
    installedApplications: state.device.applications,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    selectApplication: (app: ApplicationIdentity) =>
      dispatch(applicationInstallationSelectApplicationAction(app)),
  };
}

export const ApplicationListFormPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ApplicationListFormPage);
