import { put, select, call, takeLatest } from 'redux-saga/effects';
import { RootState } from 'Reducers/RootReducer';
import {
  GetUserSystemPermissionsResponse,
  getUserSystemPermissions,
} from 'Services/AuthorizationProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { FETCH_USER_SYSTEM_PERMISSIONS } from 'Actions/ActionTypes';
import { trackFetchAction, clearFetchAction } from 'Actions/Loading/LoadingActions';
import {
  setUserSystemPermissionsAction,
  FetchUserSystemPermissionsAction,
} from 'Actions/User/UserActions';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { Permissions } from 'Models/Authorization';

export function* fetchUserSystemPermissionsSaga(action: FetchUserSystemPermissionsAction) {
  yield put(trackFetchAction('fetchUserSystemPermissions'));
  try {
    const token = yield select((state: RootState) => state.authentication.token);
    const response: GetUserSystemPermissionsResponse = yield call(
      getUserSystemPermissions,
      token,
      action.systemId,
      action.userId
    );

    let permissions: Permissions = {};
    for (let perm of response.response) {
      permissions[perm.permissionName] = perm.hasAccess;
    }

    yield put(setUserSystemPermissionsAction(permissions));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      yield put(
        sendToastNotificationAction(
          'warning',
          "An error occurred while fetching the user's system permissions."
        )
      );
    }
  } finally {
    yield put(clearFetchAction('fetchUserSystemPermissions'));
  }
}

export function* watchFetchUserSystemPermissionsSaga() {
  yield takeLatest(FETCH_USER_SYSTEM_PERMISSIONS, fetchUserSystemPermissionsSaga);
}
