import { FETCH_USER_LIST } from 'Actions/ActionTypes';
import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import { put, select, call } from 'redux-saga/effects';
import { trackFetchAction, clearFetchAction } from 'Actions/Loading/LoadingActions';
import { RootState } from 'Reducers/RootReducer';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { fetchPrincipalsForSystems, GetPrincipalsResponse } from 'Services/SystemUsersProxy';
import {
  FetchUserListAction,
  receiveUserListFromApiAction,
  receiveRolesForSystemAction,
} from 'Actions/Users/UserActions';
import {
  getRolesRequest,
  getSystemRoles,
  GetRolesResponse,
  GetSystemRoles,
} from 'Services/AuthorizationProxy';
import { LightPrincipal } from 'Components/Users/Types';

export function* fetchUserListSaga(action: FetchUserListAction) {
  yield put(trackFetchAction('fetchUserList'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    const getPrincipalResponse: GetPrincipalsResponse = yield call(
      fetchPrincipalsForSystems,
      token,
      action.systemId
    );
    const getRolesResponse: GetRolesResponse = yield call(getRolesRequest, token, action.systemId);
    const systemRoles: GetSystemRoles = yield call(getSystemRoles, token, action.systemId);
    yield put(receiveUserListFromApiAction(assembleUsers(getPrincipalResponse, getRolesResponse)));
    yield put(receiveRolesForSystemAction(assembleRoles(systemRoles)));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      yield put(
        sendToastNotificationAction('warning', 'An error occurred while fetching the user list.')
      );
    }
  } finally {
    yield put(clearFetchAction('fetchUserList'));
  }
}

export function* watchFetchUserList() {
  yield cancelOnNavigationEffect(FETCH_USER_LIST, fetchUserListSaga);
}

function toTitleCase(role: string) {
  return role.replace(/\w\S*/g, function(roleName) {
    return roleName.charAt(0).toUpperCase() + roleName.substr(1).toLowerCase();
  });
}

function assembleUsers(
  getPrincipalResponse: GetPrincipalsResponse,
  getRolesResponse: GetRolesResponse
): LightPrincipal[] {
  const roleMap = getRolesResponse.response.reduce(
    (acc: Map<string, string>, principalRole: { principalId: string; role: string }) => {
      acc.set(principalRole.principalId, principalRole.role);
      return acc;
    },
    new Map<string, string>()
  );

  return getPrincipalResponse.response.map(r => ({
    id: r.id,
    name: r.name,
    role: roleMap.get(r.id) || '',
    type: r.type,
  }));
}

function assembleRoles(systemRoles: GetSystemRoles): { id: string; name: string }[] {
  return systemRoles.response.map(systemRole => ({
    id: systemRole.role,
    name: toTitleCase(systemRole.role.replace('_', ' ')),
  }));
}
