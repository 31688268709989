import {
  CLEAR_DEVICE_ENROLLMENT_FORM,
  SET_DEVICE_ENROLLMENT_FORM_ERRORS,
  SUBMIT_DEVICE_ENROLLMENT_FORM,
  UPDATE_DEVICE_ENROLLMENT_FORM,
} from 'Actions/ActionTypes';
import {
  DeviceEnrollmentFormReducerState,
  DeviceEnrollmentFormReducerErrorState,
} from 'Reducers/Form/DeviceEnrollmentFormReducer';

export type UpdateDeviceEnrollmentFormAction = {
  type: typeof UPDATE_DEVICE_ENROLLMENT_FORM;
  form: Partial<DeviceEnrollmentFormReducerState>;
};

export function updateDeviceEnrollmentFormAction(
  form: Partial<DeviceEnrollmentFormReducerState>
): UpdateDeviceEnrollmentFormAction {
  return {
    type: UPDATE_DEVICE_ENROLLMENT_FORM,
    form,
  };
}
export type ClearDeviceEnrollmentFormAction = {
  type: typeof CLEAR_DEVICE_ENROLLMENT_FORM;
};

export function clearDeviceEnrollmentFormAction(): ClearDeviceEnrollmentFormAction {
  return {
    type: CLEAR_DEVICE_ENROLLMENT_FORM,
  };
}

export type SubmitDeviceEnrollmentFormAction = {
  type: typeof SUBMIT_DEVICE_ENROLLMENT_FORM;
  systemId: string;
  form: DeviceEnrollmentFormReducerState;
  callback: () => void;
};

export function submitDeviceEnrollmentFormAction(
  systemId: string,
  form: DeviceEnrollmentFormReducerState,
  callback: () => void = () => undefined
): SubmitDeviceEnrollmentFormAction {
  return {
    type: SUBMIT_DEVICE_ENROLLMENT_FORM,
    systemId,
    form,
    callback,
  };
}

export type SetDeviceEnrollmentFormErrorsAction = {
  type: typeof SET_DEVICE_ENROLLMENT_FORM_ERRORS;
  errors: Partial<DeviceEnrollmentFormReducerErrorState>;
};

export function setDeviceEnrollmentFormErrorsAction(
  errors: Partial<DeviceEnrollmentFormReducerErrorState>
): SetDeviceEnrollmentFormErrorsAction {
  return {
    type: SET_DEVICE_ENROLLMENT_FORM_ERRORS,
    errors,
  };
}
