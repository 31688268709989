import { RouteComponentProps } from 'react-router-dom';
import { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import * as React from 'react';
import { _afterNavigationAction } from 'Actions/Navigation/NavigationActions';
import { Dispatch } from 'redux';
// This component watches the redux store to trigger history.push() events.

type RoutingUpdaterProps = {
  shouldSendRouteUpdate: boolean;
  completeNavigation: () => void;
  navigationCompletionCallback?: () => void;
  path: string;
};

function _RoutingUpdater(props: RouteComponentProps<any> & RoutingUpdaterProps) {
  useEffect(() => {
    if (props.shouldSendRouteUpdate) {
      props.history.push(props.path);
      // Invoking the callback from here ensures that the update occurs on the render thread.
      if (props.navigationCompletionCallback) {
        props.navigationCompletionCallback();
      }
      props.completeNavigation();
    }
  }, [props.shouldSendRouteUpdate]);

  return <Fragment />;
}

function mapStateToProps(state: RootState) {
  return {
    path: state.navigation.pendingNavigation,
    shouldSendRouteUpdate:
      // The navigation action has been acknowledged by the user.
      state.navigation.shouldSendNavigation ||
      // There are no pending changes. The route can be updated immediately.
      (!state.pendingChanges.dirty && state.navigation.pendingNavigation !== ''),

    navigationCompletionCallback: state.navigation.navigationCompletionCallback,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    completeNavigation: () => dispatch(_afterNavigationAction()),
  };
}

export const RoutingUpdater = connect(mapStateToProps, mapDispatchToProps)(_RoutingUpdater);
