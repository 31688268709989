import { cancel, fork, take } from 'redux-saga/effects';
import { _AFTER_NAVIGATION } from 'Actions/ActionTypes';
import { AnyAction } from 'redux';

export function* cancelOnNavigationSaga(pattern: string, saga: any, ...args: any[]) {
  let lastTask;
  while (true) {
    const action: AnyAction = yield take([pattern, _AFTER_NAVIGATION]);
    if (lastTask) {
      yield cancel(lastTask); // cancel is no-op if the task has already terminated
    }
    if (action.type !== _AFTER_NAVIGATION) {
      lastTask = yield fork(saga, ...args.concat(action));
    }
  }
}

export function cancelOnNavigationEffect(pattern: string, saga: any, ...args: any[]) {
  return fork(cancelOnNavigationSaga, pattern, saga, ...args);
}
