import { fetchDeviceAction } from 'Actions/Device/DeviceActions';
import {
  fetchDeviceDetailsAction,
  fetchFirmwareInfoAction,
  fetchDeviceFirmwareUpgradeDetailsAction,
} from 'Actions/Device/DeviceDetailsActions';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { DeviceSummary } from './DeviceSummary/DeviceSummary';
import { createDeviceJobAction } from '../../Actions/DeviceJobs/DeviceJobsActions';
import { CreateDeviceJob } from '../../Models/DeviceJobs/DeviceJobs';
import { SystemInfo } from 'Models/System/System';
import { FirmwareInfo } from 'Models/Device/FirmwareInfo';
import { fetchSystemFeatureFlagsAction } from 'Actions/FeatureFlags/FeatureFlagsActions';
import { FirmwareUpgradeDetails } from 'Models/Device/FirmwareUpgradeDetails';
import { useTimer } from 'Utilities/useTimer';
import { DeviceInfo } from 'Models/Device/DeviceInfo';

type OwnProps = {
  serialNumber: string;
  systemId: string;
};

type Props = OwnProps & {
  deviceInfo: DeviceInfo | null;
  systemInfo: SystemInfo | null;
  device: EnrolledDevice | null;
  firmwareInfo: FirmwareInfo | null;
  firmwareUpgradeDetails: FirmwareUpgradeDetails | null;
  featureFlags: Map<string, boolean>;
  fetchDevice: (systemId: string, serialNumber: string, silent: boolean) => void;
  fetchFirmwareInfo: (systemId: string, serialNumber: string) => void;
  createDeviceJob: (systemId: string, serialNumber: string, job: CreateDeviceJob) => void;
  fetchDeviceFirmwareUpgradeDetails: (systemId: string, serialNumber: string) => void;
  fetchFeatureFlags: (systemId: string) => void;
  fetchDeviceDetails: (systemId: string, serialNumber: string) => void;
};

export function _DeviceDetailsSummaryPage(props: Props) {
  function refreshDeviceData() {
    props.fetchDeviceDetails(props.systemId, props.serialNumber);
    props.fetchDevice(props.systemId, props.serialNumber, false);
    props.fetchFirmwareInfo(props.systemId, props.serialNumber);
    props.fetchDeviceFirmwareUpgradeDetails(props.systemId, props.serialNumber);
  }

  useEffect(refreshDeviceData, [props.serialNumber, props.systemId]);
  useEffect(() => {
    props.fetchFeatureFlags(props.systemId);
  }, [props.systemId]);
  useTimer(() => props.fetchDevice(props.systemId, props.serialNumber, true), 5000);
  useTimer(() => props.fetchFirmwareInfo(props.systemId, props.serialNumber), 5000);
  useTimer(() => props.fetchDeviceFirmwareUpgradeDetails(props.systemId, props.serialNumber), 5000);

  return (
    <DeviceSummary
      deviceInfo={props.deviceInfo}
      systemInfo={props.systemInfo}
      device={props.device}
      firmwareInfo={props.firmwareInfo}
      firmwareUpgradeDetails={props.firmwareUpgradeDetails}
      featureFlags={props.featureFlags}
      createDeviceJob={props.createDeviceJob}
    />
  );
}

function mapStateToProps(state: RootState) {
  return {
    systemInfo: state.system.info,
    device: state.device.enrollment,
    deviceInfo: state.device.deviceInfo,
    firmwareInfo: state.device.firmwareInfo,
    firmwareUpgradeDetails: state.device.firmwareUpgradeDetails,
    featureFlags: state.featureFlags,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchDevice: (systemId: string, serialNumber: string, silent: boolean) =>
      dispatch(fetchDeviceAction(systemId, serialNumber, silent)),
    fetchDeviceDetails: (systemId: string, serialNumber: string) =>
      dispatch(fetchDeviceDetailsAction(systemId, serialNumber)),
    fetchFirmwareInfo: (systemId: string, serialNumber: string) =>
      dispatch(fetchFirmwareInfoAction(systemId, serialNumber)),
    createDeviceJob: (systemId: string, serialNumber: string, job: CreateDeviceJob) =>
      dispatch(createDeviceJobAction(systemId, serialNumber, job)),
    fetchDeviceFirmwareUpgradeDetails: (systemId: string, serialNumber: string) =>
      dispatch(fetchDeviceFirmwareUpgradeDetailsAction(systemId, serialNumber)),
    fetchFeatureFlags: (systemId: string) => dispatch(fetchSystemFeatureFlagsAction(systemId)),
  };
}

export const DeviceDetailsSummaryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(_DeviceDetailsSummaryPage);
