import {
  FetchSystemFeatureFlagsAction,
  setSystemFeatureFlagsAction,
} from 'Actions/FeatureFlags/FeatureFlagsActions';
import { FETCH_SYSTEM_FEATURE_FLAGS } from 'Actions/ActionTypes';
import { put, select, call, takeLatest } from 'redux-saga/effects';
import { trackFetchAction, clearFetchAction } from 'Actions/Loading/LoadingActions';
import { RootState } from 'Reducers/RootReducer';
import { GetFeatureFlagsResponse, getSystemFeatureFlags } from 'Services/SystemsProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';

export function* fetchSystemFeatureFlagsSaga(action: FetchSystemFeatureFlagsAction) {
  yield put(trackFetchAction('fetchSystemFeatureFlags'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    const response: GetFeatureFlagsResponse = yield call(
      getSystemFeatureFlags,
      token,
      action.systemId
    );
    const featureFlags = new Map<string, boolean>(Object.entries(response.response));
    yield put(setSystemFeatureFlagsAction(featureFlags));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occurred while fetching the system features.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('fetchSystemFeatureFlags'));
  }
}

export function* watchFetchSystemFeatureFlagsSaga() {
  yield takeLatest(FETCH_SYSTEM_FEATURE_FLAGS, fetchSystemFeatureFlagsSaga);
}
