import {
  FETCH_FIRMWARE_UPGRADE_HISTORY,
  RECEIVE_FIRMWARE_UPGRADE_HISTORY,
} from 'Actions/ActionTypes';
import { FirmwareUpgradeHistory } from 'Models/Device/FirmwareUpgradeHistory';

export type FetchFirmwareUpgradeHistoryAction = {
  type: typeof FETCH_FIRMWARE_UPGRADE_HISTORY;
  systemId: string;
  serialNumber: string;
};

export function fetchFirmwareUpgradeHistoryAction(
  systemId: string,
  serialNumber: string
): FetchFirmwareUpgradeHistoryAction {
  return {
    type: FETCH_FIRMWARE_UPGRADE_HISTORY,
    systemId,
    serialNumber,
  };
}

export type ReceiveFirmwareUpgradeHistoryAction = {
  type: typeof RECEIVE_FIRMWARE_UPGRADE_HISTORY;
  firmwareUpgradeHistory: FirmwareUpgradeHistory[];
};

export function receiveFirmwareUpgradeHistoryAction(
  firmwareUpgradeHistory: FirmwareUpgradeHistory[]
): ReceiveFirmwareUpgradeHistoryAction {
  return {
    type: RECEIVE_FIRMWARE_UPGRADE_HISTORY,
    firmwareUpgradeHistory,
  };
}
