import * as React from 'react';

type PublicErrorPageProps = {};

export function PublicErrorPage(props: PublicErrorPageProps) {
  return (
    <div id="wrapper">
      <nav className="navbar navbar-default navbar-static-top no-margin-bottom">
        <div className="container-fluid">
          <div className="navbar-header"></div>
        </div>
      </nav>
      <div id="content">
        <div className="alert alert-danger">An error occured while logging in.</div>
      </div>
    </div>
  );
}
