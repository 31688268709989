import { FETCH_SYSTEM } from '../Actions/ActionTypes';
import {
  endFetchSystemAction,
  setCurrentSystem,
  FetchSystemAction,
  setCurrentSystemError,
} from 'Actions/System/SystemActions';
import { put, select, call } from 'redux-saga/effects';
import { RootState } from 'Reducers/RootReducer';
import { getSystemRequest, GetSystemResponse } from 'Services/SystemsProxy';
import { cancelOnNavigationEffect } from './CancelOnNavigationSaga';
import { SystemInfo, toSystemType } from 'Models/System/System';

export function* getSystemSaga(action: FetchSystemAction) {
  try {
    yield put(setCurrentSystemError(''));

    const token: string = yield select((state: RootState) => state.authentication.token);
    const systemResponse: GetSystemResponse = yield call(getSystemRequest, token, action.systemId);

    yield put(setCurrentSystem(assembleSystem(systemResponse)));
  } catch (error) {
    yield put(setCurrentSystemError('Error occured while fetching the system details'));
  } finally {
    yield put(endFetchSystemAction());
  }
}

export function* watchGetSystem() {
  yield cancelOnNavigationEffect(FETCH_SYSTEM, getSystemSaga);
}

function assembleSystem(systemResponse: GetSystemResponse): SystemInfo {
  return {
    id: systemResponse.response.id,
    name: systemResponse.response.name,
    type: toSystemType(systemResponse.response.systemType),
  };
}
