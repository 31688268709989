import { SET_TOKEN } from './ActionTypes';
export type AuthenticationActions = SetTokenAction;

export type SetTokenAction = {
  type: typeof SET_TOKEN;
  token: string;
};
export function setTokenAction(token: string): SetTokenAction {
  return {
    type: SET_TOKEN,
    token,
  };
}
