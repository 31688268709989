export enum JobType {
  None = '',
  Reboot = 'reboot',
  FactoryReset = 'factory_reset',
  FirmwareUpgrade = 'firmware_upgrade',
}

export enum JobStatus {
  None = '',
  Accepted = 'accepted',
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
}

export type DeviceJob = {
  deviceSerialNumber: string;
  systemId: string;
  type: JobType;
  status: JobStatus;
  reason: string;
};

export type CreateDeviceJob = {
  type: JobType;
  body: object;
};

type FactoryResetJobBody = {
  keepNetworkSettings: boolean;
};

export type FactoryResetDeviceJob = {
  type: JobType;
  body: FactoryResetJobBody;
};

type FirmwareUpgradeJobBody = {
  firmwareName: string;
  firmwareVersion: string;
  firmwareUrl: string;
};

export type FirmwareUpgradeDeviceJob = {
  type: JobType;
  body: FirmwareUpgradeJobBody;
};
