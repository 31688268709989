import { REMOVE_USER_FROM_SYSTEM } from 'Actions/ActionTypes';
import { put, select, call } from 'redux-saga/effects';
import { trackFetchAction, clearFetchAction } from 'Actions/Loading/LoadingActions';
import { RootState } from 'Reducers/RootReducer';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { removeUserFromSystemRequest } from 'Services/SystemUsersProxy';
import { RemoveUserFromSystemAction, fetchUserListAction } from 'Actions/Users/UserActions';
import { fetchUserInvitationListAction } from 'Actions/Users/UserInvitationActions';
import { withNavigationGate } from '../WithNavigationGate';

export function* removeUserFromSystemSaga(action: RemoveUserFromSystemAction) {
  yield put(trackFetchAction('deleteUser'));
  try {
    const token = yield select((state: RootState) => state.authentication.token);
    const user = yield select((state: RootState) => state.user.authUser!.id);
    yield call(removeUserFromSystemRequest, token, action.systemId, action.userId);
    yield put(sendToastNotificationAction('success', 'The user has successfully been deleted.'));
    if (user === action.userId) {
      window.location.reload();
    }
    return action;
  } finally {
    yield put(clearFetchAction('deleteUser'));
  }
}

function* onError(error: any) {
  if (error instanceof ServiceCallerError) {
    yield put(sendToastNotificationAction('warning', error.message));
  } else {
    yield put(sendToastNotificationAction('warning', 'An error occurred while deleting the user.'));
  }
}

function* onContinuation(action: RemoveUserFromSystemAction) {
  yield put(fetchUserListAction(action.systemId));
  yield put(fetchUserInvitationListAction(action.systemId));
}

export function* watchRemoveUserFromSystem() {
  yield withNavigationGate(
    REMOVE_USER_FROM_SYSTEM,
    removeUserFromSystemSaga,
    onContinuation,
    onError
  );
}
