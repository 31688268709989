import * as React from 'react';
import { useMemo, useState, useRef } from 'react';
import TableButton from 'Components/Util/Controls/TableButton';
import { useSorter } from 'Hooks/useSorter';
import { SortableTh } from 'Components/Util/Table/SortableTh';
import styles from './UserInvitationList.module.css';
import { LightPrincipal } from '../Types';
import SearchAction from 'Components/Util/SearchAction';
import useOnClickOutside from 'use-onclickoutside';
import { RoleTd } from './RoleTd';
import { ConnectedNavLink } from 'Components/Util/Browser/ConnectedNavLink';
import ReactTooltip from 'react-tooltip';

type ServiceRegistrationListProps = {
  services: LightPrincipal[];
  canInviteServices: boolean;
  canRemoveServices: boolean;
  canAssignRoles: boolean;
  canViewDetails: boolean;
  systemId: string;
  roles: { id: string; name: string }[];
  onRegisterServiceButtonClick: () => void;
  onCopyToClipboardButtonClick: (text: string) => void;
  onRefreshButtonClick: () => void;
  onDeleteServiceInvitation: (username: string) => void;
  onDeleteService: (username: string, clientId: string) => void;
  onChangeServiceRole: (username: string, userId: string, newRole: string) => void;
};

export default function ServicePrincipalsList(props: ServiceRegistrationListProps) {
  const [textFilter, setTextFilter] = useState('');
  const [selectedService, setSelectedService] = useState<string | undefined>();

  const filteredList = useMemo(() => {
    let filteredServices = filterServices(props.services, textFilter);
    return assembleVisibleCollection(filteredServices);
  }, [textFilter, props.services]);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setSelectedService(undefined));

  const sorter = useSorter(filteredList, 'name');

  return (
    <React.Fragment>
      <div className="client-portal-table-header" data-testid="service-principals-list">
        <h2>Services</h2>
        <div className={styles.invitationTableActions}>
          <SearchAction
            dataTip="Search for a service"
            value={textFilter}
            onChange={value => setTextFilter(value)}
          />
          <a
            data-tip="Refresh"
            className="pull-right"
            onClick={props.onRefreshButtonClick}
            data-testid="refresh-invitation-list"
          >
            <i className="ico-sc-refresh" />
          </a>
          {props.canInviteServices && (
            <a
              data-tip="Invite a new service"
              className="pull-right"
              onClick={props.onRegisterServiceButtonClick}
              data-testid="add-invitation"
            >
              <i className="ico-sc-add" />
            </a>
          )}
        </div>
        <ReactTooltip />
      </div>
      <table className="client-portal-table table table-header fixed-table">
        <thead>
          <tr>
            <SortableTh field="name" displayName="Name" sorter={sorter} className="td-sm" />
            <SortableTh field="role" displayName="Role" sorter={sorter} className="td-sm" />
            <SortableTh field="status" displayName="Status" sorter={sorter} className="td-sm" />
            <th className="td-xs">{/* Action Buttons */}</th>
          </tr>
        </thead>
      </table>
      <div className="flex-content">
        <table className="client-portal-table table table-body fixed-table">
          <tbody ref={ref}>
            {sorter.elements.map((service, i) => {
              return (
                <tr
                  key={service.name}
                  className="hover-visibility-trigger"
                  onClick={() => setSelectedService(service.name)}
                >
                  <td className="td-sm">
                    <div className={styles.invitationTableCellOverflow}>
                      {props.canViewDetails && (
                        <ConnectedNavLink
                          to={`/systems/${props.systemId}/Users/${service.id}`}
                          data-testid={`user-details-${service.id}`}
                        >
                          {service.name}
                        </ConnectedNavLink>
                      )}
                      {!props.canViewDetails && service.name}
                    </div>
                  </td>
                  <RoleTd
                    testId="role-td"
                    className="td-sm"
                    canAssignRoles={props.canAssignRoles}
                    user={{
                      role: service.role,
                      type: service.status == 'Completed' ? 'user' : 'invitation',
                    }}
                    roles={props.roles}
                    isSelected={service.name === selectedService}
                    onChangeRole={role =>
                      props.onChangeServiceRole(service.name, service.id!, role)
                    }
                  />
                  <td className="td-sm">{service.status}</td>

                  <td className="td-xs">
                    {props.canRemoveServices && (
                      <TableButton
                        iconClassName="ico-sc-clear text-danger"
                        onClick={() => {
                          props.onDeleteService(service.name, service.id!);
                        }}
                        testId={`deleteInvite${i}`}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

function filterServices(services: LightPrincipal[], textFilter: string): LightPrincipal[] {
  if (textFilter.trim() === '') {
    return services;
  }
  return services.filter((s: LightPrincipal) =>
    s.name.toLowerCase().includes(textFilter.toLowerCase())
  );
}

function assembleVisibleCollection(
  services: LightPrincipal[]
): {
  id: string | undefined;
  name: string;
  status: string;
  role: string;
}[] {
  let servicesAssembled: {
    id: string | undefined;
    name: string;
    status: string;
    role: string;
    registrationUrl: string | undefined;
  }[] = [];

  servicesAssembled = services.map(u => ({
    id: u.id,
    name: u.name,
    status: 'Completed',
    role: u.role,
    registrationUrl: undefined,
  }));

  return servicesAssembled;
}
