import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'Components/Util/Breadcrumb';

type FirmwareUpgradeHistoryHeaderProps = {
  systemId: string;
  serialNumber: string;
};

function FirmwareUpgradeHistoryHeader(props: FirmwareUpgradeHistoryHeaderProps) {
  return (
    <div className="client-portal-heading-banner">
      <Breadcrumb>
        <BreadcrumbItem path={`/systems/${props.systemId}/devices`} text="Devices" />
        <BreadcrumbItem
          path={`/systems/${props.systemId}/devices/${props.serialNumber}/details`}
          text="Device Details"
        />
        <BreadcrumbItem text={props.serialNumber} />
        <BreadcrumbItem text="Firmware Upgrade History" />
      </Breadcrumb>
    </div>
  );
}

export default FirmwareUpgradeHistoryHeader;
