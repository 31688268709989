import {
  FETCH_AVAILABLE_APPLICATIONS,
  RECEIVE_AVAILABLE_APPLICATIONS,
  FETCH_APPLICATION,
  RECEIVE_APPLICATION,
  CLEAR_APPLICATION,
  UNINSTALL_APPLICATION,
  UPDATE_APPLICATION,
  START_APPLICATION,
  STOP_APPLICATION,
  RESTART_APPLICATION,
} from 'Actions/ActionTypes';
import {
  InstallableApplicationDetails,
  ApplicationIdentity,
} from 'Models/Application/ApplicationStore';

export type FetchAvailableApplicationsAction = {
  type: typeof FETCH_AVAILABLE_APPLICATIONS;
  systemId: string;
};

export function fetchAvailableApplicationsAction(
  systemId: string
): FetchAvailableApplicationsAction {
  return {
    type: FETCH_AVAILABLE_APPLICATIONS,
    systemId,
  };
}

export type ReceiveAvailableApplicationsAction = {
  type: typeof RECEIVE_AVAILABLE_APPLICATIONS;
  applications: ApplicationIdentity[];
};

export function receiveAvailableApplicationsAction(
  applications: ApplicationIdentity[]
): ReceiveAvailableApplicationsAction {
  return {
    type: RECEIVE_AVAILABLE_APPLICATIONS,
    applications,
  };
}

export type FetchApplicationAction = {
  type: typeof FETCH_APPLICATION;
  appId: string;
};

export function fetchApplicationAction(appId: string): FetchApplicationAction {
  return {
    type: FETCH_APPLICATION,
    appId,
  };
}

export type ReceiveApplicationAction = {
  type: typeof RECEIVE_APPLICATION;
  application: InstallableApplicationDetails;
};

export function receiveApplicationAction(
  application: InstallableApplicationDetails
): ReceiveApplicationAction {
  return {
    type: RECEIVE_APPLICATION,
    application,
  };
}

export type ClearApplicationAction = {
  type: typeof CLEAR_APPLICATION;
};

export function clearApplicationAction(): ClearApplicationAction {
  return {
    type: CLEAR_APPLICATION,
  };
}

export type UnistallApplicationAction = {
  type: typeof UNINSTALL_APPLICATION;
  systemId: string;
  serialNumber: string;
  deploymentId: string;
};

export function uninstallApplicationAction(
  systemId: string,
  serialNumber: string,
  deploymentId: string
): UnistallApplicationAction {
  return {
    type: UNINSTALL_APPLICATION,
    systemId,
    serialNumber,
    deploymentId,
  };
}
export type UpdateApplicationAction = {
  type: typeof UPDATE_APPLICATION;
  systemId: string;
  serialNumber: string;
  deploymentId: string;
  appVersion: string;
};

export function updateApplicationAction(
  systemId: string,
  serialNumber: string,
  deploymentId: string,
  appVersion: string
): UpdateApplicationAction {
  return {
    type: UPDATE_APPLICATION,
    systemId,
    serialNumber,
    deploymentId,
    appVersion,
  };
}

export type StartApplicationAction = {
  type: typeof START_APPLICATION;
  systemId: string;
  serialNumber: string;
  deploymentId: string;
};

export function startApplicationAction(
  systemId: string,
  serialNumber: string,
  deploymentId: string
): StartApplicationAction {
  return {
    type: START_APPLICATION,
    systemId,
    serialNumber,
    deploymentId,
  };
}

export type StopApplicationAction = {
  type: typeof STOP_APPLICATION;
  systemId: string;
  serialNumber: string;
  deploymentId: string;
};

export function stopApplicationAction(
  systemId: string,
  serialNumber: string,
  deploymentId: string
): StopApplicationAction {
  return {
    type: STOP_APPLICATION,
    systemId,
    serialNumber,
    deploymentId,
  };
}

export type RestartApplicationAction = {
  type: typeof RESTART_APPLICATION;
  systemId: string;
  serialNumber: string;
  deploymentId: string;
};

export function restartApplicationAction(
  systemId: string,
  serialNumber: string,
  deploymentId: string
): RestartApplicationAction {
  return {
    type: RESTART_APPLICATION,
    systemId,
    serialNumber,
    deploymentId,
  };
}
