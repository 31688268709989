import { Role, RolePermissionsMap } from 'Models/Authorization';
import {
  FETCH_ROLE_PERMISSIONS,
  FETCH_ROLE_PRINCIPALS,
  RECEIVE_ROLE_PERMISSIONS,
  RECEIVE_ROLE_PRINCIPALS,
} from '../ActionTypes';

export type RoleAction =
  | FetchRolePermissionsAction
  | FetchRolePrincipalsAction
  | ReceiveRolePermissionsAction
  | ReceiveRolePrincipalsAction;

export type FetchRolePrincipalsAction = {
  type: typeof FETCH_ROLE_PRINCIPALS;
  systemId: string;
  roleName: Role;
};

export function fetchRolePrincipals(systemId: string, roleName: Role): FetchRolePrincipalsAction {
  return {
    type: FETCH_ROLE_PRINCIPALS,
    systemId,
    roleName,
  };
}

export type FetchRolePermissionsAction = {
  type: typeof FETCH_ROLE_PERMISSIONS;
  systemId: string;
  roleName: Role;
};

export function fetchRolePermissions(systemId: string, roleName: Role): FetchRolePermissionsAction {
  return {
    type: FETCH_ROLE_PERMISSIONS,
    systemId,
    roleName,
  };
}

export type ReceiveRolePermissionsAction = {
  type: typeof RECEIVE_ROLE_PERMISSIONS;
  permissions: RolePermissionsMap;
};

export function receiveRolePermissions(
  permissions: RolePermissionsMap
): ReceiveRolePermissionsAction {
  return {
    type: RECEIVE_ROLE_PERMISSIONS,
    permissions,
  };
}

export type ReceiveRolePrincipalsAction = {
  type: typeof RECEIVE_ROLE_PRINCIPALS;
  principals: string[];
};

export function receiveRolePrincipals(principals: string[]): ReceiveRolePrincipalsAction {
  return {
    type: RECEIVE_ROLE_PRINCIPALS,
    principals,
  };
}
