import * as React from 'react';
import { useRef, useEffect } from 'react';
import './InlineControls.css';

type InlineInputProps = {
  value: string;
  onChange?: (_: string) => void;
  onClick?: () => void;
  error?: string | JSX.Element;
  placeholder?: string;
  shouldFocus?: boolean;
  testId?: string;
};

export function InlineInput(props: InlineInputProps) {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (props.shouldFocus) {
      ref.current!.focus();
    }
  }, []);

  return (
    <div>
      <input
        ref={ref}
        className={'form-control ' + (props.error ? 'has-error' : '') + ' inline-input'}
        value={props.value}
        onChange={e => (props.onChange ? props.onChange(e.target.value) : undefined)}
        placeholder={props.placeholder}
        data-testid={props.testId}
        onClick={e => (props.onClick ? props.onClick() : undefined)}
      />
      {props.error && <span className="help-block has-error">{props.error}</span>}
    </div>
  );
}
