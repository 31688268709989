import { call, put, select } from 'redux-saga/effects';
import { clearFetchAction, trackFetchAction } from '../../Actions/Loading/LoadingActions';
import { RootState } from '../../Reducers/RootReducer';
import { sendToastNotificationAction } from '../../Actions/Notification/NotificationActions';
import { ServiceCallerError } from '../../Services/ServiceCaller';
import { fetchDeviceAction } from '../../Actions/Device/DeviceActions';
import { withNavigationGate } from '../WithNavigationGate';
import { RestartApplicationAction } from '../../Actions/Application/ApplicationActions';
import { restartApplicationRequest } from '../../Services/AppDeploymentProxy';
import { RESTART_APPLICATION } from '../../Actions/ActionTypes';

export function* restartApplicationSaga(action: RestartApplicationAction) {
  yield put(trackFetchAction('restartApplication'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);

    yield call(
      restartApplicationRequest,
      token,
      action.systemId,
      action.serialNumber,
      action.deploymentId
    );
    yield put(sendToastNotificationAction('success', `The application will be restarted shortly.`));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      console.log(e);
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occurred while attempting to restart the application.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('restartApplication'));
  }
  return action;
}

function* afterCompletion(action: RestartApplicationAction) {
  yield put(fetchDeviceAction(action.systemId, action.serialNumber, true));
}

export function* watchRestartApplication() {
  yield withNavigationGate(
    RESTART_APPLICATION,
    restartApplicationSaga,
    afterCompletion,
    () => undefined
  );
}
