import { call, put } from 'redux-saga/effects';
import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import { FETCH_USER_DETAILS } from 'Actions/ActionTypes';
import { receiveUserDetailsAction, FetchUserDetailsAction } from 'Actions/Users/UserActions';
import {
  GetUserSystemPermissionsResponse,
  getUserSystemPermissions,
} from 'Services/AuthorizationProxy';
import { genericSaga } from 'Sagas/GenericSaga';

export function* fetchUserDetails(action: FetchUserDetailsAction) {
  const config = {
    argsBuilder: (token: string): [string, string, string] => [
      token,
      action.systemId,
      action.userId,
    ],
    fn: getUserSystemPermissions,
    loadingSectionName: 'fetchUserDetails',
    errorMessage: 'Unable to fetch user details.',
  };

  const apiResponse: GetUserSystemPermissionsResponse = yield call(genericSaga, config);
  formatPermissions(apiResponse);
  yield put(receiveUserDetailsAction({ permissions: apiResponse.response }));
}

const formatPermissions = (userPermissions: GetUserSystemPermissionsResponse) => {
  userPermissions.response.forEach(
    permission =>
      (permission.permissionName = permission.permissionName.replace(/([A-Z])/g, ' $1').trim())
  );
};

export function* watchForUserDetailsActions() {
  yield cancelOnNavigationEffect(FETCH_USER_DETAILS, fetchUserDetails);
}
