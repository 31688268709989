import React from 'react';
import style from './DeviceNetwork.module.css';
import '../../Util/CollapsablePanel';
import { CollapsablePanel } from '../../Util/CollapsablePanel';
import ReactTooltip from 'react-tooltip';
import { IpAddress, Network } from 'Models/Device/DeviceInfo';

type Props = {
  networkInfo: Network | null;
  onRefresh: () => void;
};

export function DeviceNetwork({ networkInfo, onRefresh }: Props) {
  return (
    <div>
      <div className={style.header}>
        <h2>Network</h2>
        <div className="pull-right">
          <a data-tip="Refresh" onClick={onRefresh} data-testid="refresh-button">
            <i className="ico-sc-refresh" />
          </a>
        </div>
        <ReactTooltip />
      </div>

      {networkInfo === null ? (
        <div className={`text-warning ${style.marginTop}`}>
          The network information for this device is not available.
        </div>
      ) : (
        <div className="form-group">
          <div className={style.networkSection}>
            <h3 className={style.header}>General Information</h3>
            <div className="row">
              <div className="col-lg-2 noWrap">
                <label>Default Gateway</label>
                <div>{networkInfo ? networkInfo.defaultGateway : <br />}</div>
              </div>
            </div>
            <h3 className={style.header}>Interfaces</h3>
            {networkInfo && (
              <li className={style.interfacesList}>
                {Object.values(networkInfo.interfaces)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(currInterface => (
                    <ul key={currInterface.name} className={style.interface}>
                      <CollapsablePanel>
                        <div data-testid="interface-name">{currInterface.name}</div>
                        <div className="col-lg-12">
                          <label>Source</label>
                          <div>{currInterface.source}</div>
                        </div>
                        <div className="col-lg-12">
                          <label>Mac Address</label>
                          <div>{currInterface.macAddress}</div>
                        </div>
                        <div className="col-lg-12">
                          <label>Flags</label>
                          <div>{currInterface.flags.join(', ')}</div>
                        </div>
                        <div className={style.ipAddressTable}>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>IP Address</th>
                                <th>Network Mask</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currInterface.ipAddresses.map(ipAddress => (
                                <tr
                                  className="hover-visibility-trigger cursor-pointer "
                                  key={ipAddress.address}
                                >
                                  <td className="text-default">
                                    <a href={getIPAddressUrl(ipAddress)}>{ipAddress.address}</a>
                                  </td>
                                  <td className="text-default">
                                    <div className="truncate-ellipsis">
                                      {ipAddress.networkMask}{' '}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </CollapsablePanel>
                    </ul>
                  ))}
              </li>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export function getIPAddressUrl(ipAddress: IpAddress): string {
  const parsedAddress =
    ipAddress.family === 'inter_network_v6' ? `[${ipAddress.address}]` : ipAddress.address;
  return `https://${parsedAddress}`;
}
