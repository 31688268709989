import { PermissionModel, RolePermissionsMap, RolePrincipalsModel } from 'Models/Authorization';
import config from 'react-global-configuration';
import urljoin from 'url-join';
import { httpGet, httpPut } from './ServiceCaller';

export type GetRolesModel = {
  role: string;
  principalId: string;
}[];

export type GetRolesResponse = {
  response: GetRolesModel;
};

export type GetSystemRoles = {
  response: { role: string }[];
};

export type GetUserSystemPermissionsResponse = {
  response: PermissionModel[];
};

export type GetRolePermissionsResponse = {
  response: RolePermissionsMap;
};

export type GetRolePrincipalsResponse = {
  response: RolePrincipalsModel;
};

const authorizationApi = 'authorizationUrl';

export function getUserSystemPermissions(
  token: string,
  systemId: string,
  principalId: string
): Promise<GetUserSystemPermissionsResponse> {
  const url = urljoin(
    config.get(authorizationApi),
    'v1/systems',
    encodeURI(systemId),
    'principals',
    encodeURI(principalId),
    'permissions'
  );
  return httpGet('GetUserPermissions', token, url);
}

export function getRolesRequest(token: string, systemId: string): Promise<GetRolesResponse> {
  const url = urljoin(
    config.get(authorizationApi),
    'v1/systems',
    encodeURI(systemId),
    'principals'
  );
  return httpGet('GetRoles', token, url);
}

export function getSystemRoles(token: string, systemId: string): Promise<GetSystemRoles> {
  const url = urljoin(config.get(authorizationApi), 'v1/systems', encodeURI(systemId), 'roles');
  return httpGet('GetSystemRoles', token, url);
}

export function assignRoleRequest(
  token: string,
  systemId: string,
  principalId: string,
  role: string
): Promise<void> {
  const url = urljoin(
    config.get(authorizationApi),
    'v1/systems',
    encodeURI(systemId),
    'principals',
    encodeURI(principalId),
    'role'
  );

  return httpPut('PutRole', token, url, { role });
}

export function getRolePermissions(
  token: string,
  systemId: string,
  role: string
): Promise<GetRolePermissionsResponse> {
  const url = urljoin(
    config.get(authorizationApi),
    'v1/systems',
    encodeURI(systemId),
    'roles',
    encodeURI(role),
    'permissions'
  );
  return httpGet('GetRolePermissions', token, url);
}

export function getRolePrincipals(
  token: string,
  systemId: string,
  role: string
): Promise<GetRolePrincipalsResponse> {
  const url = urljoin(
    config.get(authorizationApi),
    'v1/systems',
    encodeURI(systemId),
    'roles',
    encodeURI(role),
    'principals'
  );
  return httpGet('GetRolePrincipals', token, url);
}
