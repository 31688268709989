import { GetEnrolledDeviceResponse } from './DeviceIdentityProxy';
import { EnrolledDevice, EnrollmentState, ConnectionStatus } from 'Models/Device/EnrolledDevice';

export function fromDeviceListApiResponse(response: GetEnrolledDeviceResponse[]): EnrolledDevice[] {
  return response
    .sort((a, b) => a.deviceName.localeCompare(b.deviceName))
    .map(r => ({
      systemId: r.systemId,
      deviceName: r.deviceName,
      serialNumber: r.serialNumber,
      enrollmentState: r.enrollmentState as EnrollmentState,
      enrollmentTime: new Date(r.enrollmentTime),
      connectionStatus: r.connectionStatus as ConnectionStatus,
      lastConnectionStatusUpdate: r.lastConnectionStatusUpdate
        ? new Date(r.lastConnectionStatusUpdate)
        : undefined,
      productCode: r.productCode,
    }));
}
