import { CREATE_DEVICE_JOB, RECEIVE_DEVICE_JOB } from '../ActionTypes';
import { CreateDeviceJob, DeviceJob } from '../../Models/DeviceJobs/DeviceJobs';

export type CreateDeviceJobAction = {
  type: typeof CREATE_DEVICE_JOB;
  systemId: string;
  serialNumber: string;
  deviceJob: CreateDeviceJob;
};

export function createDeviceJobAction(
  systemId: string,
  serialNumber: string,
  deviceJob: CreateDeviceJob
): CreateDeviceJobAction {
  return {
    type: CREATE_DEVICE_JOB,
    systemId: systemId,
    serialNumber: serialNumber,
    deviceJob: deviceJob,
  };
}

export type ReceiveDeviceJobAction = {
  type: typeof RECEIVE_DEVICE_JOB;
  deviceJob: DeviceJob;
};

export function receiveDeviceJobAction(deviceJob: DeviceJob): ReceiveDeviceJobAction {
  return {
    type: RECEIVE_DEVICE_JOB,
    deviceJob,
  };
}
