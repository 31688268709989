export default class Constants {
  static readonly webAppUrl = 'webAppUrl';
  static readonly systemsApi = 'systemsApiUrl';
  static readonly devicesApi = 'devicesApiUrl';
  static readonly deviceIdentityApi = 'deviceIdentityApiUrl';
  static readonly applicationConfigApi = 'applicationConfigApiUrl';
  static readonly gatewayApi = 'gatewayApiUrl';
  static readonly clientHubsApi = 'clientHubsApiUrl';
  static readonly azureIotHubsApi = 'azureIotHubsUrl';
  static readonly applicationRegistryApi = 'applicationRegistryUrl';
  static readonly appDeployementApi = 'applicationDeploymentApiUrl';
  static readonly registrationApi = 'registrationUrl';
  static readonly stsSettings = 'stsSettings';
  static readonly firmwareRepoApi = 'firmwareRepoApiUrl';
  static readonly launchDarklySettings = 'launchDarklySettings';
  static readonly deviceJobsApiUrl = 'deviceJobsApiUrl';
}
