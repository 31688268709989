import {
  FETCH_DEVICE_LIST,
  _RECEIVE_DEVICE_LIST,
  CLEAR_DEVICE_LIST,
  DELETE_DEVICE,
  SILENTLY_FETCH_DEVICE_LIST,
} from 'Actions/ActionTypes';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';

export type FetchDeviceListAction = {
  type: typeof FETCH_DEVICE_LIST;
  systemId: string;
};

export function fetchDevicesAction(systemId: string): FetchDeviceListAction {
  return {
    type: FETCH_DEVICE_LIST,
    systemId,
  };
}

export type _ReceiveDeviceListAction = {
  type: typeof _RECEIVE_DEVICE_LIST;
  devices: EnrolledDevice[];
};

export function _receiveDeviceListAction(devices: EnrolledDevice[]): _ReceiveDeviceListAction {
  return {
    type: _RECEIVE_DEVICE_LIST,
    devices,
  };
}

export type ClearDeviceListAction = {
  type: typeof CLEAR_DEVICE_LIST;
};

export function clearDeviceListAction(): ClearDeviceListAction {
  return {
    type: CLEAR_DEVICE_LIST,
  };
}

export type DeleteDeviceAction = {
  type: typeof DELETE_DEVICE;
  systemId: string;
  serialNumber: string;
  callback: () => void;
};
export function deleteDeviceAction(
  systemId: string,
  serialNumber: string,
  callback: () => void = () => undefined
): DeleteDeviceAction {
  return {
    type: DELETE_DEVICE,
    systemId,
    serialNumber,
    callback,
  };
}

export type SilentlyFetchDeviceListAction = {
  type: typeof SILENTLY_FETCH_DEVICE_LIST;
  systemId: string;
};

export function silentlyFetchDeviceListAction(systemId: string): SilentlyFetchDeviceListAction {
  return {
    type: SILENTLY_FETCH_DEVICE_LIST,
    systemId,
  };
}
