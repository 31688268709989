import { DELETE_USER_INVITATION } from 'Actions/ActionTypes';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import {
  DeleteUserInvitationAction,
  fetchUserInvitationListAction,
} from 'Actions/Users/UserInvitationActions';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select } from 'redux-saga/effects';
import { deleteInvitationRequest } from 'Services/RegistrationProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { withNavigationGate } from '../WithNavigationGate';

export function* deleteUserInvitationSaga(action: DeleteUserInvitationAction) {
  yield put(trackFetchAction('deleteInvitation'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    yield call(deleteInvitationRequest, token, action.systemId, action.username);
    yield put(
      sendToastNotificationAction('success', 'The invitation has successfully been deleted.')
    );
    return action;
  } finally {
    yield put(clearFetchAction('deleteInvitation'));
  }
}

function* onError(error: any) {
  if (error instanceof ServiceCallerError) {
    yield put(sendToastNotificationAction('warning', error.message));
  } else {
    yield put(
      sendToastNotificationAction('warning', 'An error occurred while deleting the invitation.')
    );
  }
}

function* onContinuation(action: DeleteUserInvitationAction) {
  yield put(fetchUserInvitationListAction(action.systemId));
}

export function* watchDeleteUserInvitation() {
  yield withNavigationGate(
    DELETE_USER_INVITATION,
    deleteUserInvitationSaga,
    onContinuation,
    onError
  );
}
