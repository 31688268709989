import * as React from 'react';
import useComponentVisible from '../Hooks/ComponentVisible';

type Props = {
  actions: EllipsisAction[];
  className?: string;
  direction?: string;
  event?: keyof DocumentEventMap;
  visibleOnHover?: boolean;
};

export type EllipsisAction = {
  name: string;
  action: () => void;
};

export function EllipsisMenu(props: Props) {
  const event = props.event ? props.event : 'click';
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false, event);
  const visibleOnHover = props.visibleOnHover ? props.visibleOnHover : false;
  const onClick = (e: any) => {
    setIsComponentVisible(!isComponentVisible);
    e.stopPropagation();
  };

  return (
    <div
      className={`${props.className} ${
        visibleOnHover && !isComponentVisible ? 'visible-on-hover' : ''
      } panel-options no-padding dropdown ${isComponentVisible ? 'open' : ''}`}
      ref={ref}
    >
      {props.actions.length > 0 && (
        <a className="dropdown" data-testid="menu-button" onClick={onClick}>
          <i className="ico-sc-menu text-lg" />
        </a>
      )}
      {isComponentVisible && (
        <ul
          className={`dropdown-menu ${props.direction === 'left' ? 'pull-left' : 'pull-right'}`}
          ref={ref}
        >
          {props.actions.map(action => (
            <li key={action.name}>
              <a
                onClick={e => {
                  setIsComponentVisible(false);
                  e.stopPropagation();
                  action.action();
                }}
              >
                {action.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
