import {
  GetApplicationArtifactsResponse,
  ApplicationArtifactResource,
  GetApplicationArtifactResponse,
} from 'Services/AppStoreProxy';
import {
  LeanApplicationArtifact,
  ApplicationArtifact,
  toArtifactStage,
  DeployableResource,
} from 'Models/Application/ApplicationStore';
import { compareVersions } from 'Utilities/AppVersionUtils';
import { constructParameterScopedKey } from '../AppDeployment/DeploymentParameterUtil';

export function assembleApplicationArtifacts(
  response: GetApplicationArtifactsResponse
): LeanApplicationArtifact[] {
  const artifacts = response.response.map(r => ({
    version: r.version,
    description: r.description,
    stage: toArtifactStage(r.stage),
  }));

  // Sort descending
  artifacts.sort((a, b) => -compareVersions(a.version, b.version));
  return artifacts;
}

export function assembleApplicationArtifact(
  artifact: GetApplicationArtifactResponse
): ApplicationArtifact {
  return {
    version: artifact.response.version,
    description: artifact.response.description,
    stage: toArtifactStage(artifact.response.stage),
    resources: assembleArtifactResources(artifact.response.resources),
  };
}

function assembleArtifactResources(resources: ApplicationArtifactResource[]): DeployableResource[] {
  const results = resources.map(r => ({
    name: r.name,
    displayName: r.displayName,
    parameters: r.parameters.map(p => ({
      scopedKey: constructParameterScopedKey(r.name, p.key),
      displayName: p.displayName,
      description: p.description,
      defaultValue: p.defaultValue,
    })),
  }));

  return results;
}
