import {
  clearApplicationAction,
  fetchApplicationAction,
} from 'Actions/Application/ApplicationActions';
import { clearApplicationInstallationFormAction } from 'Actions/Form/ApplicationInstallationFormActions';
import { Page, PageSwitch } from 'Components/Util/Layout/PageSwitch';
import { StoreDataContainer } from 'Components/Util/Lifecycle/StoreDataContainer';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationInstallationFormState } from 'Reducers/Form/ApplicationInstallationFormReducer';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { ApplicationDetailsFormPage } from './ApplicationDetailsFormPage';
import { ApplicationListFormPage } from './ApplicationListFormPage';
import { DeploymentParametersFormPage } from './DeploymentParametersFormPage';
import { EmptyApplicationInstallationFormPage } from './EmptyApplicationInstallationFormPage';
import { oc } from 'ts-optchain';

type Props = {
  form: ApplicationInstallationFormState;
  clearForm: () => void;
  systemId: string;
};

export function _ApplicationInstallationForm(props: Props) {
  return (
    <PageSwitch page={props.form.stage}>
      <Page name="list">
        <ApplicationListFormPage close={props.clearForm} />
      </Page>
      <Page name="details">
        <StoreDataContainer
          trigger={dispatch =>
            dispatch(
              fetchApplicationAction(
                (oc(props).form.currentlyInstalled.appId() || oc(props).form.application.id())!
              )
            )
          }
          isReady={store => store.application != null}
          fallback={<EmptyApplicationInstallationFormPage close={props.clearForm} />}
          cleanup={dispatch => dispatch(clearApplicationAction())}
        >
          <ApplicationDetailsFormPage close={props.clearForm} systemId={props.systemId} />
        </StoreDataContainer>
      </Page>
      <Page name="parameters">
        <DeploymentParametersFormPage close={props.clearForm} />
      </Page>
    </PageSwitch>
  );
}

function mapStateToProps(state: RootState) {
  return {
    form: state.form.applicationInstallation,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    clearForm: () => dispatch(clearApplicationInstallationFormAction()),
  };
}

export const ApplicationInstallationForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ApplicationInstallationForm);
