// Navigation
export const _AFTER_NAVIGATION = '_AFTER_NAVIGATION';
export const TRIGGER_NAVIGATION = 'TRIGGER_NAVIGATION';
export const CONFIRM_NAVIGATION = 'CONFIRM_NAVIGATION';
export const CLEAR_PENDING_NAVIGATION = 'CLEAR_PENDING_NAVIGATION';

// Notification
export const _ADD_TOAST_NOTIFICATION = '_ADD_TOAST_NOTIFICATION';
export const DISMISS_TOAST_NOTIFICATION = 'DISMISS_TOAST_NOTIFICATION';
export const SEND_TOAST_NOTIFICATION = 'SEND_TOAST_NOTIFICATION';

// Sticky errors
export const SET_PAGE_ERROR = 'SET_PAGE_ERROR';
export const CLEAR_PAGE_ERROR = 'CLEAR_PAGE_ERROR';

// Loading
export const TRACK_FETCH = 'TRACK_FETCH';
export const CLEAR_FETCH = 'CLEAR_FETCH';

// Authentication
export const SET_TOKEN = 'SET_TOKEN';

// Authorization Roles
export const FETCH_ROLE_PERMISSIONS = 'FETCH_ROLE_PERMISSIONS';
export const FETCH_ROLE_PRINCIPALS = 'FETCH_ROLE_PRINCIPALS';
export const RECEIVE_ROLE_PERMISSIONS = 'RECEIVE_ROLE_PERMISSIONS';
export const RECEIVE_ROLE_PRINCIPALS = 'RECEIVE_ROLE_PRINCIPALS';

// User
export const FETCH_AUTHENTICATED_USER = 'FETCH_AUTHENTICATED_USER';
export const END_FETCH_USER = 'END_FETCH_USER';
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const SET_SYSTEMS_FOR_USER = 'SET_SYSTEMS_FOR_USER';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_ERROR_FETCHING_SYSTEMS = 'SET_ERROR_FETCHING_SYSTEMS';
export const FETCH_USER_SYSTEM_PERMISSIONS = 'FETCH_USER_SYSTEM_PERMISSIONS';
export const SET_USER_SYSTEM_PERMISSIONS = 'SET_USER_SYSTEM_PERMISSIONS';
export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';

// System
export const FETCH_SYSTEM = 'FETCH_SYSTEM';
export const END_FETCH_SYSTEM = 'END_FETCH_SYSTEM';
export const SET_CURRENT_SYSTEM = 'SET_CURRENT_SYSTEM';
export const SET_CURRENT_SYSTEM_ERROR = 'SET_CURRENT_SYSTEM_ERROR';

// Users & invitations
export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const REMOVE_USER_FROM_SYSTEM = 'REMOVE_USER_FROM_SYSTEM';
export const RECEIVE_USER_LIST_FROM_API = 'RECEIVE_USER_LIST_FROM_API';
export const FETCH_USER_INVITATION_LIST = 'FETCH_USER_INVITATION_LIST';
export const RECEIVE_USER_INVITATION_LIST_FROM_API = 'RECEIVE_USER_INVITATION_LIST_FROM_API';
export const DELETE_USER_INVITATION = 'DELETE_USER_INVITATION';
export const CLEAR_USERS_AND_INVITATIONS = 'CLEAR_USERS_AND_INVITATIONS';
export const CREATE_USER_INVITATION = 'CREATE_USER_INVITATION';
export const CREATE_SERVICE_REGISTRATION = 'CREATE_SERVICE_REGISTRATION';

// Device
export const FETCH_DEVICE_LIST = 'FETCH_DEVICE_LIST';
export const _RECEIVE_DEVICE_LIST = '_RECEIVE_DEVICE_LIST';
export const CLEAR_DEVICE_LIST = 'CLEAR_DEVICE_LIST';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const SILENTLY_FETCH_DEVICE_LIST = 'SILENTLY_FETCH_DEVICE_LIST';

export const FETCH_DEVICE = 'FETCH_DEVICE';
export const RECEIVE_DEVICE = 'RECEIVE_DEVICE';
export const CLEAR_DEVICE = 'CLEAR_DEVICE';

export const FETCH_DEVICE_DETAILS = 'FETCH_DEVICE_DETAILS';
export const RECEIVE_DEVICE_INFO = 'RECEIVE_DEVICE_INFO';

export const FETCH_FIRMWARE_UPGRADE_DETAILS = 'FETCH_FIRMWARE_UPGRADE_DETAILS';
export const RECEIVE_FIRMWARE_UPGRADE_DETAILS = 'RECEIVE_FIRMWARE_UPGRADE_DETAILS';
export const FETCH_FIRMWARE_INFO = 'FETCH_FIRMWARE_INFO';
export const RECEIVE_FIRMWARE_INFO = 'RECEIVE_FIRMWARE_INFO';
export const FETCH_FIRMWARE_IMAGES = 'FETCH_FIRMWARE_IMAGES';
export const RECEIVE_FIRMWARE_IMAGES = 'RECEIVE_FIRMWARE_IMAGES';
export const FETCH_FIRMWARE_UPGRADE_HISTORY = 'FETCH_FIRMWARE_UPGRADE_HISTORY';
export const RECEIVE_FIRMWARE_UPGRADE_HISTORY = 'RECEIVE_FIRMWARE_UPGRADE_HISTORY';

// DeviceJobs
export const CREATE_DEVICE_JOB = 'CREATE_DEVICE_JOB';
export const RECEIVE_DEVICE_JOB = 'RECEIVE_DEVICE_JOB';

// Application Store
export const FETCH_AVAILABLE_APPLICATIONS = 'FETCH_AVAILABLE_APPLICATIONS';
export const RECEIVE_AVAILABLE_APPLICATIONS = 'RECEIVE_AVAILABLE_APPLICATIONS';
export const FETCH_APPLICATION = 'FETCH_APPLICATION';
export const RECEIVE_APPLICATION = 'RECEIVE_APPLICATION';
export const CLEAR_APPLICATION = 'CLEAR_APPLICATION';
export const UNINSTALL_APPLICATION = 'UNINSTALL_APPLICATION';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';

export const RESTART_APPLICATION = 'RESTART_APPLICATION';
export const START_APPLICATION = 'START_APPLICATION';
export const STOP_APPLICATION = 'STOP_APPLICATION';

// <----- FORMS ----->

// Device Enrollment Form
export const UPDATE_DEVICE_ENROLLMENT_FORM = 'UPDATE_DEVICE_ENROLLMENT_FORM';
export const CLEAR_DEVICE_ENROLLMENT_FORM = 'CLEAR_DEVICE_ENROLLMENT_FORM';
export const SUBMIT_DEVICE_ENROLLMENT_FORM = 'SUBMIT_DEVICE_ENROLLMENT_FORM';

export const SET_DEVICE_ENROLLMENT_FORM_ERRORS = 'SET_DEVICE_ENROLLMENT_FORM_ERRORS';

// Application Installation
export const CLEAR_APPLICATION_INSTALLATION_FORM = 'CLEAR_APPLICATION_INSTALLATION_FORM';
export const SHOW_APPLICATION_INSTALLATION_FORM = 'SHOW_APPLICATION_INSTALLATION_FORM';
export const SUBMIT_APPLICATION_INSTALLATION_FORM = 'SUBMIT_APPLICATION_INSTALLATION_FORM';
export const APPLICATION_INSTALLATION_BACKTRACK_WIZARD =
  'APPLICATION_INSTALLATION_BACKTRACK_WIZARD';
export const APPLICATION_INSTALLATION_SELECT_APPLICATION =
  'APPLICATION_INSTALLATION_SELECT_APPLICATION';
export const APPLICATION_INSTALLATION_FETCH_SELECTED_ARTIFACT =
  'APPLICATION_INSTALLATION_FETCH_SELECTED_ARTIFACT';
export const APPLICATION_INSTALLATION_RECEIVE_SELECTED_ARTIFACT =
  'APPLICATION_INSTALLATION_RECEIVE_SELECTED_ARTIFACT';
export const APPLICATION_INSTALLATION_UPDATE_PARAMETER_VALUES =
  'APPLICATION_INSTALLATION_UPDATE_PARAMETER_VALUES';
export const APPLICATION_EDIT_PARAMETER = 'APPLICATION_EDIT_PARAMETER';
export const SHOW_APPLICATION_INSTALLATION_UPDATE_FORM =
  'SHOW_APPLICATION_INSTALLATION_UPDATE_FORM';
export const EDIT_APPLICATION_PARAMETERS_ACTION = 'EDIT_APPLICATION_PARAMETERS_ACTION';

// <----- Modals ----->
// Confirmation Modal
export const PROMPT_FOR_CONFIRMATION = 'PROMPT_FOR_CONFIRMATION';
export const _ACCEPT_CONFIRMATION = '_ACCEPT_CONFIRMATION';
export const _CANCEL_CONFIRMATION = '_CANCEL_CONFIRMATION';

// Feature Flags
export const FETCH_SYSTEM_FEATURE_FLAGS = 'FETCH_SYSTEM_FEATURE_FLAGS';
export const SET_SYSTEM_FEATURE_FLAGS = 'SET_SYSTEM_FEATURE_FLAGS';
