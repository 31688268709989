import * as React from 'react';
import './InlineControls.css';
import { Fragment } from 'react';
type InlineSelectProps = {
  elems: { id: string; name: string }[];
  selectedElem?: string;
  onSelect?: (elem: string) => void;
  testId?: string;
  error?: string;
  shouldFocus?: boolean;
};

export function InlineSelect(props: InlineSelectProps) {
  function idToName(id?: string) {
    const selected = props.elems.find(e => e.id === id);
    if (selected) {
      return selected.name;
    }
    return '';
  }

  return (
    <Fragment>
      <div
        className={
          'client-portal-select btn-group bootstrap-select form-control inline-input' +
          (props.shouldFocus ? ' open' : '')
        }
        data-testid={props.testId}
      >
        <button
          className="btn dropdown-toggle inline-btn-list inline-input"
          type="button"
          data-toggle="dropdown"
          data-testid="select-toggle"
        >
          <span className="filter-option pull-left" data-testid="selected-element">
            {idToName(props.selectedElem)}
          </span>
          <span className="caret" />
        </button>
        <div className="dropdown-menu open">
          <ul className="dropdown-menu inner" role="menu">
            {props.elems.map(e => (
              <li
                className={e.id === props.selectedElem ? 'Selected' : ''}
                key={e.id}
                onClick={() => (props.onSelect ? props.onSelect(e.id) : null)}
                data-testid={`element_${e.id}`}
              >
                <a>
                  <span className="text">{e.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {props.error && <span className="help-block has-error thin-text">{props.error}</span>}
    </Fragment>
  );
}
