import { combineReducers } from 'redux';
import { deviceEnrollmentFormReducer } from './DeviceEnrollmentFormReducer';
import { applicationInstallationFormReducer } from './ApplicationInstallationFormReducer';

export const formReducer = combineReducers({
  deviceEnrollment: deviceEnrollmentFormReducer,
  applicationInstallation: applicationInstallationFormReducer,
});

export type FormState = ReturnType<typeof formReducer>;
