import {
  _AFTER_NAVIGATION,
  CONFIRM_NAVIGATION,
  TRIGGER_NAVIGATION,
  CLEAR_PENDING_NAVIGATION,
} from '../ActionTypes';

export type _AfterNavigationAction = {
  type: typeof _AFTER_NAVIGATION;
};

export function _afterNavigationAction() {
  return { type: _AFTER_NAVIGATION };
}

export type TriggerNavigationAction = {
  type: typeof TRIGGER_NAVIGATION;
  path: string;
  callback: () => void;
};

// Will be automatically accepted if there are no pending changes.
export function triggerNavigationAction(
  path: string,
  callback: () => void = () => {
    /* empty */
  }
): TriggerNavigationAction {
  return {
    type: TRIGGER_NAVIGATION,
    path,
    callback,
  };
}

export type ConfirmNavigationAction = {
  type: typeof CONFIRM_NAVIGATION;
};

export function confirmNavigationAction(): ConfirmNavigationAction {
  return {
    type: CONFIRM_NAVIGATION,
  };
}

export type ClearPendingNavigationAction = {
  type: typeof CLEAR_PENDING_NAVIGATION;
};

export function clearPendingNavigationAction(): ClearPendingNavigationAction {
  return {
    type: CLEAR_PENDING_NAVIGATION,
  };
}
