import React, { useEffect, useMemo } from 'react';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import { CreateDeviceJob } from 'Models/DeviceJobs/DeviceJobs';
import { FirmwareInfo } from 'Models/Device/FirmwareInfo';
import { DeviceSummaryFirmwareUpToDate } from 'Components/Device/DeviceSummary/DeviceSummaryFirmwareUpToDate';
import { FirmwareUpgradeDetails } from 'Models/Device/FirmwareUpgradeDetails';
import styles from './DeviceSummary.module.css';
import { DeviceSummaryFirmwareUpgrade } from './DeviceSummaryFirmwareUpgrade';
import { FirmwareImage } from 'Models/Device/FirmwareImage';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { fetchFirmwareImagesAction } from 'Actions/Device/FirmwareImageActions';
import { connect } from 'react-redux';
import { triggerNavigationAction } from 'Actions/Navigation/NavigationActions';

const DEFAULT_DRIVER_NAME = 'swupdate';

type Props = {
  device: EnrolledDevice;
  firmwareInfo: FirmwareInfo | null;
  firmwareUpgradeDetails: FirmwareUpgradeDetails | null;
  firmwareImages: FirmwareImage[];
  isManualFirmwareSelectionAllowed: boolean;
  supportsFirmwareUpgradeDeviceJob: boolean;
  createDeviceJob: (systemId: string, serialNumber: string, job: CreateDeviceJob) => void;
  fetchFirmwareImages: (firmwareName: string, systemId: string) => void;
  navigateToFirmwareUpgradeHistory: (path: string) => void;
};

export function _DeviceSummaryFirmwareUpgradeWrapper({
  device,
  firmwareInfo,
  firmwareUpgradeDetails,
  firmwareImages,
  isManualFirmwareSelectionAllowed,
  supportsFirmwareUpgradeDeviceJob,
  createDeviceJob,
  fetchFirmwareImages,
  navigateToFirmwareUpgradeHistory,
}: Props) {
  const hasLatestFirmwareVersion: boolean = firmwareInfo === null ? false : firmwareInfo.isUpToDate;
  const isDeviceOff = device && device.connectionStatus === 'not_connected';
  const firmwareImageName = useMemo(
    () => getDefaultFirmwareImageName(firmwareInfo, firmwareImages),
    [firmwareImages]
  );

  useEffect(() => {
    if (firmwareInfo !== null) fetchFirmwareImages(firmwareInfo.firmwareName, device.systemId);
  }, [firmwareInfo?.firmwareName]);

  const componentBody = () => {
    if (!isManualFirmwareSelectionAllowed && firmwareInfo && firmwareInfo.isUpToDate)
      return <DeviceSummaryFirmwareUpToDate firmwareInfo={firmwareInfo} />;
    else
      return (
        <DeviceSummaryFirmwareUpgrade
          firmwareInfo={firmwareInfo}
          device={device}
          firmwareUpgradeDetails={firmwareUpgradeDetails}
          firmwareImages={firmwareImages}
          createDeviceJob={createDeviceJob}
          defaultFirmwareImageName={firmwareImageName}
          isManualFirmwareSelectionAllowed={isManualFirmwareSelectionAllowed}
          disabled={
            isDeviceOff ||
            firmwareImages.length == 0 ||
            (!isManualFirmwareSelectionAllowed && (!firmwareImageName || hasLatestFirmwareVersion))
          }
        />
      );
  };

  return (
    <div data-testid="firmware-upgrade-wrapper">
      <div className={styles.otherHeaders}>
        <h2>Update</h2>
        <div className={styles.iconPosition}>
          <a
            data-tip="Firmware Upgrade History"
            onClick={() =>
              navigateToFirmwareUpgradeHistory(
                `/systems/${device.systemId}/devices/${device.serialNumber}/firmwareUpgradeHistory`
              )
            }
          >
            <i className="ico-sc-notepad" />
          </a>
        </div>
      </div>
      {supportsFirmwareUpgradeDeviceJob ? (
        componentBody()
      ) : (
        <h3 data-testid="information-not-available">
          <b> The information is temporary unavailable. </b>
        </h3>
      )}
    </div>
  );
}

function getDefaultFirmwareImageName(
  firmwareInfo: FirmwareInfo | null,
  firmwareImages: FirmwareImage[]
): string {
  let firmwareImageName = '';

  firmwareImages.forEach(firmwareImage =>
    firmwareImage.manifests.forEach(manifest => {
      if (
        manifest.firmwareVersion ===
          (firmwareInfo && firmwareInfo.latestFirmware.firmwareVersion) &&
        manifest.driver.toLowerCase() === DEFAULT_DRIVER_NAME
      )
        firmwareImageName = manifest.imageName;
    })
  );

  return firmwareImageName;
}

function mapStateToProps(state: RootState) {
  return {
    firmwareImages: state.firmwareImages,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchFirmwareImages: (firmwareName: string, systemId: string) =>
      dispatch(fetchFirmwareImagesAction(firmwareName, systemId)),
    navigateToFirmwareUpgradeHistory: (path: string) => {
      dispatch(triggerNavigationAction(path));
    },
  };
}

export const DeviceSummaryFirmwareUpgradeWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(_DeviceSummaryFirmwareUpgradeWrapper);
