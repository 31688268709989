import { FETCH_FIRMWARE_INFO } from 'Actions/ActionTypes';
import { clearFetchAction } from 'Actions/Loading/LoadingActions';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select } from 'redux-saga/effects';
import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import {
  FetchFirmwareInfoAction,
  receiveFirmwareInfoAction,
} from 'Actions/Device/DeviceDetailsActions';
import { FirmwareInfo } from 'Models/Device/FirmwareInfo';
import { getFirmwareInfoRequest } from '../../Services/Device/DeviceIdentityProxy';

export function* fetchFirmwareInfo(action: FetchFirmwareInfoAction) {
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);

    const firmwareInfo: FirmwareInfo = yield call(
      getFirmwareInfoRequest,
      token,
      action.systemId,
      action.serialNumber
    );

    yield put(receiveFirmwareInfoAction(firmwareInfo));
  } catch (e) {
    //No toast notification in case of errors, because not all devices have reports
    console.log(e);
  } finally {
    yield put(clearFetchAction('fetchFirmwareInfo'));
  }
}

export function* watchFetchFirmwareInfo() {
  yield cancelOnNavigationEffect(FETCH_FIRMWARE_INFO, fetchFirmwareInfo);
}
