import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import { FETCH_AVAILABLE_APPLICATIONS } from 'Actions/ActionTypes';
import { put, select, call } from 'redux-saga/effects';
import { FetchAvailableApplicationsAction } from 'Actions/Application/ApplicationActions';
import { trackFetchAction, clearFetchAction } from 'Actions/Loading/LoadingActions';
import { RootState } from 'Reducers/RootReducer';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import {
  getApplicationIdentitiesRequest,
  GetApplicationIdentitiesResponse,
} from 'Services/AppStoreProxy';
import { toAppStage } from 'Models/Application/ApplicationStore';
import { ApplicationIdentity } from 'Models/Application/ApplicationStore';
import { receiveAvailableApplicationsAction } from 'Actions/Application/ApplicationActions';
import { toSystemType } from 'Models/System/System';

export function* fetchAvailableApplicationsSaga(action: FetchAvailableApplicationsAction) {
  yield put(trackFetchAction('fetchAvailableApplications'));
  try {
    const token = yield select((state: RootState) => state.authentication.token);
    const applicationResponse: GetApplicationIdentitiesResponse = yield call(
      getApplicationIdentitiesRequest,
      token
    );

    const system = yield select((state: RootState) => state.system.info);
    yield put(
      receiveAvailableApplicationsAction(
        assembleApplications(applicationResponse).filter(
          app =>
            system.type !== toSystemType('production') || app.stage === toAppStage('production')
        )
      )
    );
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occurred while retrieving available applications.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('fetchAvailableApplications'));
  }
}

export function* watchFetchAvailableApplications() {
  yield cancelOnNavigationEffect(FETCH_AVAILABLE_APPLICATIONS, fetchAvailableApplicationsSaga);
}

function assembleApplications(
  applicationResponse: GetApplicationIdentitiesResponse
): ApplicationIdentity[] {
  return applicationResponse.response.map(app => ({
    id: app.id,
    displayName: app.displayName,
    description: app.description,
    latestRelease: app.latestRelease,
    stage: toAppStage(app.stage),
  }));
}
