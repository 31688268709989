import {
  fetchDevicesAction,
  clearDeviceListAction,
  deleteDeviceAction,
  silentlyFetchDeviceListAction,
} from 'Actions/Device/DeviceListActions';
import { BasicPage } from 'Components/Util/Page';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { DeviceList } from './DeviceList';
import { useTimer } from 'Utilities/useTimer';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import { triggerNavigationAction } from 'Actions/Navigation/NavigationActions';

type Props = {
  devices: EnrolledDevice[];
  systemId: string;
  fetchDevices: (systemId: string) => void;
  silentlyFetchDevices: (systemId: string) => void;
  clearDeviceList: () => void;
  deleteDevice: (systemId: string, serialNumber: string) => void;
  navigate: (path: string) => void;
};

export function _DevicePage(props: Props) {
  useEffect(() => {
    props.fetchDevices(props.systemId);
    return props.clearDeviceList;
  }, [props.systemId]);

  useTimer(() => props.silentlyFetchDevices(props.systemId), 5000);

  return (
    <BasicPage>
      <DeviceList
        systemId={props.systemId}
        devices={props.devices}
        refresh={() => props.fetchDevices(props.systemId)}
        deleteDevice={serialNumber => props.deleteDevice(props.systemId, serialNumber)}
        goToDeviceDetails={(serialNumber: string) =>
          props.navigate(`/systems/${props.systemId}/devices/${serialNumber}/details`)
        }
      />
    </BasicPage>
  );
}

function mapStateToProps(state: RootState) {
  return {
    devices: state.deviceList,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchDevices: (systemId: string) => dispatch(fetchDevicesAction(systemId)),
    silentlyFetchDevices: (systemId: string) => dispatch(silentlyFetchDeviceListAction(systemId)),
    deleteDevice: (systemId: string, serialNumber: string) =>
      dispatch(
        deleteDeviceAction(systemId, serialNumber, () => dispatch(fetchDevicesAction(systemId)))
      ),
    clearDeviceList: () => dispatch(clearDeviceListAction()),
    navigate: (path: string) => dispatch(triggerNavigationAction(path)),
  };
}

export const DevicePage = connect(mapStateToProps, mapDispatchToProps)(_DevicePage);
