import { FETCH_FIRMWARE_IMAGES } from 'Actions/ActionTypes';
import {
  FetchFirmwareImagesAction,
  receiveFirmwareImagesAction,
} from 'Actions/Device/FirmwareImageActions';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select } from 'redux-saga/effects';
import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import {
  getFirmwareImagesRequest,
  GetFirmwareImagesResponse,
} from 'Services/Firmware/FirmwareImageProxy';
import { assembleFirmwareImages } from 'Services/Firmware/FirmwareImagesAssembler';

export function* fetchFirmwareImagesSaga(action: FetchFirmwareImagesAction) {
  try {
    yield put(trackFetchAction('fetchFirmwareImages'));
    const token: string = yield select((state: RootState) => state.authentication.token);

    const firmwareImagesResponse: GetFirmwareImagesResponse = yield call(
      getFirmwareImagesRequest,
      token,
      action.firmwareName,
      action.systemId
    );

    yield put(receiveFirmwareImagesAction(assembleFirmwareImages(firmwareImagesResponse)));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(clearFetchAction('fetchFirmwareImages'));
  }
}

export function* watchFetchFirmwareImage() {
  yield cancelOnNavigationEffect(FETCH_FIRMWARE_IMAGES, fetchFirmwareImagesSaga);
}
