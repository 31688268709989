import * as React from 'react';
import classNames from 'classnames';
import styles from './SearchAction.module.css';
import { useState } from 'react';

type SearchActionProps = {
  dataTip: string;
  value: string;
  onChange: (value: string) => void;
};

export default function SearchAction(props: SearchActionProps) {
  const [showField, setShowField] = useState(false);

  if (!showField && props.value === '') {
    return (
      <a data-tip={props.dataTip} onClick={() => setShowField(true)} data-testid="search-button">
        <i className="ico-sc-search" />
      </a>
    );
  }

  return (
    <div className={classNames('input-search', styles.searchBar)}>
      <input
        autoFocus
        className={classNames('form-control', styles.searchInput)}
        type="text"
        placeholder="Search"
        value={props.value}
        onChange={event => props.onChange(event.target.value)}
        onBlur={() => setShowField(false)}
      />
      <span className={classNames('ico-search', styles.searchIcon)}>
        <i className="ico-sc-search"></i>
      </span>
      <button
        className={classNames('btn', 'btn-default', 'ico-clear', styles.clearIcon)}
        onClick={event => props.onChange('')}
        data-testid="clear-search"
      >
        <i className="ico-sc-close"></i>
      </button>
    </div>
  );
}
