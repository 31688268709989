import { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as React from 'react';
import { RootState } from 'Reducers/RootReducer';

type Props = {
  hasPendingChanges: boolean;
};

function _LeavePagePrompt(props: Props) {
  useEffect(() => {
    function listener(event: Event) {
      if (props.hasPendingChanges) {
        event.preventDefault();
        event.returnValue = true;
      }
    }
    window.addEventListener('beforeunload', listener);
    return () => window.removeEventListener('beforeunload', listener);
  }, [props.hasPendingChanges]);

  return <Fragment />;
}
function mapStateToProps(state: RootState) {
  return {
    hasPendingChanges: state.pendingChanges.dirty,
  };
}
export const LeavePagePrompt = connect(mapStateToProps)(_LeavePagePrompt);
