import { ApplicationDeploymentDetails } from 'Models/Application/ApplicationDeployment';
import { ApplicationIdentity } from 'Models/Application/ApplicationStore';
import { connect } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import { InstalledApplicationListLayout } from './Presentation/InstalledApplicationListLayout';
import {
  showApplicationEditParametersFormAction,
  showApplicationInstallationUpdateFormAction,
} from 'Actions/Form/ApplicationInstallationFormActions';
import { Dispatch } from 'redux';
import React, { Fragment } from 'react';

type OwnProps = {
  deployments: ApplicationDeploymentDetails[];
  deleteDeployment: (deploymentId: string) => void;
  updateDeployment: (deploymentId: string, appVersion: string) => void;
  openApplicationUpdateForm: (deployment: ApplicationDeploymentDetails) => void;
  openApplicationEditForm: (deploymentId: string) => void;
  startDeployment: (deploymentId: string) => void;
  stopDeployment: (deploymentId: string) => void;
  restartDeployment: (deploymentId: string) => void;
};

type Props = OwnProps & {
  availableApplications: ApplicationIdentity[];
};

export function _InstalledApplications(props: Props) {
  return (
    <Fragment>
      <InstalledApplicationListLayout
        deployments={props.deployments}
        deleteDeployment={props.deleteDeployment}
        openApplicationDetails={props.openApplicationUpdateForm}
        availableApplications={props.availableApplications}
        updateDeployment={props.updateDeployment}
        startDeployment={props.startDeployment}
        stopDeployment={props.stopDeployment}
        restartDeployment={props.restartDeployment}
        openApplicationEdit={props.openApplicationEditForm}
      />
    </Fragment>
  );
}

function mapStateToProps(state: RootState) {
  return {
    availableApplications: state.applicationList,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    openApplicationUpdateForm: (deployment: ApplicationDeploymentDetails) =>
      dispatch(showApplicationInstallationUpdateFormAction(deployment)),

    openApplicationEditForm: (deploymentId: string) =>
      dispatch(showApplicationEditParametersFormAction(deploymentId)),
  };
}

export const InstalledApplications = connect(
  mapStateToProps,
  mapDispatchToProps
)(_InstalledApplications);
