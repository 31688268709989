import { FirmwareImage, Manifests } from 'Models/Device/FirmwareImage';
import { compareVersions } from 'Utilities/AppVersionUtils';
import { FirmwareManifests, GetFirmwareImagesResponse } from './FirmwareImageProxy';

export function assembleFirmwareImages(response: GetFirmwareImagesResponse): FirmwareImage[] {
  const firmwares = response.response.map(firmwareImagesResponse => ({
    firmwareName: firmwareImagesResponse.firmwareName,
    firmwareVersion: firmwareImagesResponse.firmwareVersion,
    manifests: assembleManifests(firmwareImagesResponse.manifests),
  }));

  firmwares.sort(
    (current, other) =>
      -compareVersions(
        formatFirmwareVersion(current.firmwareVersion),
        formatFirmwareVersion(other.firmwareVersion)
      )
  );
  return firmwares;
}

function assembleManifests(manifests: FirmwareManifests[]): Manifests[] {
  const results = manifests.map(manifest => ({
    firmwareVersion: manifest.firmwareVersion,
    manifestVersion: manifest.manifestVersion,
    cpuArch: manifest.cpuArch,
    deviceClass: manifest.deviceClass,
    flavor: manifest.flavor,
    toolingLevel: manifest.toolingLevel,
    driver: manifest.driver,
    buildId: manifest.buildId,
    availabilityLevel: manifest.availabilityLevel,
    imageName: manifest.imageName,
    fileSize: manifest.fileSize,
  }));

  results.sort((current, other) => (current.driver > other.driver ? 1 : -1));
  return results;
}

function formatFirmwareVersion(str: string): string {
  return str.split(/[-,+]/)[0];
}
