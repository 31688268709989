import urljoin from 'url-join';
import { httpPost } from '../ServiceCaller';
import config from 'react-global-configuration';
import { DeviceApiCreateJob, DeviceApiJob } from './DeviceJobsAssembler';
import Constants from 'Constants';

export type CreateDeviceJobRequest = DeviceApiCreateJob;

export type CreateDeviceJobResponse = {
  response: DeviceApiJob;
};

export function createDeviceJob(
  token: string,
  systemId: string,
  serialNumber: string,
  deviceJob: CreateDeviceJobRequest
): Promise<CreateDeviceJobResponse> {
  const deviceJobEndpoint = config.get(Constants.deviceJobsApiUrl);
  const url = urljoin(
    deviceJobEndpoint,
    'v1/devicejobs/systems',
    systemId,
    '/devices',
    serialNumber,
    '/jobs'
  );

  return httpPost('DeviceJobs', token, url, deviceJob);
}
