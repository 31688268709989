import { fetchDeviceDetailsAction } from 'Actions/Device/DeviceDetailsActions';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { DeviceInfo } from 'Models/Device/DeviceInfo';
import '../../Util/CollapsablePanel';
import { DeviceNetwork } from './DeviceNetwork';

type OwnProps = {
  serialNumber: string;
  systemId: string;
  fetchDeviceDetails: (systemId: string, serialNumber: string) => void;
};

type Props = OwnProps & {
  deviceInfo: DeviceInfo | null;
};

export function _DeviceDetailsPageNetworkSettings(props: Props) {
  function fetchDeviceDetails() {
    props.fetchDeviceDetails(props.systemId, props.serialNumber);
  }
  useEffect(fetchDeviceDetails, []);

  return (
    <DeviceNetwork
      networkInfo={props.deviceInfo ? props.deviceInfo.network : null}
      onRefresh={fetchDeviceDetails}
    />
  );
}

function mapStateToProps(state: RootState) {
  return {
    deviceInfo: state.device.deviceInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchDeviceDetails: (systemId: string, serialNumber: string) =>
      dispatch(fetchDeviceDetailsAction(systemId, serialNumber)),
  };
}

export const DeviceDetailsPageNetworkSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(_DeviceDetailsPageNetworkSettings);
