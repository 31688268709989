import { _RECEIVE_DEVICE_LIST, CLEAR_DEVICE_LIST } from 'Actions/ActionTypes';
import { _ReceiveDeviceListAction, ClearDeviceListAction } from 'Actions/Device/DeviceListActions';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';

type DeviceListReducerState = EnrolledDevice[];

type DeviceListReducerActions = _ReceiveDeviceListAction | ClearDeviceListAction;

const defaultState: DeviceListReducerState = [];

export function deviceListReducer(
  state: DeviceListReducerState = defaultState,
  action: DeviceListReducerActions
) {
  switch (action.type) {
    case _RECEIVE_DEVICE_LIST:
      return action.devices;
    case CLEAR_DEVICE_LIST:
      return [];
    default:
      return state;
  }
}
