import React from 'react';
import { ConnectionStatus } from 'Models/Device/EnrolledDevice';

type Props = {
  status: ConnectionStatus;
};

export function ConnectionStatusComponent({ status }: Props) {
  return <span className={STYLE_CLASS[status]}>{TEXT[status]} </span>;
}

const TEXT = {
  not_connected: 'Not Connected',
  connected: 'Connected',
};

const STYLE_CLASS = {
  not_connected: 'text-warning',
  connected: 'text-success',
};
