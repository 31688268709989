import React from 'react';
import classNames from 'classnames';

import { Role, RolePermissionsMap, RoleLabels } from 'Models/Authorization';
import { RolePermissions } from './RolePermissions';
import { RoleMembers } from './RoleMembers';
import { LightPrincipal } from 'Components/Users/Types';

import { Breadcrumb, BreadcrumbItem } from 'Components/Util/Breadcrumb';
import { RoleDetailsTab } from './Types';
import styles from './Roles.module.css';

type Props = {
  permissions: RolePermissionsMap;
  principals: string[];
  systemUsers: LightPrincipal[];
  canViewRoleUsers: boolean;
  canViewRolePermissions: boolean;
  activeTab: RoleDetailsTab;
  systemId: string;
  roleName: Role;
  selectTab: (tab: RoleDetailsTab) => void;
};

export function RoleDetailsPageView(props: Props) {
  return (
    <div id="content" className={classNames('scrollable', styles.roleDetailsPage)}>
      <div className="client-portal-heading-banner">
        <Breadcrumb>
          <BreadcrumbItem path={`/systems/${props.systemId}/roles`} text="Roles" />
          <BreadcrumbItem text={RoleLabels.get(props.roleName)!} />
        </Breadcrumb>
      </div>
      <div id="page-wrapper" className="padding-left-md padding-right-md margin-top-md">
        <ul className="nav nav-tabs">
          {props.canViewRoleUsers && (
            <li className={classNames({ active: props.activeTab === 'members' })}>
              <a
                href="#role-members"
                data-toggle="tab"
                aria-expanded="false"
                data-testid="role-members-tab-link"
                onClick={() => props.selectTab('members')}
              >
                Members
              </a>
            </li>
          )}
          {props.canViewRolePermissions && (
            <li className={classNames({ active: props.activeTab === 'permissions' })}>
              <a
                href="#role-permissions"
                data-toggle="tab"
                aria-expanded="false"
                data-testid="role-permissions-tab-link"
                onClick={() => props.selectTab('permissions')}
              >
                Permissions
              </a>
            </li>
          )}
        </ul>
        <div className="tab-content">
          {props.canViewRoleUsers && (
            <div
              id="role-members"
              data-testid="role-members-tab"
              className={classNames('tab-pane fade', {
                active: props.activeTab === 'members',
                in: props.activeTab === 'members',
              })}
            >
              {props.principals && props.principals.length === 0 && (
                <p>This role does not have any members.</p>
              )}
              {props.principals && (
                <RoleMembers userIds={props.principals} users={props.systemUsers} />
              )}
            </div>
          )}
          {props.canViewRolePermissions && (
            <div
              id="role-permissions"
              data-testid="role-permissions-tab"
              className={classNames('tab-pane fade', {
                active: props.activeTab === 'permissions',
                in: props.activeTab === 'permissions',
              })}
            >
              <RolePermissions permissions={props.permissions} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
