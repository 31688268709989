import { call, put, select } from 'redux-saga/effects';
import { clearFetchAction, trackFetchAction } from '../../Actions/Loading/LoadingActions';
import { RootState } from '../../Reducers/RootReducer';
import { sendToastNotificationAction } from '../../Actions/Notification/NotificationActions';
import { ServiceCallerError } from '../../Services/ServiceCaller';
import { fetchDeviceAction } from '../../Actions/Device/DeviceActions';
import { withNavigationGate } from '../WithNavigationGate';
import { StopApplicationAction } from '../../Actions/Application/ApplicationActions';
import { stopApplicationRequest } from '../../Services/AppDeploymentProxy';
import { STOP_APPLICATION } from '../../Actions/ActionTypes';

export function* stopApplicationSaga(action: StopApplicationAction) {
  yield put(trackFetchAction('stopApplication'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);

    yield call(
      stopApplicationRequest,
      token,
      action.systemId,
      action.serialNumber,
      action.deploymentId
    );
    yield put(sendToastNotificationAction('success', `The application will be stopped shortly.`));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      console.log(e);
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occurred while attempting to stop the application.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('stopApplication'));
  }
  return action;
}

function* afterCompletion(action: StopApplicationAction) {
  yield put(fetchDeviceAction(action.systemId, action.serialNumber, true));
}

export function* watchStopApplication() {
  yield withNavigationGate(STOP_APPLICATION, stopApplicationSaga, afterCompletion, () => undefined);
}
