import { CreateDeviceJob, DeviceJob, JobType } from '../../Models/DeviceJobs/DeviceJobs';

export type DeviceApiCreateJob = {
  type: JobType;
  body: object;
};

export type DeviceApiJob = DeviceJob;

export const DeviceJobsAssembler = {
  fromApi(job: DeviceApiJob): DeviceJob {
    return {
      deviceSerialNumber: job.deviceSerialNumber,
      systemId: job.systemId,
      type: job.type,
      status: job.status,
      reason: job.reason,
    };
  },

  toApi(job: CreateDeviceJob): DeviceApiCreateJob {
    return {
      type: job.type,
      body: job.body,
    };
  },
};
