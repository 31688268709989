import React from 'react';
import style from './FirmwareStatus.module.css';

type FirmwareAvailableProps = {
  isUpToDate: boolean | null;
  latestFirmwareVersion: string | null;
  firmwareVersion: string | null;
};

export function FirmwareStatus(props: FirmwareAvailableProps) {
  if (props.isUpToDate === undefined || props.isUpToDate === null) {
    return <span />;
  }
  if (!props.isUpToDate) {
    return (
      <div className={style.flexHorizontal}>
        <i className={`ico-sc-info ${style.informationIcon}`} />
        <div className={style.flexVertical}>
          <div>
            <label className={style.informationText}>Latest available version:</label>
            <span>{props.latestFirmwareVersion}</span>
          </div>
          <div>
            <label className={style.greyText}>Current Version:</label>
            <span>{props.firmwareVersion}</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={style.flexHorizontal}>
      <i className={`ico-sc-info ${style.checkIcon}`} />
      <div className={style.flexVertical}>
        <div>
          <label className={style.greenText}>You have the latest firmware version</label>
        </div>
        <div>
          <label className={style.greyText}>Current Version:</label>
          <span>{props.firmwareVersion}</span>
        </div>
      </div>
    </div>
  );
}
