import {
  _ADD_TOAST_NOTIFICATION,
  DISMISS_TOAST_NOTIFICATION,
  SEND_TOAST_NOTIFICATION,
} from '../ActionTypes';
import { MsgType } from 'Components/Util/Notifications';

export type _AddToastNotificationAction = {
  type: typeof _ADD_TOAST_NOTIFICATION;
  notificationType: string;
  message: string;
  id: string;
};

export function _addToastNotificationAction(
  notificationType: string,
  message: string,
  id: string
): _AddToastNotificationAction {
  return {
    type: _ADD_TOAST_NOTIFICATION,
    notificationType,
    message,
    id,
  };
}
export type DismissToastNotificationAction = {
  type: typeof DISMISS_TOAST_NOTIFICATION;
  id: string;
};
export function dismissToastNotificationAction(id: string) {
  return { type: DISMISS_TOAST_NOTIFICATION, id };
}

export type SendToastNotificationAction = {
  type: typeof SEND_TOAST_NOTIFICATION;
  notificationType: MsgType;
  message: string;
};

export function sendToastNotificationAction(
  notificationType: MsgType,
  message: string
): SendToastNotificationAction {
  return {
    type: SEND_TOAST_NOTIFICATION,
    notificationType,
    message,
  };
}
