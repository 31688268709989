import * as React from 'react';
import { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { System } from 'Models/System/System';
import { SystemSelectorModal } from 'Components/Topnav/SystemSelectorModal';
import { RootState } from 'Reducers/RootReducer';
import { triggerNavigationAction } from 'Actions/Navigation/NavigationActions';

type Props = {
  systems: System[];
  navigate: (path: string) => void;
  // Should automatically select first option? defaults to 'true'
  autoSelectFirst?: boolean;
};

export function _SystemSelectionLandingPage(props: Props) {
  const promptUserForSelection = props.autoSelectFirst === false || props.systems.length !== 1;
  useEffect(() => {
    if (!promptUserForSelection) {
      props.navigate(`/systems/${props.systems[0].id}`);
    }
  }, [props.systems]);
  return (
    (promptUserForSelection && (
      <div id="content">
        <SystemSelectorModal
          show={true}
          systems={props.systems}
          systemId=""
          onSelectSystem={systemId => props.navigate(`/systems/${systemId}`)}
        />
      </div>
    )) || <Fragment />
  );
}

function mapStateToProps(state: RootState) {
  return {
    systems: state.user.systems,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    navigate: (path: string) => dispatch(triggerNavigationAction(path)),
  };
}

export const SystemSelectionLandingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SystemSelectionLandingPage);
