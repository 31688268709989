import * as React from 'react';

type Props = {
  progress: number;
  description: string;
  style: string;
};

export default function ProgressionLabel(props: Props): React.ReactElement<any> {
  return (
    <div>
      <label className="filled">{`${props.description}${
        !isNaN(+props.progress) ? ` (${props.progress}%)` : ''
      }`}</label>
      {!isNaN(+props.progress) && (
        <div className={`progress ${props.style}`} data-testid="progress-bar">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: `${props.progress}%` }}
          ></div>
        </div>
      )}
    </div>
  );
}
