import React, { useEffect } from 'react';
import { ApplicationInstallationForm } from 'Components/Application/Installation/ApplicationInstallationForm';
import { InstalledApplications } from './Application/InstalledApplications';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationDeploymentDetails } from 'Models/Application/ApplicationDeployment';
import { fetchDeviceAction, clearDeviceAction } from 'Actions/Device/DeviceActions';
import {
  fetchAvailableApplicationsAction,
  uninstallApplicationAction,
  updateApplicationAction,
  startApplicationAction,
  stopApplicationAction,
  restartApplicationAction,
} from 'Actions/Application/ApplicationActions';
import { promptForConfirmationAction } from 'Actions/Modal/ModalActions';
import { showApplicationInstallationFormAction } from 'Actions/Form/ApplicationInstallationFormActions';
import { useTimer } from 'Utilities/useTimer';
import DisableableIcon from 'Components/Util/DisableableIcon';
import ReactTooltip from 'react-tooltip';

type OwnProps = {
  serialNumber: string;
  systemId: string;
};

type Props = OwnProps & {
  isLoading: boolean;
  fetchDevice: (systemId: string, serialNumber: string, silent: boolean) => void;
  fetchAvailableApplications: (systemId: string) => void;
  installedApplications: ApplicationDeploymentDetails[];
  deleteApplicationDeployment: (
    systemId: string,
    serialNumber: string,
    deploymentId: string
  ) => void;
  updateDeployment: (
    systemId: string,
    serialNumber: string,
    deploymentId: string,
    appVersion: string
  ) => void;
  clearDevice: () => void;

  showApplicationInstallationForm: () => void;
  startDeployment: (systemId: string, serialNumber: string, deploymentId: string) => void;
  stopDeployment: (systemId: string, serialNumber: string, deploymentId: string) => void;
  restartDeployment: (systemId: string, serialNumber: string, deploymentId: string) => void;
};

export function _DeviceDetailsPageApplications(props: Props) {
  useEffect(() => {
    props.fetchAvailableApplications(props.systemId);
  }, []);

  useTimer(() => props.fetchDevice(props.systemId, props.serialNumber, true), 5000);

  return (
    <>
      <ApplicationInstallationForm systemId={props.systemId} />
      <div className="client-portal-table-header">
        <h2>Applications</h2>
        <div className="pull-right">
          <DisableableIcon
            dataTip="Refresh"
            iconClass="ico-sc-refresh"
            isDisabled={props.isLoading}
            dataTestId="refresh-button"
            onClick={() => {
              props.fetchDevice(props.systemId, props.serialNumber, false);
              props.fetchAvailableApplications(props.systemId);
            }}
          />
          <a
            data-tip="Install an application"
            onClick={props.showApplicationInstallationForm}
            data-testid="add-application"
          >
            <i className="ico-sc-add" />
          </a>
        </div>
        <ReactTooltip />
      </div>
      <div className="flex-content">
        <InstalledApplications
          deployments={props.installedApplications}
          deleteDeployment={(deploymentId: string) =>
            props.deleteApplicationDeployment(props.systemId, props.serialNumber, deploymentId)
          }
          updateDeployment={(deploymentId: string, appVersion: string) =>
            props.updateDeployment(props.systemId, props.serialNumber, deploymentId, appVersion)
          }
          startDeployment={(deploymentId: string) =>
            props.startDeployment(props.systemId, props.serialNumber, deploymentId)
          }
          stopDeployment={(deploymentId: string) =>
            props.stopDeployment(props.systemId, props.serialNumber, deploymentId)
          }
          restartDeployment={(deploymentId: string) =>
            props.restartDeployment(props.systemId, props.serialNumber, deploymentId)
          }
        />
      </div>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    installedApplications: state.device.applications,
    isLoading: state.loading.length > 0,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchDevice: (systemId: string, serialNumber: string, silent: boolean) =>
      dispatch(fetchDeviceAction(systemId, serialNumber, silent)),
    fetchAvailableApplications: (systemId: string) =>
      dispatch(fetchAvailableApplicationsAction(systemId)),
    clearDevice: () => dispatch(clearDeviceAction()),
    deleteApplicationDeployment: (systemId: string, serialNumber: string, deploymentId: string) =>
      dispatch(
        promptForConfirmationAction(
          'Are you sure you want to remove the application from your device?',
          () => dispatch(uninstallApplicationAction(systemId, serialNumber, deploymentId))
        )
      ),
    updateDeployment: (
      systemId: string,
      serialNumber: string,
      deploymentId: string,
      appVersion: string
    ) =>
      dispatch(
        promptForConfirmationAction(
          `The application will be updated to version ${appVersion}. Are you sure you want to continue?`,
          () => dispatch(updateApplicationAction(systemId, serialNumber, deploymentId, appVersion))
        )
      ),
    showApplicationInstallationForm: () => dispatch(showApplicationInstallationFormAction()),

    startDeployment: (systemId: string, serialNumber: string, deploymentId: string) =>
      dispatch(startApplicationAction(systemId, serialNumber, deploymentId)),
    stopDeployment: (systemId: string, serialNumber: string, deploymentId: string) =>
      dispatch(stopApplicationAction(systemId, serialNumber, deploymentId)),
    restartDeployment: (systemId: string, serialNumber: string, deploymentId: string) =>
      dispatch(restartApplicationAction(systemId, serialNumber, deploymentId)),
  };
}

export const DeviceDetailsPageApplications = connect(
  mapStateToProps,
  mapDispatchToProps
)(_DeviceDetailsPageApplications);
