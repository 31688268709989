import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { RootState } from 'Reducers/RootReducer';
import {
  clearUsersDetailsAction,
  fetchUserDetailsAction,
  fetchUserListAction,
} from 'Actions/Users/UserActions';
import { LightPrincipal } from '../Types';
import { UserDetails } from 'Models/Users/UserDetails';
import { UserDetailsPageView } from './UserDetailsPageView';

type RouteParams = {
  systemId: string;
  principalId: string;
};

type UserDetailsPageProps = RouteComponentProps<RouteParams> & {
  userDetails: UserDetails;
  systemUsers: LightPrincipal[];
  onLoad: (systemId: string, principalId: string) => void;
  clearUserDetails: () => void;
};

export function _UserDetailsPage(props: UserDetailsPageProps) {
  const {
    match: { params },
  } = props;

  useEffect(() => {
    props.onLoad(params.systemId, params.principalId);
    return props.clearUserDetails;
  }, [params.systemId, params.principalId]);

  const matchedUser = React.useMemo(() => {
    return props.systemUsers.find(u => u.id === params.principalId);
  }, [props.systemUsers, params.principalId, params.systemId]);

  return (
    <UserDetailsPageView
      permissions={props.userDetails.permissions}
      principalId={params.principalId}
      systemId={params.systemId}
      user={matchedUser}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  userDetails: state.pages.users.userDetails,
  systemUsers: state.pages.users.users,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLoad: (systemId: string, principalId: string) => {
    dispatch(fetchUserListAction(systemId));
    dispatch(fetchUserDetailsAction(systemId, principalId));
  },
  clearUserDetails: () => {
    dispatch(clearUsersDetailsAction());
  },
});

export const UserDetailsPage = connect(mapStateToProps, mapDispatchToProps)(_UserDetailsPage);
