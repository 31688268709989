import { FETCH_APPLICATION } from 'Actions/ActionTypes';
import {
  FetchApplicationAction,
  receiveApplicationAction,
} from 'Actions/Application/ApplicationActions';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import {
  LeanApplicationArtifact,
  ApplicationIdentity,
  toAppStage,
  toArtifactStage,
} from 'Models/Application/ApplicationStore';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select } from 'redux-saga/effects';
import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import {
  getApplicationArtifactsRequest,
  GetApplicationArtifactsResponse,
  getApplicationIdentityRequest,
  GetApplicationIdentityResponse,
} from 'Services/AppStoreProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { assembleApplicationArtifacts } from 'Services/AppStore/ArtifactAssembler';
import { toSystemType } from 'Models/System/System';

export function* fetchApplicationSaga(action: FetchApplicationAction) {
  yield put(trackFetchAction('fetchApplicationDetails'));

  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    const identityResponse: GetApplicationIdentityResponse = yield call(
      getApplicationIdentityRequest,
      token,
      action.appId
    );

    const artifactsResponse: GetApplicationArtifactsResponse = yield call(
      getApplicationArtifactsRequest,
      token,
      action.appId
    );

    const identity = assembleApplicationIdentity(identityResponse);
    const system = yield select((state: RootState) => state.system.info);
    const artifacts = assembleApplicationArtifacts(artifactsResponse);
    const latestInstallableVersion =
      system.type == toSystemType('production')
        ? identity.latestRelease
        : findLatestForDevSystem(artifacts);
    const installableArtifact = artifacts.find(
      artifact => artifact.version === latestInstallableVersion
    );
    const latestInstallableVersionStage =
      installableArtifact === undefined ? 'release' : installableArtifact.stage;

    yield put(
      receiveApplicationAction({
        ...identity,
        latestInstallableVersion,
        latestInstallableVersionStage,
        artifacts: artifacts.filter(
          artifact =>
            system.type !== toSystemType('production') ||
            artifact.stage === toArtifactStage('release')
        ),
      })
    );
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      console.log(e);
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occurred while retrieving the application.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('fetchApplicationDetails'));
  }
}

export function* watchFetchApplication() {
  yield cancelOnNavigationEffect(FETCH_APPLICATION, fetchApplicationSaga);
}

function assembleApplicationIdentity(
  response: GetApplicationIdentityResponse
): ApplicationIdentity {
  return {
    id: response.response.id,
    displayName: response.response.displayName,
    description: response.response.description,
    latestRelease: response.response.latestRelease,
    stage: toAppStage(response.response.stage),
  };
}

//The latest artifact for dev system can be of stage release or stable
//Note: the function assumes that the artifacts are ordered by version in descending order (done by assembleApplicationArtifacts function)
function findLatestForDevSystem(artifacts: LeanApplicationArtifact[]) {
  for (var i = 0; i < artifacts.length; i++) {
    const artifact = artifacts[i];
    if (artifact.stage === 'stable' || artifact.stage === 'release') {
      return artifact.version;
    }
  }
  return '0.0.0';
}
