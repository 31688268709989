import { ApplicationDeploymentDetails } from 'Models/Application/ApplicationDeployment';
import { ApplicationIdentity } from 'Models/Application/ApplicationStore';
import React from 'react';
import styles from './InstalledApplications.module.css';
import { InstalledApplicationTile } from './InstalledApplicationTile';

type Props = {
  deployments: ApplicationDeploymentDetails[];
  deleteDeployment: (deploymentId: string) => void;
  openApplicationDetails: (deployment: ApplicationDeploymentDetails) => void;
  availableApplications: ApplicationIdentity[];
  updateDeployment: (deploymentId: string, appVersion: string) => void;
  openApplicationEdit: (deploymentId: string) => void;
  startDeployment: (deploymentId: string) => void;
  stopDeployment: (deploymentId: string) => void;
  restartDeployment: (deploymentId: string) => void;
};

export function InstalledApplicationListLayout(props: Props) {
  return (
    <div className={styles.appList}>
      {props.deployments.map(deployment => (
        <InstalledApplicationTile
          deployment={deployment}
          key={deployment.id}
          onClick={() => props.openApplicationDetails(deployment)}
          deleteDeployment={props.deleteDeployment}
          applicationIdentity={props.availableApplications.find(
            identity => identity.id === deployment.appId
          )}
          updateDeployment={props.updateDeployment}
          openApplicationEdit={props.openApplicationEdit}
          startDeployment={props.startDeployment}
          stopDeployment={props.stopDeployment}
          restartDeployment={props.restartDeployment}
        />
      ))}
    </div>
  );
}
