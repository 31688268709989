import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'Components/Util/Breadcrumb';

type DeviceDetailsHeaderProps = {
  device: EnrolledDevice;
  systemId: string;
};

function DeviceDetailsHeader(props: DeviceDetailsHeaderProps) {
  return (
    <div className="client-portal-heading-banner">
      <div>
        <Breadcrumb>
          <BreadcrumbItem path={`/systems/${props.systemId}/devices`} text="Devices" />
          <BreadcrumbItem text={props.device.deviceName} />
        </Breadcrumb>
      </div>
    </div>
  );
}

export default DeviceDetailsHeader;
