import { useState, useEffect, useRef } from 'react';

// see https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
export default function useComponentVisible(
  initialIsVisible: boolean,
  event: keyof DocumentEventMap = 'click'
) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleEvent = (event: Event) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener(event, handleEvent, true);
    return () => {
      document.removeEventListener(event, handleEvent, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}
