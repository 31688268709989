import { _ADD_TOAST_NOTIFICATION, DISMISS_TOAST_NOTIFICATION } from 'Actions/ActionTypes';
import { MsgType } from 'Components/Util/Notifications';
import {
  _AddToastNotificationAction,
  DismissToastNotificationAction,
} from 'Actions/Notification/NotificationActions';

export type ToastNotification = {
  id: string;
  notificationType: MsgType;
  message: string;
};

export type NotificationState = ToastNotification[];

type NotificationReducerActions = _AddToastNotificationAction | DismissToastNotificationAction;

export function notificationReducer(
  state: NotificationState = [],
  action: NotificationReducerActions
): NotificationState {
  switch (action.type) {
    case _ADD_TOAST_NOTIFICATION:
      return [
        ...state,
        {
          message: action.message,
          notificationType: action.notificationType as MsgType,
          id: action.id,
        },
      ];
    case DISMISS_TOAST_NOTIFICATION:
      return state.filter(n => n.id !== action.id);

    default:
      return state;
  }
}
