import config from 'react-global-configuration';
import { httpGet, httpDelete, httpPost } from './ServiceCaller';
import { UserInvitation } from 'Models/Users/UserInvitation';

const urljoin = require('url-join');

const registrationApi = 'registrationUrl';

export type GetUserInvitationsResponse = {
  response: UserInvitation[];
};

export type GetUserInvitationResponse = {
  response: UserInvitation;
};

export type CreateUserInvitationResponse = {
  response: {
    registrationUrl: string;
  };
};

export type CreateServiceRegistrationResponse = {
  response: {
    systemId: string;
    principalId: string;
    serviceName: string;
    clientId: string;
  };
};

export async function getSystemInvitationsRequest(
  token: string,
  systemId: string
): Promise<GetUserInvitationsResponse> {
  const url = urljoin(config.get(registrationApi), '/v1/systems', systemId, 'invites');
  return await httpGet('GetSystemInvtations', token, url);
}

export async function deleteInvitationRequest(
  token: string,
  systemId: string,
  inviteId: string
): Promise<void> {
  const url = urljoin(
    config.get(registrationApi),
    '/v1/systems',
    encodeURIComponent(systemId),
    'invites',
    inviteId
  );
  return await httpDelete('DeleteInvitation', token, url);
}

export async function createUserInvitationRequest(
  token: string,
  systemId: string,
  email: string,
  role: string
): Promise<CreateUserInvitationResponse> {
  const url = urljoin(config.get(registrationApi), `v1/systems/${systemId}/invites`);

  return await httpPost('CreateUserInvitation', token, url, {
    email: email,
    role,
  });
}

export async function createServiceRegistrationRequest(
  token: string,
  systemId: string,
  clientId: string,
  name: string,
  role: string
): Promise<CreateServiceRegistrationResponse> {
  const url = urljoin(config.get(registrationApi), `v1/systems/${systemId}/services/${clientId}`);

  return await httpPost('CreateServiceRegistration', token, url, {
    servicename: name,
    role,
  });
}
