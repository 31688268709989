import React from 'react';
import { ArtifactStage } from '../../../Models/Application/ApplicationStore';

type Props = {
  appId: string;
  latest: string;
  latestStage: string;
  installed?: string;
  installedStage?: ArtifactStage;
};

export function ApplicationVersionInformation(props: Props) {
  return (
    <div>
      <div>
        Id <span className="text-neutral"> - {props.appId}</span>
      </div>
      <div>
        {props.latest} <span className="text-neutral"> - latest: {props.latestStage}</span>
      </div>
      {props.installed && (
        <div>
          {props.installed} <span className="text-success"> - current: {props.installedStage}</span>
        </div>
      )}
    </div>
  );
}
