import * as React from 'react';
import '../ClientPortal.css';

type ModalProps = {
  title: string;
  yesLabel?: string;
  yesButtonClass?: string;
  onYes?: () => void;
  noLabel?: string;
  onNo?: () => void;
  cancelLabel?: string;
  onCancel?: () => void;
  canCancel?: boolean;
  canYes?: boolean;
  show: boolean;
  children: React.ReactNode;
  showFooter?: boolean;
  large?: boolean;
};

export default function Modal(props: ModalProps) {
  const showNoButton = props.onNo && props.noLabel;
  const showYesButton = props.onYes && props.yesLabel;
  const canYes = props.canYes === undefined || props.canYes;
  const canCancel = props.canCancel === undefined || props.canCancel;
  const showFooter = props.showFooter === undefined ? true : props.showFooter;

  if (props.show) {
    return (
      <div className="client-portal-modal">
        <div
          className="modal modal-info fade in"
          data-backdrop="static"
          tabIndex={-1}
          role="dialog"
        >
          <div className="modal-backdrop fade in" />
          <div className={'modal-dialog' + (props.large ? ' modal-large' : '')}>
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title">{props.title}</div>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  data-testid="modal-close"
                  onClick={e => {
                    if (props.onCancel) {
                      props.onCancel();
                    }
                    e.stopPropagation();
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{props.children}</div>
              {showFooter && (
                <div className="modal-footer">
                  <button
                    type="button"
                    data-testid="modal-cancel"
                    className="btn btn-default"
                    onClick={e => {
                      if (props.onCancel) {
                        props.onCancel();
                      }
                      e.stopPropagation();
                    }}
                    disabled={!canCancel}
                  >
                    {props.cancelLabel ? props.cancelLabel : 'Cancel'}
                  </button>
                  {showNoButton && (
                    <button
                      type="button"
                      data-testid="modal-no"
                      className="btn btn-default"
                      onClick={e => {
                        if (props.onNo) props.onNo();
                        e.stopPropagation();
                      }}
                    >
                      {props.noLabel}
                    </button>
                  )}
                  {showYesButton && (
                    <button
                      type="button"
                      className={props.yesButtonClass || 'btn btn-primary'}
                      data-testid="modal-yes"
                      onClick={e => {
                        if (props.onYes) props.onYes();
                        e.stopPropagation();
                      }}
                      disabled={!canYes}
                    >
                      {props.yesLabel}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

type ConfirmationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  text: string;
  show: boolean;
};

export function ConfirmationModal(props: ConfirmationModalProps) {
  return (
    <Modal
      title="Are you sure?"
      cancelLabel="No"
      yesLabel="Yes"
      onCancel={props.onCancel}
      onYes={props.onConfirm}
      show={props.show}
    >
      <p>{props.text}</p>
    </Modal>
  );
}
