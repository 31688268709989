import { useSelector } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import { FeatureFlags } from 'Services/SystemsProxy';

export function useFeatureFlagToggle(featureFlag: FeatureFlags): boolean {
  const isActive = useSelector<RootState, boolean>(
    state => state.featureFlags.get(featureFlag) === true
  );
  return isActive;
}
