import { RootState } from 'Reducers/RootReducer';
import { ReactNode, useEffect } from 'react';
import { Dispatch } from 'redux';
import React from 'react';
import { connect } from 'react-redux';

type OwnProps = {
  isReady: (store: RootState) => boolean;
  trigger: (dispatch: Dispatch) => void;
  fallback?: ReactNode;
  cleanup?: (dispatch: Dispatch) => void;
  children: ReactNode;
};

type Props = OwnProps & {
  state: RootState;
  dispatch: Dispatch;
};

export function _StoreDataContainer(props: Props) {
  useEffect(() => {
    props.trigger(props.dispatch);
    if (props.cleanup) {
      return () => props.cleanup && props.cleanup(props.dispatch);
    }
    return undefined;
  }, [props.trigger, props.dispatch, props.cleanup]);

  if (!props.isReady(props.state)) {
    return <>{props.fallback}</>;
  }

  return <>{props.children}</>;
}

function mapStateToProps(state: RootState) {
  return {
    state,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dispatch,
  };
}

export const StoreDataContainer = connect(mapStateToProps, mapDispatchToProps)(_StoreDataContainer);
