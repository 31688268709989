export function createLiteralTypeCaster<T>(
  allowedValues: readonly string[],
  defaultValue: T = undefined as any
): (x: string) => T {
  return (x: string, _defaultValue: T = defaultValue as any) => {
    if (allowedValues.find(value => value === x)) {
      return (x as unknown) as T;
    }
    if (_defaultValue !== undefined) {
      return _defaultValue;
    }
    throw new Error(
      `The provided value '${x}' is not allowed for type. Allowed values are ${allowedValues}.`
    );
  };
}

export const nameOf = <T>(name: keyof T) => name;
