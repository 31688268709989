import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { Config as PermissionGateConfig, usePermissionGate } from 'Hooks/usePermissionGate';

type Props = PermissionGateConfig & {
  noAccessRedirectTo: string;
  children: ReactNode;
};

export function RouteWithPermissionGate(props: Props) {
  const hasAccess = usePermissionGate({
    permissions: props.permissions,
    requiresAll: props.requiresAll,
  });

  if (hasAccess === undefined) {
    // access has not been determined yet
    return null;
  }

  if (hasAccess) {
    return <>{props.children}</>;
  }

  return <Redirect data-testid="redirect" to={props.noAccessRedirectTo} />;
}
