import classNames from 'classnames';
import { InstalledApplicationPlaceholder } from 'Components/Application/Presentation/InstalledApplicationPlaceholder';
import { ApplicationDeploymentDetails } from 'Models/Application/ApplicationDeployment';
import { ApplicationIdentity } from 'Models/Application/ApplicationStore';
import React from 'react';
import { isGreater } from 'Utilities/AppVersionUtils';
import styles from './InstalledApplications.module.css';
import { EllipsisMenu, EllipsisAction } from '../../../Util/Controls/EllipsisMenu';

type Props = {
  deployment: ApplicationDeploymentDetails;
  deleteDeployment: (deploymentId: string) => void;
  applicationIdentity?: ApplicationIdentity;
  onClick: () => void;
  updateDeployment: (deploymentId: string, appVersion: string) => void;
  openApplicationEdit: (deploymentId: string) => void;
  startDeployment: (deploymentId: string) => void;
  stopDeployment: (deploymentId: string) => void;
  restartDeployment: (deploymentId: string) => void;
};

export function InstalledApplicationTile(props: Props) {
  const displayName = props.applicationIdentity
    ? props.applicationIdentity.displayName
    : props.deployment.appId;

  function canUpgrade() {
    return (
      props.applicationIdentity &&
      isGreater(props.applicationIdentity.latestRelease, props.deployment.appVersion)
    );
  }

  const menuActions: EllipsisAction[] = [];

  let isStarted = true;
  for (let res of props.deployment.deployedResources) {
    if (res.type === 'container' && res.workloadStatus !== 'started') {
      isStarted = false;
      break;
    }
  }

  // TODO: Hide commands if device is not connected
  if (props.deployment.deploymentStatus === 'deployed') {
    if (!isStarted) {
      menuActions.push({
        name: 'Start',
        action: () => props.startDeployment(props.deployment.id),
      });
    } else {
      menuActions.push({
        name: 'Stop',
        action: () => props.stopDeployment(props.deployment.id),
      });
      menuActions.push({
        name: 'Restart',
        action: () => props.restartDeployment(props.deployment.id),
      });
    }
  }

  menuActions.push({
    name: 'Edit',
    action: () => props.openApplicationEdit(props.deployment.id),
  });

  if (
    props.deployment.deploymentStatus !== 'pending_removal' &&
    props.deployment.deploymentStatus !== 'removing'
  ) {
    menuActions.push({
      name: 'Uninstall',
      action: () => props.deleteDeployment(props.deployment.id),
    });
  }

  return (
    <div
      className={classNames(styles.applicationTile, 'hover-visibility-trigger')}
      data-testid="application-tile"
      onClick={props.onClick}
    >
      <div className={styles.appImage}>
        <div className={styles.appMenu} data-testid="app-menu">
          <EllipsisMenu actions={menuActions} event={'click'} visibleOnHover={true} />
        </div>
        <InstalledApplicationPlaceholder text={displayName} deployment={props.deployment} />
      </div>
      <div>
        <div className="truncate-ellipsis">{displayName}</div>
        <div>
          {props.deployment.appVersion}
          {canUpgrade() && (
            <i className="text-neutral" data-testid="update-available-hint">
              - New version available
            </i>
          )}
        </div>
      </div>
    </div>
  );
}
