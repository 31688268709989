import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import React from 'react';
import { triggerNavigationAction } from 'Actions/Navigation/NavigationActions';

type _NavLinkProps = {
  to: string;
  children: any;
  className?: string;
};

type NavLinkProps = {
  onNavigation: () => void;
} & _NavLinkProps;

export function _NavLink(props: NavLinkProps) {
  return (
    <a onClick={props.onNavigation} className={props.className}>
      {props.children}{' '}
    </a>
  );
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: _NavLinkProps) {
  return {
    onNavigation: () => dispatch(triggerNavigationAction(ownProps.to)),
  };
}

export const ConnectedNavLink = connect(null, mapDispatchToProps)(_NavLink);
