import React from 'react';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import { CreateDeviceJob } from 'Models/DeviceJobs/DeviceJobs';
import { SystemInfo } from 'Models/System/System';
import { DeviceSummaryGeneralComponent } from './DeviceSummaryGeneralComponent';
import { DeviceSummaryActions } from './DeviceSummaryActions';
import { DeviceSummaryFirmwareUpgradeWrapper } from './DeviceSummaryFirmwareUpgradeWrapper';
import { FirmwareInfo } from 'Models/Device/FirmwareInfo';
import { FirmwareUpgradeDetails } from 'Models/Device/FirmwareUpgradeDetails';
import semver from 'semver';
import { DeviceInfo } from 'Models/Device/DeviceInfo';

type Props = {
  deviceInfo: DeviceInfo | null;
  systemInfo: SystemInfo | null;
  device: EnrolledDevice | null;
  firmwareInfo: FirmwareInfo | null;
  firmwareUpgradeDetails: FirmwareUpgradeDetails | null;
  featureFlags: Map<string, boolean>;
  createDeviceJob: (systemId: string, serialNumber: string, job: CreateDeviceJob) => void;
};

export function DeviceSummary({
  deviceInfo: deviceInfo,
  systemInfo: info,
  device,
  firmwareInfo,
  firmwareUpgradeDetails,
  featureFlags,
  createDeviceJob,
}: Props) {
  const isFirmwareUpgradeOngoing: boolean = firmwareUpgradeDetails
    ? firmwareUpgradeDetails.firmwareUpgradeStatus.toLowerCase() !== 'idle'
    : false;

  let supportsFirmwareUpgradeDeviceJob: boolean = true;

  if (
    firmwareInfo &&
    (firmwareInfo.currentFirmware.firmwareVersion === 'local' ||
      (firmwareInfo.availabilityLevel.toLowerCase() === 'main' &&
        semver.lt(firmwareInfo.currentFirmware.firmwareVersion, '0.3.0')))
  ) {
    supportsFirmwareUpgradeDeviceJob = false;
  }

  const isManualFirmwareSelectionAllowed: boolean = featureFlags.get(
    'AllowManualFirmwareSelection'
  )!;
  return (
    <div className="form-group">
      <DeviceSummaryGeneralComponent
        time={deviceInfo?.time?.timeZone}
        systemInfo={info}
        firmwareInfo={firmwareInfo}
        device={device}
      />
      {device && (
        <DeviceSummaryFirmwareUpgradeWrapper
          device={device}
          createDeviceJob={createDeviceJob}
          firmwareInfo={firmwareInfo}
          firmwareUpgradeDetails={firmwareUpgradeDetails}
          isManualFirmwareSelectionAllowed={isManualFirmwareSelectionAllowed}
          supportsFirmwareUpgradeDeviceJob={supportsFirmwareUpgradeDeviceJob}
        />
      )}
      <DeviceSummaryActions
        device={device}
        createDeviceJob={createDeviceJob}
        isFirmwareUpgradeOngoing={isFirmwareUpgradeOngoing}
      />
    </div>
  );
}
