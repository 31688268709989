import { InlineInput } from 'Components/Util/InlineFormControls/InlineInput';
import {
  ApplicationArtifact,
  ApplicationArtifactParameter,
} from 'Models/Application/ApplicationStore';
import React from 'react';
import _ from 'lodash';
import styles from './DeploymentParametersView.module.css';
import { DeployedResourceParameterDetails } from 'Models/Application/ApplicationDeployment';
import ReactTooltip from 'react-tooltip';

type Props = {
  parameterValues: { [scopedKey: string]: DeployedResourceParameterDetails };
  artifact: ApplicationArtifact;
  update: (scopedKey: string, value: string, isCustomValue: boolean) => void;
};

export function DeploymentParametersView(props: Props) {
  const filledFields = {};
  const filteredResources = props.artifact.resources
    .filter(res => res.parameters.length)
    .map(res => {
      filledFields[res.name] = 0;
      res.parameters.map(param => {
        const field = props.parameterValues[param.scopedKey];
        if (field && field.value && field.value.trim() && field.isCustomValue)
          filledFields[res.name]++;
      });
    });
  const initialParameterValues: {
    [scopedKey: string]: DeployedResourceParameterDetails;
  } = props.parameterValues;

  const handleParameterValueChange = (scopedKey: string, value: string) => {
    if (!initialParameterValues[scopedKey]) {
      initializeParameterValue(scopedKey, value, true);
    }

    let isCustomValue =
      initialParameterValues[scopedKey] && initialParameterValues[scopedKey].isCustomValue;
    if (
      !initialParameterValues[scopedKey].isCustomValue &&
      value !== initialParameterValues[scopedKey].value
    ) {
      isCustomValue = true;
    }
    props.update(scopedKey, value, isCustomValue);
  };

  const onUseDefaultValue = (scopedKey: string) => {
    props.update(scopedKey, '', false);
  };

  const handleParameterValueClick = (
    scopedKey: string,
    parameter: ApplicationArtifactParameter
  ) => {
    const parameterDefaultValue = '' + parameter.defaultValue;
    if (!initialParameterValues[scopedKey]) {
      initializeParameterValue(scopedKey, parameterDefaultValue, false);
    }
    if (initialParameterValues[scopedKey] && !props.parameterValues[scopedKey].isCustomValue) {
      props.update(scopedKey, parameterDefaultValue, true);
    }
  };

  const initializeParameterValue = (scopedKey: string, value: string, isCustomValue: boolean) => {
    initialParameterValues[scopedKey] = {
      key: scopedKey.split(':')[1],
      value: value,
      isCustomValue: isCustomValue,
    };
  };

  return filteredResources.length ? (
    <div className={styles.container}>
      {_.map(
        props.artifact.resources,
        resource =>
          resource.parameters.length > 0 && (
            <div className="panel panel-default" key={resource.name}>
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href={`#${sanitizeTagId(resource.name)}`}
                    data-testid="panel-title"
                  >
                    {resource.displayName || resource.name} ({filledFields[resource.name]}/
                    {resource.parameters.length})
                  </a>
                </h4>
              </div>
              <div
                id={sanitizeTagId(resource.name)}
                className="panel-collapse collapse"
                role="tabpanel"
              >
                <div className="panel-body">
                  <div className="form-group">
                    <table className="table">
                      <tbody>
                        {resource.parameters.map(parameter => {
                          const scopedParameter = props.parameterValues[parameter.scopedKey];
                          const isDefaultValue = !scopedParameter || !scopedParameter.isCustomValue;
                          return (
                            <tr key={parameter.scopedKey}>
                              <td className="td-md">
                                <span title={parameter.description}>{parameter.displayName}</span>
                              </td>
                              <td className="td-md">
                                <InlineInput
                                  value={!isDefaultValue ? scopedParameter.value : ''}
                                  placeholder={isDefaultValue ? parameter.defaultValue : ''}
                                  testId={parameter.scopedKey}
                                  onChange={value => {
                                    handleParameterValueChange(parameter.scopedKey, value);
                                  }}
                                  onClick={() => {
                                    handleParameterValueClick(parameter.scopedKey, parameter);
                                  }}
                                />
                              </td>
                              <td className="td-xs">
                                {isDefaultValue && (
                                  <>
                                    <a
                                      data-tip="The default value will be used for this parameter."
                                      data-place="right"
                                    >
                                      <i className={`ico-sc-info`} />
                                    </a>
                                    <ReactTooltip />
                                  </>
                                )}
                                {!isDefaultValue && (
                                  <>
                                    <a
                                      data-tip="Revert parameter to its default value."
                                      data-testid="rever-parameter"
                                      data-place="right"
                                      onClick={_ => onUseDefaultValue(parameter.scopedKey)}
                                    >
                                      <i className={`ico-sc-step-back ${styles['reset-button']}`} />
                                    </a>
                                    <ReactTooltip />
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  ) : (
    <h4>
      {' '}
      No parameters to set. Click <strong>Submit</strong> to proceed.
    </h4>
  );
}

function sanitizeTagId(value: string): string {
  return value.split('.').join('_dot_');
}
