import {
  DeviceEnrollmentFormReducerState,
  DeviceEnrollmentFormReducerErrorState,
} from 'Reducers/Form/DeviceEnrollmentFormReducer';

export function validateDeviceEnrollmentForm(
  form: DeviceEnrollmentFormReducerState
): DeviceEnrollmentFormReducerErrorState {
  const errors: DeviceEnrollmentFormReducerErrorState = {
    activationCodeError: '',
    serialNumberError: '',
    deviceNameError: '',
  };

  if (form.deviceName.trim() === '') {
    errors.deviceNameError = 'The device name cannot be blank.';
  }

  if (form.deviceName.length > 150) {
    errors.deviceNameError = 'The device name cannot exceed 150 characters.';
  }

  if (form.activationCode.trim() === '') {
    errors.activationCodeError = 'The activation code cannot be blank.';
  }

  let serialNumber = form.serialNumber.trim();
  if (serialNumber === '') {
    errors.serialNumberError = 'The serial number cannot be blank.';
  } else if (serialNumber.length < 7) {
    errors.serialNumberError = 'The serial number must contains at least 7 characters.';
  } else if (serialNumber.length > 64) {
    errors.serialNumberError = 'The serial number must contains at most 64 characters.';
  } else if (serialNumber.match('^[0-9-]$')) {
    errors.serialNumberError = 'The serial number must contains only ASCII digits or hyphens.';
  } else if (serialNumber[0] == '-' || serialNumber[serialNumber.length - 1] == '-') {
    errors.serialNumberError = 'The serial number cannot start or end with an hyphen.';
  }

  return errors;
}

export function hasErrors(errors: DeviceEnrollmentFormReducerErrorState): boolean {
  return (
    errors.deviceNameError !== '' ||
    errors.serialNumberError !== '' ||
    errors.activationCodeError !== ''
  );
}
