import { FETCH_FIRMWARE_IMAGES, RECEIVE_FIRMWARE_IMAGES } from 'Actions/ActionTypes';
import { FirmwareImage } from 'Models/Device/FirmwareImage';

export type FetchFirmwareImagesAction = {
  type: typeof FETCH_FIRMWARE_IMAGES;
  firmwareName: string;
  systemId: string;
};

export function fetchFirmwareImagesAction(
  firmwareName: string,
  systemId: string
): FetchFirmwareImagesAction {
  return {
    type: FETCH_FIRMWARE_IMAGES,
    firmwareName,
    systemId,
  };
}

export type ReceiveFirmwareImagesAction = {
  type: typeof RECEIVE_FIRMWARE_IMAGES;
  firmwareImages: FirmwareImage[];
};

export function receiveFirmwareImagesAction(
  firmwareImages: FirmwareImage[]
): ReceiveFirmwareImagesAction {
  return {
    type: RECEIVE_FIRMWARE_IMAGES,
    firmwareImages,
  };
}
