import * as React from 'react';
import { useState } from 'react';
import { ConfirmationModal } from '../Modal';

type DeleteButtonWithConfirmationModalProps = {
  delete: () => void;
  message: string;
  dataTip: string;
};

export function DeleteButtonWithConfirmationModal(props: DeleteButtonWithConfirmationModalProps) {
  const [isModalVisible, setModalVisibility] = useState(false);

  return (
    <div className="cursor-auto pull-right">
      <ConfirmationModal
        text={props.message}
        onCancel={() => {
          setModalVisibility(false);
        }}
        show={isModalVisible}
        onConfirm={() => {
          props.delete();
          setModalVisibility(false);
        }}
      />
      <a
        data-tip={props.dataTip}
        onClick={e => {
          setModalVisibility(true);
          e.stopPropagation();
        }}
        data-testid="open-deletion-modal"
      >
        <i className="ico-sc-clear text-danger visible-on-hover" />
      </a>
    </div>
  );
}
