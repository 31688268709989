import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { authenticationService } from '../Authentication/AuthenticationService';
import PrivateApp from './PrivateApp';
import { useDispatch } from 'react-redux';
import { setLoginError } from 'Actions/User/UserActions';

function SignInCallback(props: RouteComponentProps) {
  const dispatch = useDispatch();
  authenticationService
    .loginCallback()
    .then(return_url => props.history.replace(return_url))
    .catch(error => {
      console.log(error);
      dispatch(setLoginError('Error while logging in'));
      props.history.replace('/');
    });
  return null;
}

function SignOutCallback(props: RouteComponentProps) {
  authenticationService
    .logoutCallback()
    .then(() => authenticationService.redirectToLogin('/'))
    .catch(error => {
      console.log(error);
      props.history.push('/');
    });
  return null;
}

function SilentRenewCallback(props: RouteComponentProps) {
  const dispatch = useDispatch();
  authenticationService.renewCallback().catch(error => {
    console.log(error);
    dispatch(setLoginError('Error while logging in'));
    props.history.push('/');
  });
  return null;
}

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/signin-callback" component={SignInCallback} />
        <Route exact path="/signout-callback" component={SignOutCallback} />
        <Route exact path="/silent-renew" component={SilentRenewCallback} />
        <Route path="/" component={PrivateApp} />
      </Switch>
    </Router>
  );
}
