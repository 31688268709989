import classNames from 'classnames';
import React from 'react';

type Props = {
  isDisabled: boolean;
  iconClass: string;
  dataTestId: string;
  dataTip: string;
  onClick: () => void;
};

export default function DisableableIcon(props: Props) {
  return (
    <a
      style={props.isDisabled ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
      data-testid={props.dataTestId}
      onClick={props.onClick}
      data-tip={props.dataTip}
    >
      <i
        className={props.isDisabled ? classNames(props.iconClass, 'text-muted') : props.iconClass}
      />
    </a>
  );
}
