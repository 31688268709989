import React, { useState } from 'react';
import Modal from '../../Util/Modal';
import Input from 'Components/Util/Input';
import { Select } from 'Components/Util/Select';

type ServiceRegistrationDialogProps = {
  submitServiceRegistration: (servicename: string, role: string, clientId: string) => void;
  roles: { id: string; name: string }[];
  onClose: () => void;
};

export default function ServiceRegistrationDialog(props: ServiceRegistrationDialogProps) {
  // State
  const [serviceName, setServiceName] = useState('');
  const [ServiceNameError, setServiceNameError] = useState('');
  const [clientId, setClientId] = useState('');
  const [role, setRole] = useState('');
  const roles = props.roles;

  function canSubmit(): boolean {
    const trimmedServiceName = serviceName.trim();
    const clientIdFilled = clientId;
    return trimmedServiceName !== '' && trimmedServiceName.length <= 256 && clientIdFilled !== '';
  }

  function setServiceNameValue(value: string) {
    setServiceName(value.trim());
    if (serviceName.length > 256) {
      setServiceNameError('The service principal name cannot contain more than 256 characters.');
    } else {
      setServiceNameError('');
    }
  }

  function submitRegistration() {
    if (canSubmit()) {
      props.submitServiceRegistration(
        serviceName.trim(),
        role.toLowerCase().replace(' ', '_'),
        clientId.trim()
      );
      props.onClose();
    }
  }

  return (
    <Modal
      title="Register Service"
      show={true}
      yesLabel="Register"
      onYes={submitRegistration}
      canYes={canSubmit()}
      cancelLabel="Cancel"
      onCancel={props.onClose}
    >
      <div className="modal-body">
        <form
          className="form-horizontal row"
          onSubmit={e => {
            e.preventDefault();
            submitRegistration();
          }}
        >
          <div className="form-group">
            <div className="col-sm-12">
              <Input
                title="Display Name"
                placeholder="Ex.: my-service"
                value={serviceName}
                onChange={setServiceNameValue}
                focusByDefault={true}
                errorMsg={ServiceNameError}
                testId="displayname-input"
              />
            </div>
            <div className="col-sm-12">
              <Input
                title="Client Id"
                placeholder="Genetec Client Identifier"
                value={clientId}
                onChange={setClientId}
                focusByDefault={false}
                testId="clientid-input"
              />
            </div>
            <div className="col-sm-12">
              <Select
                title="Role"
                elems={roles}
                selectedElem={role}
                onSelect={elem => setRole(elem.id)}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
