import * as React from 'react';
import {
  AuthenticatedUser,
  authenticationService,
} from '../../Authentication/AuthenticationService';
import { RootState } from 'Reducers/RootReducer';
import { connect } from 'react-redux';

type Props = {
  user?: AuthenticatedUser;
};

export function _UserMenuItem(props: Props) {
  return (
    <li className="dropdown">
      <a className="dropdown-toggle" data-toggle="dropdown" href="#">
        <i className="ico-sc-user" />
      </a>
      <ul className="dropdown-menu pull-right">
        {props.user!.isAuthenticated ? (
          <li>
            <a onClick={() => authenticationService.redirectToLogout()}>
              <i className="ico-sc-logout" />
              <span>Logout</span>
            </a>
          </li>
        ) : (
          <li>
            <a onClick={() => authenticationService.redirectToLogin('/')}>
              <i className="ico-sc-login" />
              <span>Login</span>
            </a>
          </li>
        )}
      </ul>
    </li>
  );
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user.authUser,
  };
}

export const UserMenuItem = connect(mapStateToProps)(_UserMenuItem);
