import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import { FETCH_USER_INVITATION_LIST } from 'Actions/ActionTypes';
import { trackFetchAction, clearFetchAction } from 'Actions/Loading/LoadingActions';
import { put, select, call } from 'redux-saga/effects';
import { RootState } from 'Reducers/RootReducer';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { ServiceCallerError } from 'Services/ServiceCaller';
import {
  GetUserInvitationsResponse,
  getSystemInvitationsRequest,
} from 'Services/RegistrationProxy';
import {
  FetchUserInvitationListAction,
  receiveUserInvitationListFromApiAction,
} from 'Actions/Users/UserInvitationActions';

export function* fetchUserInvitationListSaga(action: FetchUserInvitationListAction) {
  yield put(trackFetchAction('fetchUserInvitationList'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    const response: GetUserInvitationsResponse = yield call(
      getSystemInvitationsRequest,
      token,
      action.systemId
    );
    yield put(receiveUserInvitationListFromApiAction(response.response));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occurred while fetching user invitations..'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('fetchUserInvitationList'));
  }
}

export function* watchFetchUserInvitationList() {
  yield cancelOnNavigationEffect(FETCH_USER_INVITATION_LIST, fetchUserInvitationListSaga);
}
