import { FirmwareUpgradeHistory } from 'Models/Device/FirmwareUpgradeHistory';
import styles from './FirmwareUpgradeHistoryList.module.css';
import React, { useMemo, useState } from 'react';
import Popover from 'Components/Util/Popover';
import { UpgradeHistoryResult } from './UpgradeHistoryResult';
import { useSorter } from 'Hooks/useSorter';
import SearchAction from 'Components/Util/SearchAction';
import classNames from 'classnames';
import { SortableTh } from 'Components/Util/Table/SortableTh';
import { dateToReadableString } from 'Utilities/DateUtils';

type Props = {
  firmwareUpgradeHistory: FirmwareUpgradeHistory[];
  onRefresh: () => void;
};

export function FirmwareUpgradeHistoryList(props: Props) {
  const [textFilter, setTextFilter] = useState('');
  const lowerCasedTextFilter = textFilter.toLowerCase();
  const filteredFirmwareUpgradeHistory = useMemo(
    () =>
      props.firmwareUpgradeHistory.filter(
        fwUpgradeHistory =>
          fwUpgradeHistory.upgradeStartTime
            .toString()
            .toLowerCase()
            .includes(lowerCasedTextFilter) ||
          fwUpgradeHistory.upgradeEndTime
            .toString()
            .toLowerCase()
            .includes(lowerCasedTextFilter) ||
          fwUpgradeHistory.oldFirmware?.toLowerCase().includes(lowerCasedTextFilter) ||
          fwUpgradeHistory.newFirmware?.toLowerCase().includes(lowerCasedTextFilter) ||
          fwUpgradeHistory.result?.toLowerCase().includes(lowerCasedTextFilter)
      ),

    [textFilter, props.firmwareUpgradeHistory]
  );

  const sorter = useSorter(filteredFirmwareUpgradeHistory, 'date');
  return (
    <>
      <div className="client-portal-table-header">
        <h2 className="horizontal-flex">
          Firmware History
          <div
            data-testid="firmwareupgrade-history-count"
            className="margin-left-xs"
          >{`(${filteredFirmwareUpgradeHistory.length})`}</div>{' '}
        </h2>
        <div className={classNames('pull-right', styles.firmwareUpgradeHistoryTableActions)}>
          <SearchAction
            dataTip="Search firmware history"
            value={textFilter}
            onChange={value => setTextFilter(value)}
          />
          <a
            data-tip="Refresh"
            onClick={props.onRefresh}
            data-testid="refresh-firmwareupgrade-history-list"
          >
            <i className="ico-sc-refresh" />
          </a>
        </div>
      </div>
      <table className="client-portal-table table">
        <thead>
          <tr>
            <SortableTh
              field="upgradeStartTime"
              displayName="Start Time"
              sorter={sorter}
              className="td-xs"
            />
            <SortableTh
              field="upgradeEndTime"
              displayName="End Time"
              sorter={sorter}
              className="td-xs"
            />
            <SortableTh
              field="oldFirmware"
              displayName="Previous Version"
              sorter={sorter}
              className="td-xs"
            />
            <SortableTh
              field="newFirmware"
              displayName="New Version"
              sorter={sorter}
              className="td-xs"
            />
            <SortableTh field="result" displayName="Result" sorter={sorter} className="td-xs" />
          </tr>
        </thead>
      </table>
      <div className={classNames('flex-content', styles.noScroll)}>
        {props.firmwareUpgradeHistory.length > 0 ? (
          <table className="client-portal-table table table-header fixed-table">
            <tbody>
              {sorter.elements.map((fwUpgradeHistory, index) => (
                <tr key={index}>
                  <td>{dateToReadableString(fwUpgradeHistory.upgradeStartTime)} </td>
                  <td>{dateToReadableString(fwUpgradeHistory.upgradeEndTime)} </td>
                  <td>{formatFirmwareVersion(fwUpgradeHistory.oldFirmware)}</td>
                  <td>{formatFirmwareVersion(fwUpgradeHistory.newFirmware)}</td>
                  <td>
                    <Popover
                      disabled={false}
                      placement="right"
                      content={<>{fwUpgradeHistory.message}</>}
                    >
                      <div>{<UpgradeHistoryResult result={fwUpgradeHistory.result} />}</div>
                    </Popover>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h3>The device has not yet had any firmware upgrade.</h3>
        )}
      </div>
    </>
  );
}

function formatFirmwareVersion(str: string | null): string {
  return (str || '').split('+')[0];
}
