// Compare versions with the 'X.Y.Z' format.
export function compareVersions(versionA: string, versionB: string) {
  const constituantsA = versionA.split('.');
  const constituantsB = versionB.split('.');
  for (let i = 0; i < constituantsA.length; i++) {
    const a = parseInt(constituantsA[i]);
    const b = parseInt(constituantsB[i]);
    if (a < b) {
      return -1;
    }
    if (b < a) {
      return 1;
    }
  }
  return 0;
}

export function isGreaterOrEqual(a: string, b: string) {
  return compareVersions(a, b) >= 0;
}

export function isGreater(a: string, b: string) {
  return compareVersions(a, b) > 0;
}
