import React from 'react';
import './SectionExpandTrigger.css';

type Props = {
  isExpanded: boolean;
  text: string;
  onClick: (state: boolean) => void;
  testId?: string;
};

export function SectionExpandTrigger(props: Props) {
  const caretClass = props.isExpanded ? 'expanded' : 'collapsed';
  return (
    <div
      className="section-expand-trigger cursor-pointer no-select"
      onClick={() => props.onClick(!props.isExpanded)}
      data-testid={props.testId}
    >
      <span className="text-primary">{props.text}</span>
      <div
        style={{ display: 'inline-block' }}
        className={`section-caret ${caretClass}`}
        data-testid="caret"
      >
        <i className="ico-sc-next" />
      </div>
    </div>
  );
}
