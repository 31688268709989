import * as React from 'react';

export function PageWithSideMenu(props: any) {
  return (
    <div id="page-wrapper" className="with-sidemenu">
      <div className="row padding-left-sm padding-right-sm">
        <div className="col-lg-12">{props.children}</div>
      </div>
    </div>
  );
}

type BasicPageProps = {
  children: React.ReactChild;
};

export function BasicPage(props: BasicPageProps) {
  return (
    <div id="content" className="container-fluid">
      <div id="page-wrapper" className="no-padding-left padding-top-md">
        <div className="row padding-left-md padding-right-md">
          <div className="col-lg-12">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
