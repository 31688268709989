import {
  clearDeviceEnrollmentFormAction,
  submitDeviceEnrollmentFormAction,
  updateDeviceEnrollmentFormAction,
} from 'Actions/Form/DeviceEnrollmentFormActions';
import Input from 'Components/Util/Input';
import Modal from 'Components/Util/Modal';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import {
  DeviceEnrollmentFormReducerErrorState,
  DeviceEnrollmentFormReducerState,
} from 'Reducers/Form/DeviceEnrollmentFormReducer';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';

type Props = {
  systemId: string;
  form: DeviceEnrollmentFormReducerState & DeviceEnrollmentFormReducerErrorState;
  update: (_: Partial<DeviceEnrollmentFormReducerState>) => void;
  submit: (
    systemId: string,
    formState: DeviceEnrollmentFormReducerState,
    callback: () => void
  ) => void;
  clear: () => void;
  isLoading: boolean;
};

export function _DeviceEnrollmentModal(props: Props) {
  const [isVisible, setVisible] = useState(false);
  const [areFieldsVisible, setFieldsVisible] = useState(false);

  return (
    <Fragment>
      <a
        data-tip="Enroll a new device"
        onClick={e => {
          setVisible(true);
          e.stopPropagation();
        }}
        data-testid="open-enrollment-modal"
      >
        <i className="ico-sc-add" />
      </a>
      <Modal
        title="Enroll a device"
        show={isVisible}
        onCancel={() => {
          setVisible(false);
          props.clear();
          setFieldsVisible(false);
        }}
        onYes={() =>
          props.submit(props.systemId, props.form, () => {
            setVisible(false);
            props.clear();
            setFieldsVisible(false);
          })
        }
        canYes={!props.isLoading}
        canCancel={!props.isLoading}
        yesLabel="Create"
      >
        <div className="row">
          <Input
            title="Device Name"
            value={props.form.deviceName}
            errorMsg={props.form.deviceNameError}
            onChange={deviceName => props.update({ deviceName })}
          />
        </div>
        <div className="row">
          {areFieldsVisible ? (
            <div className="form-group">
              <Input
                title="Serial Number"
                value={props.form.serialNumber}
                errorMsg={props.form.serialNumberError}
                onChange={serialNumber => props.update({ serialNumber })}
              />
              <Input
                title="Activation Code"
                value={props.form.activationCode}
                errorMsg={props.form.activationCodeError}
                onChange={activationCode => props.update({ activationCode: activationCode })}
              />
            </div>
          ) : (
            <div className="col-lg-12">
              <a onClick={() => setFieldsVisible(true)}>or enter manually. </a>
            </div>
          )}
        </div>
      </Modal>
    </Fragment>
  );
}

function mapStateToProps(state: RootState) {
  return {
    form: state.form.deviceEnrollment,
    isLoading: state.loading.length > 0,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    update: (form: Partial<DeviceEnrollmentFormReducerState>) =>
      dispatch(updateDeviceEnrollmentFormAction(form)),
    clear: () => dispatch(clearDeviceEnrollmentFormAction()),
    submit: (systemId: string, form: DeviceEnrollmentFormReducerState, callback: () => void) =>
      dispatch(submitDeviceEnrollmentFormAction(systemId, form, callback)),
  };
}

export const DeviceEnrollmentModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(_DeviceEnrollmentModal);
