const Created = 201;
const NoContent = 204;
const BadRequest = 400;
const NotFound = 404;
const Conflict = 409;
const Forbidden = 403;
const InternalServerError = 500;
const GatewayTimeout = 504;

export class ServiceCallerError extends Error {
  code: string;
  name: string;

  constructor(serviceName: string, message: string, code: string) {
    super(message);
    this.code = code;
    this.name = serviceName;
  }
}

export class ForbiddenActionError extends ServiceCallerError {
  constructor(serviceCaller: string) {
    super(
      serviceCaller,
      'Permission denied. Ask an administrator of your system for this permission.',
      ''
    );
  }
}

export class ConflictError extends ServiceCallerError {
  constructor(serviceCaller: string) {
    super(
      serviceCaller,
      'The request could not be completed due to a conflict with the current state of the target resource.',
      ''
    );
  }
}

export class NotFoundError extends ServiceCallerError {
  constructor(serviceCaller: string) {
    super(
      serviceCaller,
      'The server did not find a current representation for the target resource.',
      ''
    );
  }
}

export class GatewayTimeOutError extends ServiceCallerError {
  constructor(serviceCaller: string) {
    super(
      serviceCaller,
      'The server did not receive a response on time in order to complete the request.',
      ''
    );
  }
}

export async function httpGet(serviceName: string, bearerToken: string, url: string): Promise<any> {
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  });

  if (response.ok) {
    return await response.json();
  } else if (response.status === BadRequest || response.status === InternalServerError) {
    const exception = await response.json();
    throw new ServiceCallerError(serviceName, exception.message, exception.exceptionCode);
  } else if (response.status === Forbidden) {
    throw new ForbiddenActionError(serviceName);
  }

  throw new Error('Unexpected error');
}

export async function httpPost(
  serviceName: string,
  bearerToken: string,
  url: string,
  body: any,
  headers?: any
): Promise<any> {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json;',
      ...headers,
    },
    body: JSON.stringify(body),
  });

  if (response.ok) {
    if (response.status === Created) {
      // 201 Created
      return;
    }
    if (response.status === NoContent) {
      // 204 No Content
      return;
    }
    return await response.json();
  } else if (response.status === BadRequest || response.status === InternalServerError) {
    const exception = await response.json();
    throw new ServiceCallerError(serviceName, exception.message, exception.exceptionCode);
  } else if (response.status === NotFound) {
    throw new NotFoundError(serviceName);
  } else if (response.status === Conflict) {
    throw new ConflictError(serviceName);
  } else if (response.status === GatewayTimeout) {
    throw new GatewayTimeOutError(serviceName);
  } else if (response.status === Forbidden) {
    throw new ForbiddenActionError(serviceName);
  }

  throw new Error('Unexpected error');
}

export async function httpPut(
  serviceName: string,
  bearerToken: string,
  url: string,
  body: any,
  headers?: any
): Promise<any> {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json;',
      ...headers,
    },
    body: JSON.stringify(body),
  });

  if (response.ok) {
    if (response.status === NoContent) {
      // 204 No Content
      return;
    }
    return await response.json();
  } else if (response.status === BadRequest || response.status === InternalServerError) {
    const exception = await response.json();
    throw new ServiceCallerError(serviceName, exception.message, exception.exceptionCode);
  } else if (response.status === Forbidden) {
    throw new ForbiddenActionError(serviceName);
  }

  throw new Error('Unexpected error');
}

export async function httpDelete(
  serviceName: string,
  bearerToken: string,
  url: string
): Promise<void> {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json;',
      'If-Match': '*',
    },
  });

  if (response.ok) {
    return;
  } else if (response.status === BadRequest || response.status === InternalServerError) {
    const exception = await response.json();
    throw new ServiceCallerError(serviceName, exception.message, exception.exceptionCode);
  } else if (response.status === Forbidden) {
    throw new ForbiddenActionError(serviceName);
  }

  throw new Error('Unexpected error');
}
