import {
  APPLICATION_INSTALLATION_BACKTRACK_WIZARD,
  APPLICATION_INSTALLATION_SELECT_APPLICATION,
  APPLICATION_INSTALLATION_FETCH_SELECTED_ARTIFACT,
  APPLICATION_INSTALLATION_UPDATE_PARAMETER_VALUES,
  CLEAR_APPLICATION_INSTALLATION_FORM,
  SUBMIT_APPLICATION_INSTALLATION_FORM,
  SHOW_APPLICATION_INSTALLATION_FORM,
  SHOW_APPLICATION_INSTALLATION_UPDATE_FORM,
  APPLICATION_EDIT_PARAMETER,
  EDIT_APPLICATION_PARAMETERS_ACTION,
  APPLICATION_INSTALLATION_RECEIVE_SELECTED_ARTIFACT,
} from 'Actions/ActionTypes';
import { ApplicationArtifact, ApplicationIdentity } from 'Models/Application/ApplicationStore';
import { ApplicationDeploymentDetails } from 'Models/Application/ApplicationDeployment';

export type ApplicationInstallationBacktrackWizardAction = {
  type: typeof APPLICATION_INSTALLATION_BACKTRACK_WIZARD;
};

export function applicationInstallationBacktrackWizardAction(): ApplicationInstallationBacktrackWizardAction {
  return {
    type: APPLICATION_INSTALLATION_BACKTRACK_WIZARD,
  };
}

export type ApplicationInstallationSelectApplicationAction = {
  type: typeof APPLICATION_INSTALLATION_SELECT_APPLICATION;
  application: ApplicationIdentity;
};

export type ReceiveSelectedApplicationArtifactAction = {
  type: typeof APPLICATION_INSTALLATION_RECEIVE_SELECTED_ARTIFACT;
  artifact: ApplicationArtifact;
};

export function receiveSelectedApplicationArtifactAction(
  artifact: ApplicationArtifact
): ReceiveSelectedApplicationArtifactAction {
  return {
    type: APPLICATION_INSTALLATION_RECEIVE_SELECTED_ARTIFACT,
    artifact,
  };
}

export function applicationInstallationSelectApplicationAction(
  application: ApplicationIdentity
): ApplicationInstallationSelectApplicationAction {
  return {
    type: APPLICATION_INSTALLATION_SELECT_APPLICATION,
    application,
  };
}

export type FetchSelectedArtifactAction = {
  type: typeof APPLICATION_INSTALLATION_FETCH_SELECTED_ARTIFACT;
  appId: string;
  appVersion: string;
};

export function fetchSelectedArtifactAction(
  appId: string,
  appVersion: string
): FetchSelectedArtifactAction {
  return {
    type: APPLICATION_INSTALLATION_FETCH_SELECTED_ARTIFACT,
    appId,
    appVersion,
  };
}

export type ApplicationEditParameter = {
  type: typeof APPLICATION_EDIT_PARAMETER;
  artifact: ApplicationArtifact;
  currentlyInstalled: ApplicationDeploymentDetails;
};

export function applicationEditParameter(
  artifact: ApplicationArtifact,
  currentlyInstalled: ApplicationDeploymentDetails
): ApplicationEditParameter {
  return {
    type: APPLICATION_EDIT_PARAMETER,
    artifact,
    currentlyInstalled,
  };
}

export type ApplicationInstallationUpdateParameterValuesAction = {
  type: typeof APPLICATION_INSTALLATION_UPDATE_PARAMETER_VALUES;
  values: { [key: string]: { value: string; isCustomValue: boolean } };
};

export function applicationInstallationUpdateParameterValuesAction(values: {
  [scopedKey: string]: { value: string; isCustomValue: boolean };
}): ApplicationInstallationUpdateParameterValuesAction {
  return {
    type: APPLICATION_INSTALLATION_UPDATE_PARAMETER_VALUES,
    values,
  };
}

export type ClearApplicationInstallationFormAction = {
  type: typeof CLEAR_APPLICATION_INSTALLATION_FORM;
};

export function clearApplicationInstallationFormAction(): ClearApplicationInstallationFormAction {
  return {
    type: CLEAR_APPLICATION_INSTALLATION_FORM,
  };
}

export type SubmitApplicationInstallationFormAction = {
  type: typeof SUBMIT_APPLICATION_INSTALLATION_FORM;
};

export function submitApplicationInstallationFormAction(): SubmitApplicationInstallationFormAction {
  return {
    type: SUBMIT_APPLICATION_INSTALLATION_FORM,
  };
}

export type ShowApplicationInstallationFormAction = {
  type: typeof SHOW_APPLICATION_INSTALLATION_FORM;
};

export function showApplicationInstallationFormAction(): ShowApplicationInstallationFormAction {
  return {
    type: SHOW_APPLICATION_INSTALLATION_FORM,
  };
}

export type ShowApplicationInstallationUpdateFormAction = {
  type: typeof SHOW_APPLICATION_INSTALLATION_UPDATE_FORM;
  currentlyInstalled: ApplicationDeploymentDetails;
};

export function showApplicationInstallationUpdateFormAction(
  currentlyInstalled: ApplicationDeploymentDetails
): ShowApplicationInstallationUpdateFormAction {
  return {
    type: SHOW_APPLICATION_INSTALLATION_UPDATE_FORM,
    currentlyInstalled,
  };
}

export type ShowApplicationEditParametersFormAction = {
  type: typeof EDIT_APPLICATION_PARAMETERS_ACTION;
  deploymentId: string;
};
export function showApplicationEditParametersFormAction(
  deploymentId: string
): ShowApplicationEditParametersFormAction {
  return {
    type: EDIT_APPLICATION_PARAMETERS_ACTION,
    deploymentId,
  };
}
