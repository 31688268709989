import React from 'react';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from 'Components/PrivateRoute';
import { UserDetailsPage } from './UserDetails/UserDetailsPage';
import { UsersListPage } from './InvitationList/UsersListPage';
import { RouteWithPermissionGate } from 'Components/RouteWithPermissionGate';

type Props = {
  systemId: string;
};

export function UsersPage(props: Props) {
  return (
    <Switch>
      <PrivateRoute
        path="/systems/:systemId/Users/:principalId"
        render={routerProps => (
          <RouteWithPermissionGate
            noAccessRedirectTo={`/systems/${props.systemId}/Users`}
            permissions={['ViewPermissions']}
          >
            <UserDetailsPage {...routerProps} />
          </RouteWithPermissionGate>
        )}
        exact
      />
      <PrivateRoute path="/systems/:systemId/Users" component={UsersListPage} />
    </Switch>
  );
}
