import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import { ReceiveDeviceAction, ClearDeviceAction } from 'Actions/Device/DeviceActions';
import {
  RECEIVE_DEVICE,
  CLEAR_DEVICE,
  RECEIVE_DEVICE_INFO,
  RECEIVE_FIRMWARE_INFO,
  RECEIVE_FIRMWARE_UPGRADE_DETAILS,
} from 'Actions/ActionTypes';
import { ApplicationDeploymentDetails } from 'Models/Application/ApplicationDeployment';
import {
  ReceiveDeviceInfoAction,
  ReceiveFirmwareInfoAction,
  ReceiveDeviceFirmwareUpgradeDetailsAction,
} from 'Actions/Device/DeviceDetailsActions';
import { DeviceInfo } from 'Models/Device/DeviceInfo';
import { FirmwareInfo } from 'Models/Device/FirmwareInfo';
import { FirmwareUpgradeDetails } from 'Models/Device/FirmwareUpgradeDetails';

type DeviceReducerState = {
  enrollment: EnrolledDevice | null;
  applications: ApplicationDeploymentDetails[];
  deviceInfo: DeviceInfo | null;
  firmwareInfo: FirmwareInfo | null;
  firmwareUpgradeDetails: FirmwareUpgradeDetails | null;
};

type DeviceReducerActions =
  | ReceiveDeviceAction
  | ClearDeviceAction
  | ReceiveDeviceInfoAction
  | ReceiveFirmwareInfoAction
  | ReceiveDeviceFirmwareUpgradeDetailsAction;

const defaultState: DeviceReducerState = {
  enrollment: null,
  applications: [],
  deviceInfo: null,
  firmwareInfo: null,
  firmwareUpgradeDetails: null,
};

export function deviceReducer(
  state: DeviceReducerState = defaultState,
  action: DeviceReducerActions
): DeviceReducerState {
  switch (action.type) {
    case RECEIVE_DEVICE:
      return { ...state, enrollment: action.device, applications: action.applications };
    case RECEIVE_DEVICE_INFO:
      return { ...state, deviceInfo: action.deviceInfo };
    case RECEIVE_FIRMWARE_INFO:
      return { ...state, firmwareInfo: action.firmwareInfo };
    case RECEIVE_FIRMWARE_UPGRADE_DETAILS:
      return { ...state, firmwareUpgradeDetails: action.firmwareUpgradeDetails };
    case CLEAR_DEVICE:
      return defaultState;
    default:
      return state;
  }
}
