import {
  _AddToastNotificationAction,
  _addToastNotificationAction,
  SendToastNotificationAction,
  dismissToastNotificationAction,
} from 'Actions/Notification/NotificationActions';
import { takeEvery, put } from 'redux-saga/effects';
import { SEND_TOAST_NOTIFICATION } from 'Actions/ActionTypes';
import { v4 as uuidv4 } from 'uuid';

function* toastNotificationSaga(action: SendToastNotificationAction) {
  const id = uuidv4();

  yield put(_addToastNotificationAction(action.notificationType, action.message, id));

  yield new Promise(resolve => setTimeout(() => resolve(), 6000));

  yield put(dismissToastNotificationAction(id));
}

export function* watchToastNotification() {
  yield takeEvery(SEND_TOAST_NOTIFICATION, toastNotificationSaga);
}
