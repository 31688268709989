import React from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const state = React.useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state[0]));
  }, [key, state]);

  return state;
};
