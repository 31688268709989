import { TRACK_FETCH, CLEAR_FETCH } from 'Actions/ActionTypes';

export type TrackFetchAction = {
  type: typeof TRACK_FETCH;
  name: string;
};

export function trackFetchAction(name: string): TrackFetchAction {
  return {
    type: TRACK_FETCH,
    name,
  };
}

export type ClearFetchAction = {
  type: typeof CLEAR_FETCH;
  name: string;
};

export function clearFetchAction(name: string): ClearFetchAction {
  return {
    type: CLEAR_FETCH,
    name,
  };
}
