import { FETCH_FIRMWARE_UPGRADE_DETAILS } from 'Actions/ActionTypes';
import {
  FetchDeviceFirmwareUpgradeDetailsAction,
  receiveDeviceFirmwareUpgradeDetailsAction,
} from 'Actions/Device/DeviceDetailsActions';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getDeviceFirmwareUpgradeDetailsRequest } from 'Services/Device/DeviceIdentityProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { FirmwareUpgradeDetails } from 'Models/Device/FirmwareUpgradeDetails';

export function* fetchFirmwareUpgradeDetails(action: FetchDeviceFirmwareUpgradeDetailsAction) {
  const token: string = yield select((state: RootState) => state.authentication.token);

  try {
    const response: FirmwareUpgradeDetails = yield call(
      getDeviceFirmwareUpgradeDetailsRequest,
      token,
      action.systemId,
      action.serialNumber
    );

    yield put(receiveDeviceFirmwareUpgradeDetailsAction(response));
  } catch (e) {
    // We do not log when it's a firmware_upgrade_information_not_found error, because it happens most of the time (when DeviceInfo has no matching entry)
    if (e instanceof ServiceCallerError) {
      if (e.code != 'firmware_upgrade_information_not_found') {
        yield put(sendToastNotificationAction('warning', e.message));
      }
    } else {
      console.log(e);
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occurred while retrieving firmware upgrade details.'
        )
      );
    }
  }
}

export function* watchFetchFirmwareUpgradeDetails() {
  yield takeLatest(FETCH_FIRMWARE_UPGRADE_DETAILS, fetchFirmwareUpgradeDetails);
}
