import React, { useMemo } from 'react';
import { LightPrincipal } from 'Components/Users/Types';
import classNames from 'classnames';

import styles from './Roles.module.css';

type Props = {
  userIds: string[];
  users: LightPrincipal[];
};

export function RoleMembers(props: Props) {
  const usersList = useMemo(() => {
    return props.users.filter(u => props.userIds.includes(u.id));
  }, [props.userIds, props.users]);

  return (
    <table className="client-portal-table table table-body fixed-table">
      <thead>
        <tr>
          <th>Username</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {usersList.map(user => (
          <tr data-testid={`role-member-${user.id}`} key={user.id}>
            <td className="td-sm">
              <div className={classNames('text-ellipsis', styles.memberTableCell)}>{user.name}</div>
            </td>
            <td className="td-sm">{user.type}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
