import { useMemo, useState } from 'react';

type SortingDirection = 'ascending' | 'descending';

interface CollectionSorter<T> {
  elements: T[];
  toggleComparator: (field: string) => void;
  comparedField: string;
  direction: SortingDirection;
}

/// A custom hook to sort generic object collections.
/// Compared fields can either be strings or numbers.
/// Use with <SortableTh /> for the best experience.
export function useSorter<T>(
  collection: T[],
  defaultComparedField: string = '',
  defaultDirection: SortingDirection = 'ascending'
): CollectionSorter<T> {
  const [comparedField, setComparedField] = useState(defaultComparedField);
  const [direction, setDirection] = useState(defaultDirection);
  const elements = useMemo(
    () =>
      [...collection].sort((a, b) => {
        if (direction === 'ascending') {
          return compare(a[comparedField], b[comparedField]);
        } else {
          return -compare(a[comparedField], b[comparedField]);
        }
      }),
    [collection, comparedField, direction]
  );

  function toggleComparator(field: string) {
    if (field === comparedField) {
      flipDirection();
    } else {
      setComparedField(field);
      setDirection('ascending');
    }
  }

  function flipDirection() {
    if (direction === 'ascending') {
      setDirection('descending');
    } else {
      setDirection('ascending');
    }
  }

  return {
    elements,
    comparedField,
    direction,
    toggleComparator,
  };
}

function compare(a: string | number, b: string | number) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  } else {
    if (a > b) return 1;
    if (!a) return 1;
    if (!b) return -1;
    return a === b ? 0 : -1;
  }
}
