import { UserManager, UserManagerSettings, User, Log } from 'oidc-client';
import Constants from '../Constants';
import config from 'react-global-configuration';

import { setAuthenticatedUserAction } from '../Actions/User/UserActions';
import { setTokenAction } from '../Actions/AuthenticationActions';
import { store } from 'store';

export interface AuthenticatedUser {
  isAuthenticated: boolean;
  id: string;
  name: string;
  bearerToken: string;
}

class AuthenticationService {
  private _userManager: UserManager | undefined;

  initialize() {
    Log.logger = console;
    Log.level = Log.INFO;

    const stsSettings = config.get(Constants.stsSettings);
    const clientRoot = config.get(Constants.webAppUrl);

    const settings: UserManagerSettings = {
      authority: stsSettings.authority,
      client_id: stsSettings.clientId,
      client_secret: stsSettings.clientSecret,
      response_type: 'code',
      scope: `openid profile email source_iss groups ${stsSettings.scope}`,
      redirect_uri: `${clientRoot}signin-callback`,

      automaticSilentRenew: true,
      accessTokenExpiringNotificationTime: 300 /* number of seconds to trigger the silent renew before the expiration of the token of 1 hour */,
      silent_redirect_uri: `${clientRoot}silent-renew`,

      revokeAccessTokenOnSignout: true,
      post_logout_redirect_uri: `${clientRoot}signout-callback`,

      filterProtocolClaims: true,
      loadUserInfo: true,
    };

    this._userManager = new UserManager(settings);

    this._userManager.events.addUserLoaded(async (user: User) => {
      await this._mapUser(user).then(authUser => {
        store.dispatch(setAuthenticatedUserAction(authUser));
        store.dispatch(setTokenAction(authUser.bearerToken));
      });
    });

    this._userManager.events.addUserSignedOut(() => {
      let disconnectedUser = {
        isAuthenticated: false,
        id: '',
        name: '',
        bearerToken: '',
      };
      store.dispatch(setAuthenticatedUserAction(disconnectedUser));
      store.dispatch(setTokenAction(''));
    });

    this._userManager.events.addSilentRenewError(error => {
      console.log(error);
      const disconnectedUser = {
        isAuthenticated: false,
        id: '',
        name: '',
        bearerToken: '',
      };
      store.dispatch(setAuthenticatedUserAction(disconnectedUser));
      store.dispatch(setTokenAction(''));
    });
  }

  private _ensureIsInitialized() {
    if (!this._userManager) {
      throw new Error('AuthenticationService is not initialized');
    }
  }

  private async _mapUser(user: User): Promise<AuthenticatedUser> {
    if (!user || !user.id_token || !user.access_token || user.expired) {
      return {
        isAuthenticated: false,
        id: '',
        name: '',
        bearerToken: '',
      };
    } else {
      return {
        isAuthenticated: !user.expired,
        id: user.profile ? user.profile.sub : '',
        name: user.profile.name ? user.profile.name : '',
        bearerToken: user.access_token,
      };
    }
  }

  async getAuthenticatedUser(): Promise<AuthenticatedUser> {
    this._ensureIsInitialized();
    return this._userManager!.getUser().then(user => this._mapUser(user!));
  }

  async redirectToLogin(return_url: string): Promise<void> {
    this._ensureIsInitialized();
    return this._userManager!.signinRedirect({
      state: { return_url },
    });
  }

  async loginCallback(): Promise<string> {
    this._ensureIsInitialized();
    return this._userManager!.signinRedirectCallback().then(user =>
      user.state ? user.state.return_url : '/'
    );
  }

  async redirectToLogout() {
    this._ensureIsInitialized();
    this._userManager!.signoutRedirect();
  }

  async logoutCallback() {
    this._ensureIsInitialized();
    this._userManager!.signoutRedirectCallback();
  }

  async renewCallback() {
    this._ensureIsInitialized();
    this._userManager!.signinSilentCallback();
  }
}

export const authenticationService: AuthenticationService = new AuthenticationService();
