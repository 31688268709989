import React from 'react';
import styles from './DeviceSummary.module.css';
import { FirmwareInfo } from 'Models/Device/FirmwareInfo';

type Props = { firmwareInfo: FirmwareInfo };

export function DeviceSummaryFirmwareUpToDate({ firmwareInfo }: Props) {
  const [version, build] = getVersion(firmwareInfo && firmwareInfo.currentFirmware.firmwareVersion);

  return (
    <div data-testid="firmware-up-to-date">
      {firmwareInfo && (
        <div className={styles.flexHorizontal}>
          <i className={`ico-sc-check ${styles.checkIcon}`} />
          <div className={styles.flexVertical}>
            <span className={styles.greenText}>You have the latest firmware version</span>
            <div>
              <label className={styles.greyText}>Current version: </label>
              <span title={getVersionTooltip(version, build)}>{version}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function getVersion(firmwareVersion: string | null): string[] {
  return (firmwareVersion || '').split('+');
}

function getVersionTooltip(version: string, build: string): string {
  return [version && `Version: ${version}`, build && `Build: ${build}`].filter(Boolean).join(', ');
}
