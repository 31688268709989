import React from 'react';
import {
  Box,
  Checkbox,
  Drawer,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  PermanentFlagNames,
  PermanentFlagDefaultValues,
  TemporaryFlagDefaultValues,
  TemporaryFlagNames,
} from 'Models/FeatureManagement/FeatureFlags';
import { useLocalStorage } from 'Hooks/useLocalStorage';
import { SearchBox } from './Controls/SearchBox';

interface IFlagOverride {
  flagName: string;
  overriden: boolean;
  overridenValue: boolean;
}

const allKnownFlagNames = { ...PermanentFlagNames, ...TemporaryFlagNames };
const allFlagDefaultValues = { ...PermanentFlagDefaultValues, ...TemporaryFlagDefaultValues };
const theme = {
  backgroundColor: '#232323',
  fontSize: '1.2rem',
};

const styles = {
  whiteTableElements: {
    ...theme,
    color: 'white',
  },
  greenTableElements: {
    ...theme,
    color: 'green',
  },
  redTableElements: {
    ...theme,
    color: '#b01c02',
  },
  box: { height: '100%', backgroundColor: '#232323' },
  grid: { flexGrow: 0, flexShrink: 1, minHeight: '18rem' },
};

export const FlagStateContext = React.createContext<Record<string, boolean>>({});

export const FlagOverrideProvider: React.FC = props => {
  const flags = useFlags();
  const DebuggingTools = React.useMemo(() => flags[PermanentFlagNames.DebuggingTools] ?? false, [
    flags,
  ]);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [query, setQuery] = React.useState<string>('');
  const [flagOverrides, setFlagOverrides] = useLocalStorage<IFlagOverride[]>('flagOverrides', []);

  React.useEffect(() => {
    if (!DebuggingTools) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'F4') {
        setDrawerOpen(isVisible => !isVisible);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [DebuggingTools]);

  const allFlagStates = React.useMemo(() => {
    const newFlagStates: {
      key: string;
      flagName: string;
      displayName: string;
      overriden: boolean;
      defaultValue: boolean;
      ldValue: boolean;
      overridenValue: boolean | undefined;
      normalizedValue: boolean;
    }[] = [];

    for (const key in allKnownFlagNames) {
      const flagName = allKnownFlagNames[key];
      const displayName = key.split(/(?=[A-Z])/).join(' ');
      const defaultValue = allFlagDefaultValues[key];
      const ldValue = flags[flagName];
      const override = flagOverrides.find(flag => flag.flagName === flagName);
      const overriden = override?.overriden ?? false;
      const overridenValue = overriden ? override?.overridenValue : undefined;
      const normalizedValue = DebuggingTools
        ? overridenValue ?? ldValue ?? defaultValue
        : ldValue ?? defaultValue;

      newFlagStates.push({
        key,
        flagName,
        displayName,
        overriden,
        defaultValue,
        ldValue,
        overridenValue,
        normalizedValue,
      });
    }

    return newFlagStates;
  }, [DebuggingTools, flagOverrides, flags]);

  const flattenFlagStates = React.useMemo(
    () => allFlagStates.reduce((prev, next) => ({ ...prev, [next.key]: next.normalizedValue }), {}),
    [allFlagStates]
  );

  const handleOverridenChanged = (flagName: string, checked: boolean) => {
    setFlagOverrides(flagOverrides => {
      let override = flagOverrides.find(flag => flag.flagName === flagName);

      if (override) {
        override.overriden = checked;
      } else {
        override = {
          flagName,
          overriden: checked,
          overridenValue: false,
        };

        flagOverrides.push(override);
      }

      return [...flagOverrides];
    });
  };

  const handleOverridenValueChanged = (flagName: string, checked: boolean) => {
    setFlagOverrides(flagOverrides => {
      let override = flagOverrides.find(flag => flag.flagName === flagName);

      if (override) {
        override.overriden = true;
        override.overridenValue = checked;
      } else {
        override = {
          flagName,
          overriden: true,
          overridenValue: checked,
        };

        flagOverrides.push(override);
      }

      return [...flagOverrides];
    });
  };

  const filteredFlags = allFlagStates
    .sort((flag, anotherFlag) => flag.displayName.localeCompare(anotherFlag.displayName))
    .filter(flag => !query || flag.displayName.toLowerCase().includes(query.toLowerCase()));

  return (
    <FlagStateContext.Provider value={flattenFlagStates}>
      {DebuggingTools ? (
        <Drawer anchor="right" onClose={() => setDrawerOpen(false)} open={drawerOpen}>
          <Box alignItems="stretch" display="flex" flexDirection="column" style={styles.box}>
            <SearchBox value={query} onChange={value => setQuery(value)} autoFocus={true} />
            <Grid item style={styles.grid}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.whiteTableElements}>Flag Name</TableCell>
                      <TableCell style={styles.whiteTableElements}>LD Value</TableCell>
                      <TableCell style={styles.whiteTableElements}>Override?</TableCell>
                      <TableCell style={styles.whiteTableElements}>On/Off</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredFlags.map(row => (
                      <TableRow key={row.flagName}>
                        <TableCell component="th" scope="row">
                          <Typography style={styles.whiteTableElements} variant="body2">
                            {row.displayName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={
                              row.ldValue ? styles.greenTableElements : styles.redTableElements
                            }
                            variant="body2"
                          >
                            {row.ldValue ? 'ON' : 'OFF'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={row.overriden}
                            onChange={(_, checked) => handleOverridenChanged(row.flagName, checked)}
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={!!row.overridenValue}
                            color="primary"
                            onChange={(_, checked) =>
                              handleOverridenValueChanged(row.flagName, checked)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Box>
        </Drawer>
      ) : null}
      {props.children}
    </FlagStateContext.Provider>
  );
};
