import config from 'react-global-configuration';
import urljoin from 'url-join';
import Constants from 'Constants';
import { httpGet } from 'Services/ServiceCaller';

export type GetFirmwareImagesResponse = {
  response: {
    firmwareName: string;
    firmwareVersion: string;
    manifests: FirmwareManifests[];
  }[];
};

export type FirmwareManifests = {
  firmwareVersion: string;
  manifestVersion: string;
  cpuArch: string;
  deviceClass: string;
  flavor: string;
  toolingLevel: string;
  driver: string;
  buildId: string;
  availabilityLevel: string;
  imageName: string;
  fileSize: number;
};

export async function getFirmwareImagesRequest(
  token: string,
  firmwareName: string,
  systemId: string
): Promise<GetFirmwareImagesResponse> {
  const url = urljoin(
    config.get(Constants.firmwareRepoApi),
    `/v2/systems/${encodeURI(systemId)}/firmware/${encodeURI(
      firmwareName
    )}?includeDeletedEntries=false`
  );
  return await httpGet('GetFirmwareImages', token, url);
}
