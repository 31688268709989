import { useEffect, useState } from 'react';
import * as React from 'react';
import { SystemSelectorModal } from './SystemSelectorModal';
import '../ClientPortal.css';
import { RootState } from 'Reducers/RootReducer';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { triggerNavigationAction } from 'Actions/Navigation/NavigationActions';

type SystemMenuItemProps = {
  systemId: string;
  systems: { id: string; name: string }[];
  changeSystem: (newId: string) => void;
};

export function _SystemMenuItem(props: SystemMenuItemProps) {
  const [systemNaume, setSystemName] = useState(props.systemId);
  const [showSelectorModal, setShowSelectorModal] = useState(false);

  useEffect(() => {
    const system = props.systems!.find(s => s.id === props.systemId);

    if (!system) {
      return;
    }

    setSystemName(system.name);
  }, [props.systemId, props.systems]);

  function onChangeSystem(newId: string) {
    props.changeSystem(newId);
    setShowSelectorModal(false);
  }

  if (props.systemId) {
    return (
      <React.Fragment>
        <SystemSelectorModal
          show={showSelectorModal}
          onCancel={() => setShowSelectorModal(false)}
          systems={props.systems}
          systemId={props.systemId}
          onSelectSystem={onChangeSystem}
        />
        <li className="dropdown">
          <a
            onClick={() => setShowSelectorModal(true)}
            className={props.systems.length > 1 ? '' : 'disabled'}
          >
            <span>{systemNaume}</span>
          </a>
        </li>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

function mapStateToProps(state: RootState) {
  return {
    systems: state.user.systems,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    changeSystem: (systemId: string) => {
      dispatch(triggerNavigationAction(`/systems/${systemId}`));
    },
  };
}

export const SystemMenuItem = connect(mapStateToProps, mapDispatchToProps)(_SystemMenuItem);
