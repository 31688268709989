import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ActionButtonWithConfirmationModal } from '../Util/Controls/ActionButtonWithConfirmationModal';
import { CreateDeviceJob, JobType } from '../../Models/DeviceJobs/DeviceJobs';
import { createDeviceJobAction } from '../../Actions/DeviceJobs/DeviceJobsActions';
import styles from './Buttons.module.css';

type RebootButtonProps = {
  serialNumber: string;
  systemId: string;
  disabled: boolean;
  createDeviceJob: (systemId: string, serialNumber: string) => void;
};

export function _RebootDeviceButton(props: RebootButtonProps) {
  function onConfirm() {
    props.createDeviceJob(props.systemId, props.serialNumber);
  }

  return (
    <ActionButtonWithConfirmationModal
      actionButtonClassName={`${props.disabled ? styles.buttonDisabled : styles.buttonDefault}`}
      modalButtonClassName="btn btn-default"
      onConfirm={onConfirm}
      title={`Restart device: ${props.serialNumber}`}
      text="Restart device"
      disabled={props.disabled}
      testId="reboot-device-button"
    >
      <div>
        <p>
          The device will restart, the connection to the device <b>will be lost</b> during reboot.
        </p>
        <p> Are you sure you want to continue? </p>
      </div>
    </ActionButtonWithConfirmationModal>
  );
}

function mapDispatchToProps(dispatch: Dispatch) {
  const rebootJob: CreateDeviceJob = {
    type: JobType.Reboot,
    body: {},
  };
  return {
    createDeviceJob: (systemId: string, serialNumber: string) =>
      dispatch(createDeviceJobAction(systemId, serialNumber, rebootJob)),
  };
}

export const RebootDeviceButton = connect(null, mapDispatchToProps)(_RebootDeviceButton);
