import React from 'react';
import { SystemType } from 'Models/System/System';

type props = {
  infoType: SystemType | null;
  toolingLevel: string | null;
};

export function ToolingLevel({ infoType, toolingLevel }: props) {
  if (!infoType || !toolingLevel) {
    return <div />;
  }
  if (infoType === 'development') {
    return (
      <div className="col-lg-2 noWrap">
        <label>Tooling Level</label>
        <div>{toolingLevel}</div>
      </div>
    );
  }
  return <div />;
}
