import { FETCH_FIRMWARE_UPGRADE_HISTORY } from 'Actions/ActionTypes';
import {
  FetchFirmwareUpgradeHistoryAction,
  receiveFirmwareUpgradeHistoryAction,
} from 'Actions/Device/FirmwareUpgradeHistoryActions';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select } from 'redux-saga/effects';
import { getFirmwareUpgradeHistoryRequest } from 'Services/Device/DeviceIdentityProxy';
import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import { FirmwareUpgradeHistory } from 'Models/Device/FirmwareUpgradeHistory';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';

export function* fetchFirmwareUpgradeHistorySaga(action: FetchFirmwareUpgradeHistoryAction) {
  yield put(trackFetchAction('fetchFirmwareUpgradeHistory'));
  const token: string = yield select((state: RootState) => state.authentication.token);
  try {
    const firmwareUpgradeHistoryResponse: FirmwareUpgradeHistory[] = yield call(
      getFirmwareUpgradeHistoryRequest,
      token,
      action.systemId,
      action.serialNumber
    );

    yield put(
      receiveFirmwareUpgradeHistoryAction(
        assembleFirmwareUpgradeHistory(firmwareUpgradeHistoryResponse)
      )
    );
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occured while retrieving firmware upgrade history.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('fetchFirmwareUpgradeHistory'));
  }
}

export function* watchFirmwareUpgradeHistory() {
  yield cancelOnNavigationEffect(FETCH_FIRMWARE_UPGRADE_HISTORY, fetchFirmwareUpgradeHistorySaga);
}

const assembleFirmwareUpgradeHistory = (
  firmwareUpgradeHistoryResponse: FirmwareUpgradeHistory[]
): FirmwareUpgradeHistory[] =>
  firmwareUpgradeHistoryResponse
    .sort(
      (current, other) =>
        new Date(other.upgradeStartTime).getTime() - new Date(current.upgradeStartTime).getTime()
    )
    .map(fwUpgradeHistory => ({
      deviceSerialNumber: fwUpgradeHistory.deviceSerialNumber,
      newFirmware: fwUpgradeHistory.newFirmware,
      oldFirmware: fwUpgradeHistory.oldFirmware,
      upgradeStartTime: new Date(fwUpgradeHistory.upgradeStartTime),
      upgradeEndTime: new Date(fwUpgradeHistory.upgradeEndTime),
      result: fwUpgradeHistory.result,
      message: fwUpgradeHistory.message,
    }));
