import * as React from 'react';
import { InlineSelect } from './InlineSelect';
import { Fragment } from 'react';

type EditableLabelSelectProps = {
  isEditable: boolean;
  elems: { id: string; name: string }[];
  selectedElem?: string;
  onSelect?: (elem: string) => void;
  error?: string;
  testId?: string;
  shouldFocus?: boolean;
};

export function EditableLabelSelect(props: EditableLabelSelectProps) {
  function idToName(id?: string) {
    const selected = props.elems.find(x => x.id === id);
    if (selected) {
      return selected.name;
    }

    return '';
  }

  if (props.isEditable) {
    return (
      <InlineSelect
        elems={props.elems}
        selectedElem={props.selectedElem}
        onSelect={props.onSelect}
        error={props.error}
        testId={props.testId}
        shouldFocus={props.shouldFocus}
      />
    );
  } else {
    if (props.elems) {
      const selectedItemName = idToName(props.selectedElem);

      if (props.error) {
        return (
          <Fragment>
            {selectedItemName !== '' ? (
              <div className="underlined text-danger truncate-ellipsis">{selectedItemName}</div>
            ) : (
              <div className="underlined text-placeholder truncate-ellipsis">Click to select</div>
            )}
            <div className="text-danger help-text thin-text">{props.error}</div>
          </Fragment>
        );
      }

      return <span data-testid={props.testId}>{selectedItemName}</span>;
    }
    return <span data-testid={props.testId}>{''}</span>;
  }
}
