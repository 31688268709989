import { put, select, call } from 'redux-saga/effects';
import { RootState } from 'Reducers/RootReducer';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';

export type FnReturnType<TFn extends (...args: any) => any> = ReturnType<TFn> extends Promise<
  infer TPromise
>
  ? TPromise
  : ReturnType<TFn>;

interface GenericSagaConfig<TFn extends (...args: any) => any> {
  fn: TFn;
  argsBuilder: (token: string) => Parameters<TFn>;
  successMessage?: string;
  errorMessage?: string;
  loadingSectionName?: string;
}

export function* genericSaga<T extends (...args: any) => any>(
  config: GenericSagaConfig<T>
): Generator<any, FnReturnType<T>, any> {
  const { loadingSectionName, fn, argsBuilder, successMessage, errorMessage } = config;

  if (loadingSectionName) yield put(trackFetchAction(loadingSectionName));

  try {
    const token = yield select((state: RootState) => state.authentication.token);
    const fnArgs: any[] = argsBuilder(token);

    const result: FnReturnType<T> = yield call(fn as any, ...fnArgs);

    if (successMessage) {
      yield put(sendToastNotificationAction('success', successMessage));
    }

    return result;
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      if (errorMessage) {
        yield put(sendToastNotificationAction('warning', errorMessage));
      }
    }

    throw e;
  } finally {
    if (loadingSectionName) yield put(clearFetchAction(loadingSectionName));
  }
}
