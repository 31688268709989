import React from 'react';
import Tippy from '@tippyjs/react/headless';

type Props = {
  children: React.ReactElement<any>;
  disabled?: boolean;
  trigger?: PopoverTrigger;
  visible?: boolean;
  placement?: PopoverPlacement;
  header?: string;
  content: React.ReactNode;
  width?: string;
  margin?: string;
  delay?: number | [number | null, number | null];
  interactive?: boolean;
};

export type PopoverPlacement = 'left' | 'right' | 'top' | 'bottom';
export type PopoverTrigger =
  | 'mouseenter focus'
  | 'click'
  | 'focusin'
  | 'mouseenter click'
  | 'manual';

export default function Popover(props: Props) {
  return (
    <Tippy
      disabled={props.disabled ? props.disabled : false}
      trigger={props.trigger ? props.trigger : 'mouseenter focus'}
      visible={props.visible}
      placement={props.placement ? props.placement : 'right'}
      interactive={props.interactive ? props.interactive : false}
      interactiveBorder={6}
      delay={props.delay ? props.delay : [500, 250]}
      arrow={true}
      render={attrs => {
        return (
          <div
            className={`popover fade in ${attrs['data-placement']}`}
            style={{
              position: 'relative',
              display: 'block',
              float: 'left',
              width: props.width ? props.width : '260px',
              margin: props.margin ? props.margin : '2px',
            }}
          >
            <div className="arrow" data-popper-arrow />
            {props.header && <h3 className="popover-title">{props.header}</h3>}
            <div className="popover-content">{props.content}</div>
          </div>
        );
      }}
    >
      {props.children}
    </Tippy>
  );
}
