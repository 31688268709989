import { FETCH_DEVICE_DETAILS } from 'Actions/ActionTypes';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { RootState } from 'Reducers/RootReducer';
import { all, call, put, select } from 'redux-saga/effects';
import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import {
  FetchDeviceDetailsAction,
  receiveDeviceInfoAction,
} from 'Actions/Device/DeviceDetailsActions';
import {
  getDeviceConfigurationRequest,
  getDeviceInventoryRequest,
} from 'Services/Device/DeviceIdentityProxy';
import { DeviceConfiguration, DeviceInfoFrom, DeviceInventory } from 'Models/Device/DeviceInfo';

type DeviceDetails = {
  inventory: DeviceInventory;
  config: DeviceConfiguration;
};

export function* fetchDeviceDetailsSaga(action: FetchDeviceDetailsAction) {
  yield put(trackFetchAction('fetchDeviceDetails'));

  try {
    const token: string = yield select((state: RootState) => state.authentication.token);

    const deviceDetails: DeviceDetails = yield all({
      inventory: call(getDeviceInventoryRequest, token, action.systemId, action.serialNumber),
      config: call(getDeviceConfigurationRequest, token, action.systemId, action.serialNumber),
    });

    yield put(
      receiveDeviceInfoAction(
        DeviceInfoFrom(action.serialNumber, deviceDetails.inventory, deviceDetails.config)
      )
    );
  } catch (e) {
    //No toast notification in case of errors, because not all devices have reports (deviceInfo)
    console.log(e);
  } finally {
    yield put(clearFetchAction('fetchDeviceDetails'));
  }
}

export function* watchFetchDeviceDetails() {
  yield cancelOnNavigationEffect(FETCH_DEVICE_DETAILS, fetchDeviceDetailsSaga);
}
