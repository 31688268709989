import { FETCH_SYSTEM_FEATURE_FLAGS, SET_SYSTEM_FEATURE_FLAGS } from '../ActionTypes';

export type FetchSystemFeatureFlagsAction = {
  type: typeof FETCH_SYSTEM_FEATURE_FLAGS;
  systemId: string;
};

export function fetchSystemFeatureFlagsAction(systemId: string): FetchSystemFeatureFlagsAction {
  return {
    type: FETCH_SYSTEM_FEATURE_FLAGS,
    systemId,
  };
}

export type SetSystemFeatureFlagsAction = {
  type: typeof SET_SYSTEM_FEATURE_FLAGS;
  featureFlags: Map<string, boolean>;
};

export function setSystemFeatureFlagsAction(
  featureFlags: Map<string, boolean>
): SetSystemFeatureFlagsAction {
  return {
    type: SET_SYSTEM_FEATURE_FLAGS,
    featureFlags,
  };
}
