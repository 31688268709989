import * as React from 'react';
import { useMemo, useState, useRef } from 'react';
import { UserInvitation } from 'Models/Users/UserInvitation';
import TableButton from 'Components/Util/Controls/TableButton';
import { useSorter } from 'Hooks/useSorter';
import { SortableTh } from 'Components/Util/Table/SortableTh';
import styles from './UserInvitationList.module.css';
import { LightPrincipal } from '../Types';
import SearchAction from 'Components/Util/SearchAction';
import useOnClickOutside from 'use-onclickoutside';
import { RoleTd } from './RoleTd';
import { ConnectedNavLink } from 'Components/Util/Browser/ConnectedNavLink';
import ReactTooltip from 'react-tooltip';

type UserInvitationsListProps = {
  invitations: UserInvitation[];
  users: LightPrincipal[];
  canInviteUsers: boolean;
  canRemoveUsers: boolean;
  canAssignRoles: boolean;
  canViewDetails: boolean;
  systemId: string;
  roles: { id: string; name: string }[];
  onInviteUserButtonClick: () => void;
  onCopyToClipboardButtonClick: (text: string) => void;
  onRefreshButtonClick: () => void;
  onDeleteUserInvitation: (username: string) => void;
  onDeleteUser: (username: string, userId: string) => void;
  onChangeUserRole: (username: string, userId: string, newRole: string) => void;
};

export default function UserInvitationsList(props: UserInvitationsListProps) {
  const [textFilter, setTextFilter] = useState('');
  const [selectedUser, setSelectedUser] = useState<string | undefined>();

  const filteredList = useMemo(() => {
    let filteredInvitations = filterInvitations(props.invitations, textFilter);
    let filteredUsers = filterUsers(props.users, textFilter);
    return assembleVisibleCollection(filteredUsers, filteredInvitations);
  }, [textFilter, props.users, props.invitations]);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setSelectedUser(undefined));

  const sorter = useSorter(filteredList, 'email');

  return (
    <React.Fragment>
      <div className="client-portal-table-header">
        <h2>Users</h2>
        <div className={styles.invitationTableActions}>
          <SearchAction
            dataTip="Search for a user"
            value={textFilter}
            onChange={value => setTextFilter(value)}
          />
          <a
            data-tip="Refresh"
            className="pull-right"
            onClick={props.onRefreshButtonClick}
            data-testid="refresh-invitation-list"
          >
            <i className="ico-sc-refresh" />
          </a>
          {props.canInviteUsers && (
            <a
              data-tip="Invite a new user"
              className="pull-right"
              onClick={props.onInviteUserButtonClick}
              data-testid="add-invitation"
            >
              <i className="ico-sc-add" />
            </a>
          )}
          <ReactTooltip />
        </div>
      </div>
      <table className="client-portal-table table table-header fixed-table">
        <thead>
          <tr>
            <SortableTh field="email" displayName="Email" sorter={sorter} className="td-sm" />
            <SortableTh field="role" displayName="Role" sorter={sorter} className="td-sm" />
            <SortableTh field="status" displayName="Status" sorter={sorter} className="td-sm" />
            <th className="td-xs">{/* Action Buttons */}</th>
          </tr>
        </thead>
      </table>
      <div className="flex-content">
        <table className="client-portal-table table table-body fixed-table">
          <tbody ref={ref}>
            {sorter.elements.map((user, i) => {
              return (
                <tr
                  key={user.email}
                  className="hover-visibility-trigger"
                  onClick={() => setSelectedUser(user.email)}
                >
                  <td className="td-sm">
                    <div className={styles.invitationTableCellOverflow}>
                      {props.canViewDetails && (
                        <ConnectedNavLink
                          to={`/systems/${props.systemId}/Users/${user.id}`}
                          data-testid={`user-details-${user.id}`}
                        >
                          {user.email}
                        </ConnectedNavLink>
                      )}
                      {!props.canViewDetails && user.email}
                    </div>
                  </td>
                  <RoleTd
                    testId="role-td"
                    className="td-sm"
                    canAssignRoles={props.canAssignRoles}
                    user={{
                      role: user.role,
                      type: user.status == 'Completed' ? 'user' : 'invitation',
                    }}
                    roles={props.roles}
                    isSelected={user.email === selectedUser}
                    onChangeRole={role => props.onChangeUserRole(user.email, user.id!, role)}
                  />
                  <td className="td-sm">{user.status}</td>

                  <td className="td-xs">
                    {props.canRemoveUsers && (
                      <TableButton
                        iconClassName="ico-sc-clear text-danger"
                        onClick={() => {
                          if (user.id === undefined) {
                            props.onDeleteUserInvitation(user.email);
                          } else {
                            props.onDeleteUser(user.email, user.id);
                          }
                        }}
                        testId={`deleteInvite${i}`}
                      />
                    )}
                    {user.registrationUrl !== undefined && (
                      <TableButton
                        iconClassName="ico-sc-modal"
                        onClick={() =>
                          props.onCopyToClipboardButtonClick(user.registrationUrl || '')
                        }
                        testId={`copyToClipboard${i}`}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

//Keep only the non completed invitations
//And if the search action is used, keep only the searched invitations
function filterInvitations(invitations: UserInvitation[], textFilter: string): UserInvitation[] {
  if (textFilter.trim() === '') {
    return invitations.filter((i: UserInvitation) => i.isCompleted === false);
  } else {
    return invitations.filter(
      (i: UserInvitation) =>
        i.username.toLowerCase().includes(textFilter.toLowerCase()) && i.isCompleted === false
    );
  }
}

function filterUsers(users: LightPrincipal[], textFilter: string): LightPrincipal[] {
  if (textFilter.trim() === '') {
    return users;
  }
  return users.filter((u: LightPrincipal) =>
    u.name.toLowerCase().includes(textFilter.toLowerCase())
  );
}

function assembleVisibleCollection(
  users: LightPrincipal[],
  invitations: UserInvitation[]
): {
  id: string | undefined;
  email: string;
  status: string;
  role: string;
  registrationUrl: string | undefined;
}[] {
  let usersAssembled: {
    id: string | undefined;
    email: string;
    status: string;
    role: string;
    registrationUrl: string | undefined;
  }[] = [];

  usersAssembled = users.map(u => ({
    id: u.id,
    email: u.name,
    status: 'Completed',
    role: u.role,
    registrationUrl: undefined,
  }));

  usersAssembled.push(
    ...invitations.map(i => ({
      id: undefined,
      email: i.username,
      role: i.role,
      status: 'Pending',
      registrationUrl: i.registrationUrl,
    }))
  );

  return usersAssembled;
}
