import {
  FETCH_DEVICE_DETAILS,
  RECEIVE_DEVICE_INFO,
  RECEIVE_FIRMWARE_INFO,
  FETCH_FIRMWARE_UPGRADE_DETAILS,
  RECEIVE_FIRMWARE_UPGRADE_DETAILS,
  FETCH_FIRMWARE_INFO,
} from 'Actions/ActionTypes';
import { DeviceInfo } from 'Models/Device/DeviceInfo';
import { FirmwareInfo } from 'Models/Device/FirmwareInfo';
import { FirmwareUpgradeDetails } from 'Models/Device/FirmwareUpgradeDetails';

export type FetchDeviceDetailsAction = {
  type: typeof FETCH_DEVICE_DETAILS;
  systemId: string;
  serialNumber: string;
};

export function fetchDeviceDetailsAction(
  systemId: string,
  serialNumber: string
): FetchDeviceDetailsAction {
  return {
    type: FETCH_DEVICE_DETAILS,
    systemId,
    serialNumber,
  };
}

export type ReceiveDeviceInfoAction = {
  type: typeof RECEIVE_DEVICE_INFO;
  deviceInfo: DeviceInfo;
};

export function receiveDeviceInfoAction(deviceInfo: DeviceInfo): ReceiveDeviceInfoAction {
  return {
    type: RECEIVE_DEVICE_INFO,
    deviceInfo,
  };
}

export type FetchFirmwareInfoAction = {
  type: typeof FETCH_FIRMWARE_INFO;
  systemId: string;
  serialNumber: string;
};

export function fetchFirmwareInfoAction(
  systemId: string,
  serialNumber: string
): FetchFirmwareInfoAction {
  return {
    type: FETCH_FIRMWARE_INFO,
    systemId,
    serialNumber,
  };
}

export type ReceiveFirmwareInfoAction = {
  type: typeof RECEIVE_FIRMWARE_INFO;
  firmwareInfo: FirmwareInfo;
};

export function receiveFirmwareInfoAction(firmwareInfo: FirmwareInfo): ReceiveFirmwareInfoAction {
  return {
    type: RECEIVE_FIRMWARE_INFO,
    firmwareInfo: firmwareInfo,
  };
}

export type ReceiveDeviceFirmwareUpgradeDetailsAction = {
  type: typeof RECEIVE_FIRMWARE_UPGRADE_DETAILS;
  firmwareUpgradeDetails: FirmwareUpgradeDetails;
};

export function receiveDeviceFirmwareUpgradeDetailsAction(
  firmwareUpgradeDetails: FirmwareUpgradeDetails
): ReceiveDeviceFirmwareUpgradeDetailsAction {
  return {
    type: RECEIVE_FIRMWARE_UPGRADE_DETAILS,
    firmwareUpgradeDetails,
  };
}

export type FetchDeviceFirmwareUpgradeDetailsAction = {
  type: typeof FETCH_FIRMWARE_UPGRADE_DETAILS;
  systemId: string;
  serialNumber: string;
};

export function fetchDeviceFirmwareUpgradeDetailsAction(
  systemId: string,
  serialNumber: string
): FetchDeviceFirmwareUpgradeDetailsAction {
  return {
    type: FETCH_FIRMWARE_UPGRADE_DETAILS,
    systemId,
    serialNumber,
  };
}
