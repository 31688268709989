import React from 'react';
import './GoBackButton.css';

type Props = {
  onClick: () => void;
};

export function GoBackButton(props: Props) {
  return (
    <div
      onClick={props.onClick}
      className="go-back-button text-primary"
      data-testid="go-back-button"
    >
      <i className="ico-sc-previous" /> Back
    </div>
  );
}
