import React, { useEffect } from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RolesList } from './RolesList';
import { RoleDetailsPage } from './RoleDetailsPage';
import { PrivateRoute } from 'Components/PrivateRoute';
import { fetchUserListAction } from 'Actions/Users/UserActions';
import { Roles } from 'Models/Authorization';

const system_roles = [
  { id: Roles.User, name: 'User' },
  { id: Roles.Admin, name: 'Admin' },
  { id: Roles.ApplicationManager, name: 'Application Manager' },
];

type RouteParams = {
  systemId: string;
};

type Props = RouteComponentProps<RouteParams> & {
  fetchUsers: (systemId: string) => void;
};

export function _RolesPage(props: Props) {
  useEffect(() => {
    props.fetchUsers(props.match.params.systemId);
  }, [props.match.params.systemId]);

  return (
    <Switch>
      <PrivateRoute
        path="/systems/:systemId/roles/:roleName/details"
        component={RoleDetailsPage}
        exact
      />
      <PrivateRoute
        path="/systems/:systemId/roles"
        render={props => <RolesList systemId={props.match.params.systemId} roles={system_roles} />}
      />
    </Switch>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUsers: (systemId: string) => dispatch(fetchUserListAction(systemId)),
});

export const RolesPage = connect(null, mapDispatchToProps)(_RolesPage);
