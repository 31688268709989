import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { System } from 'Models/System/System';
import { RootState } from 'Reducers/RootReducer';
import { BasicPage } from 'Components/Util/Page';
import { fetchSystemFeatureFlagsAction } from 'Actions/FeatureFlags/FeatureFlagsActions';
import styles from './SystemLandingPage.module.css';

type OwnProps = {
  systemId: string;
};

type Props = {
  system?: System;
};

export function _SystemLandingPage(props: Props) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props.system != null) {
      dispatch(fetchSystemFeatureFlagsAction(props.system.id));
    }
  }, [props.system]);

  if (!props.system) {
    return null;
  }

  return (
    <BasicPage>
      <>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <div className={styles.logo} />
            <div className={styles.text}>
              <h1>Genetec Client Portal</h1>
            </div>
          </div>
        </div>
      </>
    </BasicPage>
  );
}

function mapStateToProps(state: RootState, { systemId }: OwnProps) {
  return {
    system: state.user.systems.find(s => s.id === systemId),
  };
}

export const SystemLandingPage = connect(mapStateToProps)(_SystemLandingPage);
