import Modal from 'Components/Util/Modal';
import React, { useState } from 'react';
import { InstallableApplicationDetails } from 'Models/Application/ApplicationStore';
import { RootState } from 'Reducers/RootReducer';
import {
  applicationInstallationBacktrackWizardAction,
  fetchSelectedArtifactAction,
} from 'Actions/Form/ApplicationInstallationFormActions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationDetailsView } from './Presentation/ApplicationDetailsView';
import { ApplicationDeploymentDetails } from 'Models/Application/ApplicationDeployment';
import { oc } from 'ts-optchain';

type OwnProps = {
  close: () => void;
};

type Props = OwnProps & {
  backtrack: () => void;
  application: InstallableApplicationDetails;
  installed: ApplicationDeploymentDetails | null;
  selectArtifact: (appId: string, appVersion: string) => void;
  systemId: string;
};

export function _ApplicationDetailsFormPage(props: Props) {
  const [version, selectVersion] = useState(
    !props.installed ? props.application.latestRelease || '0.0.0' : props.installed.appVersion
  );

  const canBacktrack = props.installed === null;
  return (
    <Modal
      show={true}
      title="Install Application"
      onCancel={props.close}
      large={true}
      canYes={props.application.artifacts.some(artifact => artifact.version === version)}
      yesLabel="Next"
      onYes={() => props.selectArtifact(props.application.id, version)}
    >
      <ApplicationDetailsView
        application={props.application}
        goBack={canBacktrack ? props.backtrack : undefined}
        installedVersion={oc(props).installed.appVersion()}
        selectedVersion={version}
        selectVersion={selectVersion}
        systemId={props.systemId}
      />
    </Modal>
  );
}

function mapStateToProps(state: RootState) {
  return {
    application: state.application!,
    installed: state.form.applicationInstallation.currentlyInstalled,
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    backtrack: () => dispatch(applicationInstallationBacktrackWizardAction()),
    selectArtifact: (appId: string, appVersion: string) =>
      dispatch(fetchSelectedArtifactAction(appId, appVersion)),
  };
}

export const ApplicationDetailsFormPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ApplicationDetailsFormPage);
