import { makeReducer } from 'Utilities/MakeReducer';
import { nameOf } from 'Utilities/TypeScriptUtils';
import { RECEIVE_ROLE_PRINCIPALS, RECEIVE_ROLE_PERMISSIONS } from 'Actions/ActionTypes';
import { RolePermissionsMap } from 'Models/Authorization';
import {
  ReceiveRolePermissionsAction,
  ReceiveRolePrincipalsAction,
} from 'Actions/Authorization/AuthorizationActions';

type State = {
  permissions: RolePermissionsMap;
  principals: string[];
};

const initialState: State = {
  permissions: {},
  principals: [],
};

export const rolesReducer = makeReducer<State>({
  initialState,
  setters: {
    [RECEIVE_ROLE_PRINCIPALS]: {
      stateField: nameOf<State>('principals'),
      actionValueField: nameOf<ReceiveRolePrincipalsAction>('principals'),
    },
    [RECEIVE_ROLE_PERMISSIONS]: {
      stateField: nameOf<State>('permissions'),
      actionValueField: nameOf<ReceiveRolePermissionsAction>('permissions'),
    },
  },
});
