import { LightPrincipal } from 'Components/Users/Types';
import { UserInvitation } from 'Models/Users/UserInvitation';
import {
  ReceiveUserListFromApiAction,
  ClearUsersAndInvitationsAction,
  ReceiveUserDetailsAction,
  ReceiveRolesForSystemAction,
  FetchUserDetailsAction,
  ClearUserDetailsAction,
} from 'Actions/Users/UserActions';
import { ReceiveUserInvitationListFromApiAction } from 'Actions/Users/UserInvitationActions';
import {
  RECEIVE_USER_LIST_FROM_API,
  RECEIVE_USER_INVITATION_LIST_FROM_API,
  CLEAR_USERS_AND_INVITATIONS,
  RECEIVE_USER_DETAILS,
  CLEAR_USER_DETAILS,
  RECEIVE_ROLE_PRINCIPALS,
} from 'Actions/ActionTypes';
import { UserDetails } from 'Models/Users/UserDetails';

type userReducerState = {
  users: LightPrincipal[];
  invitations: UserInvitation[];
  userDetails: UserDetails;
  roles: { id: string; name: string }[];
};

type userReducerActions =
  | ReceiveUserListFromApiAction
  | ReceiveUserDetailsAction
  | ReceiveUserInvitationListFromApiAction
  | ReceiveRolesForSystemAction
  | FetchUserDetailsAction
  | ClearUsersAndInvitationsAction
  | ClearUserDetailsAction;

const defaultState: userReducerState = {
  users: [],
  invitations: [],
  userDetails: { permissions: [] },
  roles: [],
};

export function usersReducer(state: userReducerState = defaultState, action: userReducerActions) {
  switch (action.type) {
    case RECEIVE_USER_LIST_FROM_API:
      return { ...state, users: action.users };
    case RECEIVE_USER_INVITATION_LIST_FROM_API:
      return { ...state, invitations: action.invitations };
    case RECEIVE_ROLE_PRINCIPALS:
      return { ...state, roles: action.roles };
    case CLEAR_USERS_AND_INVITATIONS:
      return defaultState;
    case RECEIVE_USER_DETAILS:
      return { ...state, userDetails: action.userDetails };
    case CLEAR_USER_DETAILS:
      return { ...state, userDetails: { permissions: [] } };
    default:
      return state;
  }
}
