import config from 'react-global-configuration';
import urljoin from 'url-join';
import { httpGet, httpDelete } from './ServiceCaller';

export type GetSystemsResponse = {
  response: {
    id: string;
    systemName: string;
  }[];
};

export type GetPrincipalsResponse = {
  response: {
    id: string;
    name: string;
    role: 'user' | 'admin';
    type: string;
  }[];
};

const systemUsersApi = 'systemUsersApiUrl';

export async function fetchSystemsForUser(
  token: string,
  userId: string
): Promise<GetSystemsResponse> {
  const url = urljoin(config.get(systemUsersApi), 'v1/users', encodeURI(userId), 'systems');
  return await httpGet('GetSystemsForUser', token, url);
}

export async function fetchPrincipalsForSystems(
  token: string,
  systemId: string
): Promise<GetPrincipalsResponse> {
  const url = urljoin(config.get(systemUsersApi), 'v1/systems', systemId, '/principals');
  return await httpGet('GetPrincipalsForSystem', token, url);
}

export async function removeUserFromSystemRequest(
  token: string,
  systemId: string,
  principalId: string
): Promise<void> {
  const url = urljoin(
    config.get(systemUsersApi),
    '/v1/systems',
    encodeURIComponent(systemId),
    'principals',
    encodeURIComponent(principalId)
  );
  return await httpDelete('RemoveUserFromSystem', token, url);
}
