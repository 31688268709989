import { SET_PAGE_ERROR, CLEAR_PAGE_ERROR } from '../ActionTypes';

export type SetPageErrorAction = {
  type: typeof SET_PAGE_ERROR;
  error: string;
};

export function setPageErrorAction(error: string): SetPageErrorAction {
  return {
    type: SET_PAGE_ERROR,
    error,
  };
}

export type ClearPageErrorAction = {
  type: typeof CLEAR_PAGE_ERROR;
};

export function clearPageErrorAction(): ClearPageErrorAction {
  return {
    type: CLEAR_PAGE_ERROR,
  };
}
