import React, { useState, useRef } from 'react';
import { EditableLabelSelect } from 'Components/Util/InlineFormControls/EditableLabelSelect';
import useOnClickOutside from 'use-onclickoutside';

type RoleTdProps = {
  className: string;
  testId?: string;
  user: { type: 'user' | 'invitation'; role: string };
  isSelected: boolean;
  canAssignRoles: boolean;
  roles: { id: string; name: string }[];
  onChangeRole: (role: string) => void;
};

export function RoleTd(props: RoleTdProps) {
  const [shouldFocus, setShouldFocus] = useState(false);
  const container = useRef(null);
  const roles = props.roles;
  useOnClickOutside(container, () => setShouldFocus(false));

  return (
    <td className={props.className} onClick={() => setShouldFocus(true)} ref={container}>
      <EditableLabelSelect
        elems={roles!}
        selectedElem={props.user.role}
        isEditable={props.canAssignRoles && props.isSelected && props.user.type === 'user'}
        shouldFocus={shouldFocus}
        onSelect={props.onChangeRole}
        testId={props.testId}
      />
    </td>
  );
}
