import { call, put, select } from 'redux-saga/effects';
import { clearFetchAction, trackFetchAction } from '../../Actions/Loading/LoadingActions';
import { RootState } from '../../Reducers/RootReducer';
import {
  getApplicationArtifactRequest,
  GetApplicationArtifactResponse,
} from '../../Services/AppStoreProxy';
import { ServiceCallerError } from '../../Services/ServiceCaller';
import { sendToastNotificationAction } from '../../Actions/Notification/NotificationActions';
import { ApplicationDeploymentDetails } from '../../Models/Application/ApplicationDeployment';
import { assembleApplicationArtifact } from '../../Services/AppStore/ArtifactAssembler';
import { applicationEditParameter } from '../../Actions/Form/ApplicationInstallationFormActions';
import { EDIT_APPLICATION_PARAMETERS_ACTION } from '../../Actions/ActionTypes';
import { cancelOnNavigationEffect } from '../CancelOnNavigationSaga';

export function* editDeploymentParametersSaga(action: any) {
  yield put(trackFetchAction('editParametersSaga'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    const deployment: ApplicationDeploymentDetails = yield select((state: RootState) =>
      state.device.applications.find(dep => dep.id === action.deploymentId)
    );
    const artifact: GetApplicationArtifactResponse = yield call(
      getApplicationArtifactRequest,
      token,
      deployment.appId,
      deployment.appVersion
    );

    yield put(applicationEditParameter(assembleApplicationArtifact(artifact), deployment));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      yield put(sendToastNotificationAction('warning', e));
    }
  } finally {
    yield put(clearFetchAction('editParametersSaga'));
  }
}

export function* watchEditApplicationParametersAction() {
  yield cancelOnNavigationEffect(EDIT_APPLICATION_PARAMETERS_ACTION, editDeploymentParametersSaga);
}
