import { SET_PAGE_ERROR, CLEAR_PAGE_ERROR } from 'Actions/ActionTypes';
import { SetPageErrorAction, ClearPageErrorAction } from 'Actions/Notification/PageErrorActions';

type PageErrorReducerActions = SetPageErrorAction | ClearPageErrorAction;

export function pageErrorReducer(state: string = '', action: PageErrorReducerActions) {
  switch (action.type) {
    case SET_PAGE_ERROR:
      return action.error;
    case CLEAR_PAGE_ERROR:
      return '';
  }
  return state;
}
