import { SetSystemFeatureFlagsAction } from 'Actions/FeatureFlags/FeatureFlagsActions';
import { SET_SYSTEM_FEATURE_FLAGS } from 'Actions/ActionTypes';

type Actions = SetSystemFeatureFlagsAction;
const defaultState = new Map<string, boolean>();

export function featureFlagReducer(state: Map<string, boolean> = defaultState, action: Actions) {
  switch (action.type) {
    case SET_SYSTEM_FEATURE_FLAGS:
      return action.featureFlags;
    default:
      return state;
  }
}
