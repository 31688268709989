import { UPDATE_APPLICATION } from 'Actions/ActionTypes';
import { UpdateApplicationAction } from 'Actions/Application/ApplicationActions';
import { fetchDeviceAction } from 'Actions/Device/DeviceActions';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { ApplicationDeployment } from 'Models/Application/ApplicationDeployment';
import { RootState } from 'Reducers/RootReducer';
import { call, cancel, put, select } from 'redux-saga/effects';
import { withNavigationGate } from 'Sagas/WithNavigationGate';
import { getNewlyRequiredParameters } from 'Services/AppDeployment/DeploymentParameterUtil';
import { updateApplicationDeploymentRequest } from 'Services/AppDeploymentProxy';
import {
  getApplicationArtifactRequest,
  GetApplicationArtifactResponse,
} from 'Services/AppStoreProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';

export function* updateApplicationSaga(action: UpdateApplicationAction) {
  yield put(trackFetchAction('updateApplication'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    const deployments: ApplicationDeployment[] = yield select(
      (state: RootState) => state.device.applications
    );
    const updatedDeployment = deployments.find(x => x.id === action.deploymentId);
    if (!updatedDeployment) {
      throw 'The updated deployment could not be found.';
    }
    const artifact: GetApplicationArtifactResponse = yield call(
      getApplicationArtifactRequest,
      token,
      updatedDeployment.appId,
      action.appVersion
    );

    const newParameters = getNewlyRequiredParameters(updatedDeployment, artifact.response as any);
    if (newParameters.length > 0) {
      yield put(
        sendToastNotificationAction(
          'warning',
          'The application cannot be automatically updated. Open the application details page to manually update the application. '
        )
      );
      yield cancel();
    }

    yield call(
      updateApplicationDeploymentRequest,
      token,
      action.systemId,
      action.serialNumber,
      action.deploymentId,
      action.appVersion,
      updatedDeployment.deployedResources
    );

    yield put(sendToastNotificationAction('success', 'The application will be updated shortly.'));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      console.log(e);
      yield put(
        sendToastNotificationAction('warning', 'An error occurred while updating the application.')
      );
    }
  } finally {
    yield put(clearFetchAction('updateApplication'));
  }

  return action;
}

function* afterCompletion(action: UpdateApplicationAction) {
  yield put(fetchDeviceAction(action.systemId, action.serialNumber));
}

export function* watchUpdateApplication() {
  yield withNavigationGate(UPDATE_APPLICATION, updateApplicationSaga, afterCompletion);
}
