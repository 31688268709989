import * as React from 'react';
import { PermissionModel } from 'Models/Authorization';
import classNames from 'classnames';

type Props = {
  permissions: PermissionModel[];
};

export function UserPermissions(props: Props) {
  return (
    <>
      <ul className="permission-list padding-left-md">
        {props.permissions.map(permission => (
          <li
            className={classNames('margin-bottom-sm permission-list-item', {
              ['has-access']: permission.hasAccess,
              ['has-no-access']: !permission.hasAccess,
            })}
            key={permission.permissionName}
            data-testid={permission.permissionName}
          >
            {permission.permissionName}
          </li>
        ))}
      </ul>
    </>
  );
}
