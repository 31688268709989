import { useEffect, useState } from 'react';

export function useTimer(callback: () => void, interval: number, deps: any[] = []) {
  const [isExecuting, setExecuting] = useState(false);
  useEffect(() => {
    const intervalId = window.setInterval(async () => {
      if (!isExecuting) {
        setExecuting(true);
        try {
          await callback();
        } finally {
          setExecuting(false);
        }
      }
    }, interval);
    return () => window.clearInterval(intervalId);
  }, deps);
}
