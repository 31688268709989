import React from 'react';
import styles from './SearchBox.module.css';
import classNames from 'classnames';

type Props = {
  value: string;
  onChange: (_: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
};

export function SearchBox(props: Props) {
  return (
    <div className={classNames('input-group', styles.container)}>
      <input
        className="form-control"
        data-testid="search-box-field"
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus || false}
      />
      <div className={styles.searchIcon}>
        <i className="ico-sc-search" />
      </div>
    </div>
  );
}
