import { take, fork, call, race, cancel } from 'redux-saga/effects';
import { _AFTER_NAVIGATION } from 'Actions/ActionTypes';

type SagaFunction = (...args: any[]) => any;

export function* _navigationGateSaga(
  saga: SagaFunction,
  continueWith: SagaFunction,
  onError: SagaFunction
) {
  const navigationListenerTask = yield fork(function*() {
    yield take(_AFTER_NAVIGATION);
  });

  try {
    const result = yield call(saga);
    if (navigationListenerTask.isRunning()) {
      yield race({
        navigation: call(navigationListenerTask.toPromise),
        continueWith: call(continueWith, result),
      });
    }
  } catch (error) {
    yield call(onError, error);
  } finally {
    yield cancel(navigationListenerTask);
  }
}

export function* navigationGateSaga(
  pattern: string,
  saga: SagaFunction,
  continueWith: SagaFunction,
  onError: SagaFunction
) {
  while (true) {
    const action = yield take(pattern);
    yield fork(
      _navigationGateSaga,
      function*() {
        return yield call(saga, action);
      },
      continueWith,
      onError
    );
  }
}
// pattern: Store action (string)
// saga: The uninterruptible saga.
// continueWith: Invoked when the user has not triggered any navigation action.
//               Will receive as a parameter the *returned* value of the first saga.
// onError: Invoked when the uninterruptible saga throws an exception.
export function withNavigationGate(
  pattern: string,
  saga: SagaFunction,
  continueWith: SagaFunction,
  onError: SagaFunction = () => undefined
) {
  return fork(navigationGateSaga, pattern, saga, continueWith, onError);
}
