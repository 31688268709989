import { ReactNode } from 'react';
import React from 'react';

type Props = {
  children: ReactNode;
};

export function FlexPageContent(props: Props) {
  return (
    <div id="content" className="container-fluid">
      <div id="page-wrapper" className="no-padding-left padding-top-md">
        <div className="flex-page-content-container padding-left-md padding-right-md">
          {props.children}
        </div>
      </div>
    </div>
  );
}
