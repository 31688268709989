import { call, put, select } from 'redux-saga/effects';
import { clearFetchAction, trackFetchAction } from '../../Actions/Loading/LoadingActions';
import { RootState } from '../../Reducers/RootReducer';
import {
  getApplicationArtifactRequest,
  GetApplicationArtifactResponse,
} from '../../Services/AppStoreProxy';
import { ServiceCallerError } from '../../Services/ServiceCaller';
import { sendToastNotificationAction } from '../../Actions/Notification/NotificationActions';
import { assembleApplicationArtifact } from '../../Services/AppStore/ArtifactAssembler';
import { receiveSelectedApplicationArtifactAction } from '../../Actions/Form/ApplicationInstallationFormActions';
import { cancelOnNavigationEffect } from '../CancelOnNavigationSaga';
import { FetchSelectedArtifactAction } from 'Actions/Form/ApplicationInstallationFormActions';
import { APPLICATION_INSTALLATION_FETCH_SELECTED_ARTIFACT } from 'Actions/ActionTypes';

export function* fetchApplicationArtifactSaga(action: FetchSelectedArtifactAction) {
  yield put(trackFetchAction('fetchApplicationArtifact'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);

    const artifact: GetApplicationArtifactResponse = yield call(
      getApplicationArtifactRequest,
      token,
      action.appId,
      action.appVersion
    );

    yield put(receiveSelectedApplicationArtifactAction(assembleApplicationArtifact(artifact)));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occured while fetching application artifact.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('fetchApplicationArtifact'));
  }
}

export function* watchFetchApplicationArtifact() {
  yield cancelOnNavigationEffect(
    APPLICATION_INSTALLATION_FETCH_SELECTED_ARTIFACT,
    fetchApplicationArtifactSaga
  );
}
