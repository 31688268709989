import { _AFTER_NAVIGATION } from 'Actions/ActionTypes';
import { AnyAction } from 'redux';

type PendingChangesReducerState = {
  dirty: boolean;
};

const actionsWhichTriggerFormChanges: string[] = [];

const actionsWhichClearFormChanges = [_AFTER_NAVIGATION];

export function pendingChangesReducer(
  state: PendingChangesReducerState = { dirty: false },
  action: AnyAction
) {
  if (actionsWhichTriggerFormChanges.indexOf(action.type) !== -1) {
    return { dirty: true };
  }

  if (actionsWhichClearFormChanges.indexOf(action.type) !== -1) {
    return { dirty: false };
  }

  return state;
}
