import { createLiteralTypeCaster } from 'Utilities/TypeScriptUtils';

export type System = {
  id: string;
  name: string;
};

export type SystemInfo = {
  id: string;
  name: string;
  type: SystemType;
};

const systemTypeValues = ['development', 'production', 'unknown'] as const;
export type SystemType = typeof systemTypeValues[number];
export const toSystemType = createLiteralTypeCaster<SystemType>(systemTypeValues, 'unknown');
