import React from 'react';

import { Breadcrumb, BreadcrumbItem } from 'Components/Util/Breadcrumb';
import { UserPermissions } from './UserPermissions';
import { PermissionModel } from 'Models/Authorization';
import { LightPrincipal as LightUser } from '../Types';

type Props = {
  user: LightUser | undefined;
  permissions: PermissionModel[];
  systemId: string;
  principalId: string;
};

export function UserDetailsPageView(props: Props) {
  return (
    <div id="content" className="scrollable">
      <div className="client-portal-heading-banner">
        <Breadcrumb>
          <BreadcrumbItem path={`/systems/${props.systemId}/Users`} text="Users" />
          <BreadcrumbItem text={props.user ? props.user.name : ''} />
        </Breadcrumb>
      </div>
      <div id="page-wrapper" className="user-details padding-left-md padding-right-md">
        <div className="client-portal-table-header margin-bottom-md">
          <h2>Permissions</h2>
        </div>
        <UserPermissions permissions={props.permissions} />
      </div>
    </div>
  );
}
