import React from 'react';

type TableButtonProps = {
  iconClassName: string;
  onClick: () => void;
  testId?: string;
};

// This component requires its parent to have the "hover-visibility-trigger" class with any trigger.
export default function ActionButton(props: TableButtonProps) {
  return (
    <a
      data-testid={props.testId}
      className="pull-right visible-on-hover"
      onClick={e => {
        props.onClick();
        e.stopPropagation();
      }}
    >
      <i className={props.iconClassName} />
    </a>
  );
}
