import { useState } from 'react';
import * as React from 'react';
import './CollapsablePanel.css';

type CollapsablePanelProps = {
  children: any;
  isCollapsable?: boolean;
};

export function CollapsablePanel({ children, isCollapsable }: CollapsablePanelProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  let elements = React.Children.toArray(children);

  function toggleCollapse() {
    setIsCollapsed(previous => !previous);
  }

  return (
    <div className="client-portal-collapsable-panel panel panel-default">
      <div className="panel-body">
        <div className={'header' + (isCollapsed ? ' collapsed' : '')}>
          {(isCollapsable === undefined || isCollapsable === true) && (
            <a onClick={toggleCollapse}>
              <i className="ico-sc-ptz-up" />
            </a>
          )}
          <div>{elements[0]}</div>
        </div>
        {!isCollapsed && <div className="body">{elements.slice(1)}</div>}
      </div>
    </div>
  );
}
