import { fetchSystemFeatureFlagsAction } from 'Actions/FeatureFlags/FeatureFlagsActions';
import { RadioButton } from 'Components/Util/Controls/RadioButton';
import { useFeatureFlagToggle } from 'Hooks/useFeatureFlagToggle';
import { LeanApplicationArtifact } from 'Models/Application/ApplicationStore';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { compareVersions } from 'Utilities/AppVersionUtils';
import styles from './ArtifactList.module.css';

type Props = {
  artifacts: LeanApplicationArtifact[];
  selectedVersion: string;
  installedVersion?: string;
  selectArtifact: (version: string) => void;
  fetchFeatureFlags: (systemId: string) => void;
  systemId: string;
};

export function _ArtifactList(props: Props) {
  useEffect(() => {
    props.fetchFeatureFlags(props.systemId);
  }, [props.systemId]);

  const isDowngradeAllowed = useFeatureFlagToggle('AllowApplicationDowngrade')!;

  function canInstall(artifactVersion: string): boolean {
    if (props.installedVersion) {
      return isDowngradeAllowed || compareVersions(props.installedVersion, artifactVersion) <= 0;
    }
    return true;
  }

  return (
    <div className={styles.artifactList}>
      <table className="table">
        <thead>
          <tr>
            <th className="td-xs" />
            <th className="td-md">Version</th>
            <th className="td-md">Stage </th>
          </tr>
        </thead>
      </table>
      <div className="flex-content">
        <table className="table">
          <tbody>
            {props.artifacts.map(artifact => (
              <tr key={artifact.version}>
                <td
                  className="td-xs"
                  onClick={() => {
                    if (canInstall(artifact.version)) {
                      props.selectArtifact(artifact.version);
                    }
                  }}
                >
                  <RadioButton
                    value={artifact.version === props.selectedVersion}
                    onChange={() => undefined}
                    disabled={!canInstall(artifact.version)}
                    testId="select-artifact"
                  />
                </td>
                <td className="td-md">{artifact.version}</td>
                <td className="td-md">{artifact.stage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchFeatureFlags: (systemId: string) => dispatch(fetchSystemFeatureFlagsAction(systemId)),
  };
}

export const ArtifactList = connect(null, mapDispatchToProps)(_ArtifactList);
