import { all } from 'redux-saga/effects';
import { watchNavigation } from './NavigationSaga';
import { watchGetUserSystemsSaga } from './UserSystemsSaga';
import { watchFetchDevice } from './DeviceList/FetchDeviceSaga';
import { watchToastNotification } from './ToastNotificationSaga';
import { watchDeleteDevice } from './DeviceList/DeleteDeviceSaga';
import { watchFetchDevices } from './DeviceList/FetchDeviceListSaga';
import { watchFetchFirmwareUpgradeDetails } from './DeviceList/FetchFirmwareUpgradeDetailsSaga';
import { watchUpdateApplication } from './Application/UpdateApplicationSaga';
import { watchDeviceJobsCreateJob } from './DeviceJobs/DeviceJobsCreateJobSaga';
import { watchUninstallApplication } from './Application/UninstallApplicationSaga';
import { watchSubmitDeviceEnrollmentForm } from './DeviceList/DeviceEnrollmentSaga';
import { watchSilentlyFetchDeviceList } from './DeviceList/SilentDeviceListFetchSaga';
import { watchFetchAvailableApplications } from './Application/FetchApplicationListSaga';
import { watchFetchApplication } from './Application/FetchInstallableApplicationDetailsSaga';
import { watchSubmitApplicationInstallationForm } from './Application/SubmitApplicationInstallationFormSaga';
import { watchStartApplication } from './Application/StartApplicationSaga';
import { watchRestartApplication } from './Application/RestartApplicationSaga';
import { watchStopApplication } from './Application/StopApplicationSaga';
import { watchEditApplicationParametersAction } from './Application/EditDeploymentParametersSaga';
import { watchFetchUserList } from './Users/FetchUserListSaga';
import { watchFetchUserInvitationList } from './Users/FetchUserInvitationListSaga';
import { watchRemoveUserFromSystem } from './Users/RemoveUserFromSystemSaga';
import { watchDeleteUserInvitation } from './Users/DeleteUserInvitationSaga';
import { watchCreateUserInvitation } from './Users/CreatePrincipalInvitationSaga';
import { watchCreateServiceRegistration } from './Users/CreatePrincipalInvitationSaga';
import { watchGetSystem } from './SystemSaga';
import { watchFetchDeviceDetails } from './DeviceList/FetchDeviceDetailsSaga';
import { watchFetchFirmwareInfo } from './DeviceList/FetchFirmwareInfoSaga';
import { watchChangeUserRole } from './Users/ChangeUserRoleSaga';
import { watchFetchUserSystemPermissionsSaga } from './Permissions/FetchUserSystemPermissionsSaga';
import { watchFetchSystemFeatureFlagsSaga } from './FeatureFlags/FetchSystemFeatureFlagsSaga';
import { watchFetchRolePermissionsSaga } from './Permissions/FetchRolePermissionsSaga';
import { watchFetchRolePrincipalsSaga } from './Users/FetchRolePrincipalsSaga';
import { watchForUserDetailsActions } from './Users/FetchUserDetailsSaga';
import { watchFetchFirmwareImage } from './DeviceList/FetchFirmwareImagesSaga';
import { watchFetchApplicationArtifact } from './Application/FetchApplicationArtifactSaga';
import { watchFirmwareUpgradeHistory } from './DeviceList/FetchFirmwareUpgradeHistorySaga';

export function* rootSaga() {
  yield all([
    watchNavigation(),
    watchToastNotification(),
    watchFetchDeviceDetails(),
    watchFetchFirmwareInfo(),
    watchFetchDevice(),
    watchFetchDevices(),
    watchFirmwareUpgradeHistory(),
    watchFetchFirmwareUpgradeDetails(),
    watchDeleteDevice(),
    watchSubmitDeviceEnrollmentForm(),
    watchSilentlyFetchDeviceList(),
    watchDeviceJobsCreateJob(),
    watchGetUserSystemsSaga(),
    watchFetchApplication(),
    watchFetchAvailableApplications(),
    watchSubmitApplicationInstallationForm(),
    watchEditApplicationParametersAction(),
    watchUninstallApplication(),
    watchStartApplication(),
    watchStopApplication(),
    watchRestartApplication(),
    watchUpdateApplication(),
    watchFetchApplicationArtifact(),
    watchFetchFirmwareImage(),

    // Users
    watchFetchUserList(),
    watchFetchUserInvitationList(),
    watchRemoveUserFromSystem(),
    watchDeleteUserInvitation(),
    watchCreateUserInvitation(),
    watchCreateServiceRegistration(),
    watchChangeUserRole(),
    watchFetchUserSystemPermissionsSaga(),
    watchForUserDetailsActions(),

    // System
    watchGetSystem(),

    // Authorization
    watchFetchRolePermissionsSaga(),
    watchFetchRolePrincipalsSaga(),

    // Feature Flags
    watchFetchSystemFeatureFlagsSaga(),
  ]);
}
