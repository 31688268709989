import {
  UpdateDeviceEnrollmentFormAction,
  ClearDeviceEnrollmentFormAction,
  SetDeviceEnrollmentFormErrorsAction,
} from 'Actions/Form/DeviceEnrollmentFormActions';
import {
  UPDATE_DEVICE_ENROLLMENT_FORM,
  CLEAR_DEVICE_ENROLLMENT_FORM,
  SET_DEVICE_ENROLLMENT_FORM_ERRORS,
} from 'Actions/ActionTypes';

export type DeviceEnrollmentFormReducerState = {
  serialNumber: string;
  activationCode: string;
  deviceName: string;
};

export type DeviceEnrollmentFormReducerErrorState = {
  serialNumberError: string;
  activationCodeError: string;
  deviceNameError: string;
};

type DeviceEnrollmentFormReducerActions =
  | UpdateDeviceEnrollmentFormAction
  | ClearDeviceEnrollmentFormAction
  | SetDeviceEnrollmentFormErrorsAction;

const defaultState: DeviceEnrollmentFormReducerState & DeviceEnrollmentFormReducerErrorState = {
  serialNumber: '',
  activationCode: '',
  deviceName: '',

  serialNumberError: '',
  activationCodeError: '',
  deviceNameError: '',
};

export function deviceEnrollmentFormReducer(
  state: DeviceEnrollmentFormReducerState & DeviceEnrollmentFormReducerErrorState = defaultState,
  action: DeviceEnrollmentFormReducerActions
) {
  switch (action.type) {
    case UPDATE_DEVICE_ENROLLMENT_FORM:
      return { ...state, ...action.form };
    case CLEAR_DEVICE_ENROLLMENT_FORM:
      return defaultState;
    case SET_DEVICE_ENROLLMENT_FORM_ERRORS:
      return { ...state, ...action.errors };
    default:
      return state;
  }
}
