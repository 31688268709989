import React from 'react';
import styles from './ApplicationCard.module.css';
import { ApplicationImagePlaceholder } from './ApplicationImagePlaceholder';
import { ApplicationIdentity } from 'Models/Application/ApplicationStore';
import classNames from 'classnames';

type Props = {
  application: ApplicationIdentity;
  onClick?: () => void;
};

export function InstallableApplicationCard(props: Props) {
  return (
    <div className={styles.appCard} onClick={props.onClick} data-testid="app-card">
      <div className={styles.imageContainer}>
        <ApplicationImagePlaceholder text={props.application.displayName} />
      </div>
      <div className={styles.textContainer}>
        <div className={classNames(styles.textContent, styles.title)}>
          {props.application.displayName}
        </div>
        <div className={styles.textContent}>{props.application.latestRelease} </div>
      </div>
    </div>
  );
}
