import { ReceiveAvailableApplicationsAction } from 'Actions/Application/ApplicationActions';
import { RECEIVE_AVAILABLE_APPLICATIONS } from 'Actions/ActionTypes';
import { ApplicationIdentity } from 'Models/Application/ApplicationStore';

type ApplicationListReducerState = ApplicationIdentity[];

type ApplicationListReducerActions = ReceiveAvailableApplicationsAction;

const defaultState: ApplicationListReducerState = [];

export function applicationListReducer(
  state: ApplicationListReducerState = defaultState,
  action: ApplicationListReducerActions
) {
  switch (action.type) {
    case RECEIVE_AVAILABLE_APPLICATIONS:
      return action.applications;
    default:
      return state;
  }
}
