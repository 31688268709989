import {
  FETCH_AUTHENTICATED_USER,
  SET_AUTHENTICATED_USER,
  SET_SYSTEMS_FOR_USER,
  SET_LOGIN_ERROR,
  SET_ERROR_FETCHING_SYSTEMS,
  END_FETCH_USER,
  CHANGE_USER_ROLE,
  SET_USER_SYSTEM_PERMISSIONS,
  FETCH_USER_SYSTEM_PERMISSIONS,
} from '../ActionTypes';
import { AuthenticatedUser } from 'Authentication/AuthenticationService';
import { Permissions } from 'Models/Authorization';

export type UserActions =
  | FetchAuthenticatedUserAction
  | EndFetchUserAction
  | SetAuthenticatedUserAction
  | SetSystemsForUser
  | SetLoginError
  | SetErrorFetchingSystems
  | SetUserSystemPermissionsAction;

export type FetchAuthenticatedUserAction = {
  type: typeof FETCH_AUTHENTICATED_USER;
};
export function fetchAuthenticatedUserAction(): FetchAuthenticatedUserAction {
  return {
    type: FETCH_AUTHENTICATED_USER,
  };
}

export type EndFetchUserAction = {
  type: typeof END_FETCH_USER;
};
export function endFetchUserAction(): EndFetchUserAction {
  return {
    type: END_FETCH_USER,
  };
}

export type SetAuthenticatedUserAction = {
  type: typeof SET_AUTHENTICATED_USER;
  authUser: AuthenticatedUser;
};
export function setAuthenticatedUserAction(
  authUser: AuthenticatedUser
): SetAuthenticatedUserAction {
  return {
    type: SET_AUTHENTICATED_USER,
    authUser,
  };
}

export type FetchUserSystemPermissionsAction = {
  type: typeof FETCH_USER_SYSTEM_PERMISSIONS;
  systemId: string;
  userId: string;
};

export function fetchUserSystemPermissionsAction(
  systemId: string,
  userId: string
): FetchUserSystemPermissionsAction {
  return {
    type: FETCH_USER_SYSTEM_PERMISSIONS,
    systemId,
    userId,
  };
}

export type SetUserSystemPermissionsAction = {
  type: typeof SET_USER_SYSTEM_PERMISSIONS;
  permissions: Permissions;
};

export function setUserSystemPermissionsAction(
  permissions: Permissions
): SetUserSystemPermissionsAction {
  return {
    type: SET_USER_SYSTEM_PERMISSIONS,
    permissions,
  };
}

export type SetSystemsForUser = {
  type: typeof SET_SYSTEMS_FOR_USER;
  systems: { id: string; name: string }[];
};
export function setSystemsForUser(systems: { id: string; name: string }[]): SetSystemsForUser {
  return {
    type: SET_SYSTEMS_FOR_USER,
    systems,
  };
}

export type SetLoginError = {
  type: typeof SET_LOGIN_ERROR;
  error: string;
};
export function setLoginError(error: string): SetLoginError {
  return {
    type: SET_LOGIN_ERROR,
    error,
  };
}

export type SetErrorFetchingSystems = {
  type: typeof SET_ERROR_FETCHING_SYSTEMS;
  error: string;
};
export function setErrorFetchingSystems(error: string): SetErrorFetchingSystems {
  return {
    type: SET_ERROR_FETCHING_SYSTEMS,
    error,
  };
}

export type ChangeUserRoleAction = {
  type: typeof CHANGE_USER_ROLE;
  systemId: string;
  userId: string;
  role: string;
};

export function changeUserRoleAction(
  systemId: string,
  userId: string,
  role: string
): ChangeUserRoleAction {
  return {
    type: CHANGE_USER_ROLE,
    systemId,
    userId,
    role,
  };
}
