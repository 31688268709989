import { DeployedResourceParameterDetails } from 'Models/Application/ApplicationDeployment';
import { DeployableResource } from 'Models/Application/ApplicationStore';
import { DeploymentResourceModel } from 'Services/AppDeploymentProxy';
import { deconstructParameterScopedKey } from './DeploymentParameterUtil';

export function assembleResourceModel(
  resourceDeclarations: DeployableResource[],
  parameterValues: { [key: string]: DeployedResourceParameterDetails }
): DeploymentResourceModel[] {
  return resourceDeclarations
    .map(resource => {
      let parameters = new Array<any>();
      Object.entries(parameterValues).forEach(([key, parameterValue]) => {
        const deconstructedParameterKey = deconstructParameterScopedKey(key);
        if (deconstructedParameterKey[0] !== resource.name) return;
        parameters.push({
          key: deconstructedParameterKey[1],
          value: parameterValue.value,
          isCustomValue: parameterValue.isCustomValue,
        });
      });
      return {
        name: resource.name,
        parameters: parameters,
      };
    })
    .filter(resource => !!resource);
}
