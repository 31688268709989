export function dateToReadableString(date: Date) {
  return (
    date.getFullYear() +
    '-' +
    addPaddingToSingleDigitNumber(date.getMonth() + 1) +
    '-' +
    addPaddingToSingleDigitNumber(date.getDate()) +
    ' ' +
    addPaddingToSingleDigitNumber(date.getHours()) +
    ':' +
    addPaddingToSingleDigitNumber(date.getMinutes())
  );
}

function addPaddingToSingleDigitNumber(num: number): string {
  return num.toString().padStart(2, '0');
}
