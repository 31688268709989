export function DeviceInfoFrom(
  serialNumber: string,
  deviceInventory: DeviceInventory,
  deviceConfiguration: DeviceConfiguration
): DeviceInfo {
  return {
    serialNumber,
    time: deviceConfiguration.time,
    network: deviceConfiguration.network,
    system: deviceInventory.system,
  };
}

export type DeviceInfo = {
  serialNumber: string;
  time: Time;
  network: Network;
  system: System;
};

export type DeviceConfiguration = {
  time: Time;
  network: Network;
};

export type DeviceInventory = {
  system: System;
};

export type System = {
  hwSerialNumber: string;
};

export type Time = {
  timeZone: string;
};

export type Network = {
  defaultGateway: string;
  interfaces: { [name: string]: Interface };
};

export type Interface = {
  name: string;
  source: string;
  macAddress: string;
  flags: string[];
  ipAddresses: IpAddress[];
};

export type IpAddress = {
  address: string;
  networkMask: string;
  family: string;
};
