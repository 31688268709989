import {
  END_FETCH_USER,
  SET_AUTHENTICATED_USER,
  SET_ERROR_FETCHING_SYSTEMS,
  SET_LOGIN_ERROR,
  SET_SYSTEMS_FOR_USER,
  SET_USER_SYSTEM_PERMISSIONS,
} from 'Actions/ActionTypes';
import { UserActions } from 'Actions/User/UserActions';
import { AuthenticatedUser } from 'Authentication/AuthenticationService';
import { Permissions } from 'Models/Authorization';

export type UserReducerState = {
  authUser?: AuthenticatedUser;
  systems: { id: string; name: string }[];
  permissions: Permissions;
  loginError: string;
  systemsError: string;
  isAuthenticationFlowCompleted: boolean;
};

const emptyState = {
  systems: [],
  permissions: {},
  loginError: '',
  systemsError: '',
  isAuthenticationFlowCompleted: false,
};

export function userReducer(state: UserReducerState = emptyState, action: UserActions) {
  switch (action.type) {
    case END_FETCH_USER:
      return { ...state, isAuthenticationFlowCompleted: true };
    case SET_AUTHENTICATED_USER:
      return { ...state, authUser: action.authUser };
    case SET_SYSTEMS_FOR_USER:
      return { ...state, systems: action.systems };
    case SET_LOGIN_ERROR:
      return { ...state, loginError: action.error };
    case SET_ERROR_FETCHING_SYSTEMS:
      return { ...state, systemsError: action.error };
    case SET_USER_SYSTEM_PERMISSIONS:
      return { ...state, permissions: action.permissions };
    default:
      return state;
  }
}
