export interface IPermanentFlags {
  DebuggingTools: boolean;
}

export interface ITemporaryFlags {}

export const PermanentFlagNames: { [key in keyof IPermanentFlags]: string } = {
  DebuggingTools: 'portal-debugging-tools',
};

export const TemporaryFlagNames: { [key in keyof ITemporaryFlags]: string } = {};

export const PermanentFlagDefaultValues: { [key in keyof IPermanentFlags]: boolean } = {
  DebuggingTools: false,
};

export const TemporaryFlagDefaultValues: { [key in keyof ITemporaryFlags]: boolean } = {};
