import { FETCH_DEVICE_LIST } from 'Actions/ActionTypes';
import { FetchDeviceListAction, _receiveDeviceListAction } from 'Actions/Device/DeviceListActions';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { setPageErrorAction } from 'Actions/Notification/PageErrorActions';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select } from 'redux-saga/effects';
import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import {
  GetEnrolledDeviceResponse,
  getEnrolledDevicesRequest,
} from 'Services/Device/DeviceIdentityProxy';
import { fromDeviceListApiResponse } from 'Services/Device/DeviceListAssembler';

export function* fetchDevicesSaga(action: FetchDeviceListAction) {
  yield put(trackFetchAction('fetchDeviceList'));
  const token: string = yield select((state: RootState) => state.authentication.token);

  try {
    const response: GetEnrolledDeviceResponse[] = yield call(
      getEnrolledDevicesRequest,
      token,
      action.systemId
    );
    yield put(_receiveDeviceListAction(fromDeviceListApiResponse(response)));
  } catch (e) {
    console.log(e);
    yield put(
      setPageErrorAction(
        'An error occurred while retrieving the device list. Please try again later.'
      )
    );
  } finally {
    yield put(clearFetchAction('fetchDeviceList'));
  }
}

export function* watchFetchDevices() {
  yield cancelOnNavigationEffect(FETCH_DEVICE_LIST, fetchDevicesSaga);
}
