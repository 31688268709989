import Modal from 'Components/Util/Modal';
import React from 'react';

type Props = {
  close: () => void;
};

export function EmptyApplicationInstallationFormPage(props: Props) {
  return (
    <Modal show={true} title="Install Application" onCancel={props.close} large={true}>
      <></>
    </Modal>
  );
}
