import React, { useEffect, useState } from 'react';
import { FirmwareStatus } from './FirmwareStatus';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import { CreateDeviceJob } from 'Models/DeviceJobs/DeviceJobs';
import styles from './DeviceSummary.module.css';
import { FirmwareInfo } from 'Models/Device/FirmwareInfo';
import { FirmwareUpgradeButton } from 'Components/DeviceJobs/FirmwareUpgradeButton';
import { FirmwareUpgradeDetails } from 'Models/Device/FirmwareUpgradeDetails';
import { Select } from 'Components/Util/Select';
import { FirmwareImage } from 'Models/Device/FirmwareImage';
import ProgressionLabel from '../../Util/ProgressionLabel';

type Props = {
  device: EnrolledDevice | null;
  firmwareInfo: FirmwareInfo | null;
  firmwareUpgradeDetails: FirmwareUpgradeDetails | null;
  firmwareImages: FirmwareImage[];
  defaultFirmwareImageName: string;
  disabled: boolean;
  isManualFirmwareSelectionAllowed: boolean;
  createDeviceJob: (systemId: string, serialNumber: string, job: CreateDeviceJob) => void;
};

export function DeviceSummaryFirmwareUpgrade({
  device,
  firmwareInfo,
  firmwareUpgradeDetails,
  firmwareImages,
  defaultFirmwareImageName,
  disabled,
  isManualFirmwareSelectionAllowed,
  createDeviceJob,
}: Props) {
  const [firmwareImageName, setFirmwareImageName] = useState(defaultFirmwareImageName);

  useEffect(() => {
    setFirmwareImageName(defaultFirmwareImageName);
  }, [defaultFirmwareImageName]);

  const getFirmwareImageDetails = (firmwareImages: FirmwareImage[]) => {
    return firmwareImages.flatMap(firmware =>
      firmware.manifests.map(manifest => {
        return {
          id: manifest.imageName,
          name: manifest.imageName,
          version: getVersion(manifest.firmwareVersion),
        };
      })
    );
  };

  const getFirmwareImageVersion = (firmwareImage: string): string => {
    let firmwareInfo = firmwareImageDetails.find(firmware => firmware.name === firmwareImage);
    return firmwareInfo?.version || latestVersion;
  };

  const latestVersion = getVersion(firmwareInfo && firmwareInfo.latestFirmware.firmwareVersion);
  const firmwareImageDetails = getFirmwareImageDetails(firmwareImages);

  return (
    <div data-testid="firmware-upgrade">
      {firmwareInfo ? (
        <FirmwareStatus
          isUpToDate={firmwareInfo.isUpToDate}
          latestFirmwareVersion={getVersion(firmwareInfo.latestFirmware.firmwareVersion)}
          firmwareVersion={getVersion(firmwareInfo.currentFirmware.firmwareVersion)}
        />
      ) : (
        <h3 className={styles.marginLeft}>
          <b> The information is temporary unavailable. </b>
        </h3>
      )}
      <div className={styles.flexVertical}>
        {firmwareUpgradeDetails &&
          firmwareUpgradeDetails.firmwareUpgradeStatus.toLowerCase() !== 'idle' && (
            <ProgressionLabel
              progress={firmwareUpgradeDetails.firmwareUpgradeProgress}
              style={styles.progressBar}
              description={firmwareUpgradeDetails.firmwareUpgradeStatusDescription}
            />
          )}
        {device &&
          (!firmwareUpgradeDetails ||
            (firmwareUpgradeDetails &&
              firmwareUpgradeDetails.firmwareUpgradeStatus.toLowerCase() === 'idle' &&
              isManualFirmwareSelectionAllowed)) && (
            <div data-testid="select-firmware-image">
              <Select
                title="Firmware images"
                elems={firmwareImageDetails}
                selectedElem={firmwareImageName}
                onSelect={image => setFirmwareImageName(image.name)}
              />
            </div>
          )}
        {device &&
          firmwareInfo &&
          (!firmwareUpgradeDetails ||
            (firmwareUpgradeDetails &&
              firmwareUpgradeDetails.firmwareUpgradeStatus.toLowerCase() === 'idle')) && (
            <div data-testid="firmware-upgrade-button">
              <FirmwareUpgradeButton
                serialNumber={device.serialNumber}
                systemId={device.systemId}
                disabled={disabled}
                createDeviceJob={createDeviceJob}
                firmwareName={firmwareInfo.firmwareName}
                firmwareVersion={
                  isManualFirmwareSelectionAllowed
                    ? getFirmwareImageVersion(firmwareImageName)
                    : firmwareInfo.latestFirmware.firmwareVersion
                }
                imageName={
                  isManualFirmwareSelectionAllowed ? firmwareImageName : defaultFirmwareImageName
                }
              />
            </div>
          )}
      </div>
    </div>
  );
}

function getVersion(firmwareVersion: string | null): string {
  return (firmwareVersion ?? '').split('+')[0];
}
