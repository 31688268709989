import { FETCH_DEVICE, RECEIVE_DEVICE, CLEAR_DEVICE } from 'Actions/ActionTypes';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import { ApplicationDeploymentDetails } from 'Models/Application/ApplicationDeployment';

export type FetchDeviceAction = {
  type: typeof FETCH_DEVICE;
  systemId: string;
  serialNumber: string;
  silent: boolean;
};

export function fetchDeviceAction(
  systemId: string,
  serialNumber: string,
  silent: boolean = false
): FetchDeviceAction {
  return {
    type: FETCH_DEVICE,
    systemId,
    serialNumber,
    silent,
  };
}

export type ReceiveDeviceAction = {
  type: typeof RECEIVE_DEVICE;
  device: EnrolledDevice;
  applications: ApplicationDeploymentDetails[];
};

export function receiveDeviceAction(
  device: EnrolledDevice,
  applications: ApplicationDeploymentDetails[]
): ReceiveDeviceAction {
  return {
    type: RECEIVE_DEVICE,
    device,
    applications,
  };
}

export type ClearDeviceAction = {
  type: typeof CLEAR_DEVICE;
};

export function clearDeviceAction(): ClearDeviceAction {
  return {
    type: CLEAR_DEVICE,
  };
}
