import React from 'react';

type Props = {
  onChange: (state: boolean) => void;
  value: boolean;
  label?: string;
  disabled?: boolean;
  testId?: string;
};

export function RadioButton(props: Props) {
  const label = props.label ? props.label : ''; // Empty label is required if empty.
  return (
    <div
      className="radio radio-primary"
      onClick={() => {
        if (!props.disabled) {
          props.onChange(!props.value);
        }
      }}
      data-testid={props.testId || 'radio-button'}
    >
      <input
        className={props.value ? 'filled' : ''}
        type="radio"
        checked={props.value}
        onChange={() => undefined}
        disabled={props.disabled}
      />
      <label>{label}</label>
      {!props.disabled && <div className="focus-animate" />}
    </div>
  );
}
