import React from 'react';
import { ConnectedNavLink } from './Browser/ConnectedNavLink';

interface IBreadcrumbItem {
  path?: string;
  text: string;
}

export function BreadcrumbItem(props: IBreadcrumbItem) {
  return (
    <li className="breadcrumb-item">
      {props.path === undefined ? (
        <span>{props.text}</span>
      ) : (
        <ConnectedNavLink className="text-primary" to={props.path}>
          {props.text}
        </ConnectedNavLink>
      )}
    </li>
  );
}

export function Breadcrumb(props: any) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">{props.children}</ol>
    </nav>
  );
}
