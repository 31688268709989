import { CHANGE_USER_ROLE } from 'Actions/ActionTypes';
import { put, select, call } from 'redux-saga/effects';
import { RootState } from 'Reducers/RootReducer';
import { assignRoleRequest } from 'Services/AuthorizationProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { ChangeUserRoleAction } from 'Actions/User/UserActions';
import { trackFetchAction, clearFetchAction } from 'Actions/Loading/LoadingActions';
import { withNavigationGate } from 'Sagas/WithNavigationGate';
import { fetchUserInvitationListAction } from 'Actions/Users/UserInvitationActions';

export function* changeUserRoleSaga(action: ChangeUserRoleAction) {
  yield put(trackFetchAction('changeUserRole'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    yield call(assignRoleRequest, token, action.systemId, action.userId, action.role);
    yield put(sendToastNotificationAction('success', 'The role has been successfully changed.'));
    return action;
  } finally {
    yield put(clearFetchAction('changeUserRole'));
  }
}

function* onError(e: any) {
  if (e instanceof ServiceCallerError) {
    yield put(sendToastNotificationAction('warning', e.message));
  } else {
    yield put(
      sendToastNotificationAction(
        'warning',
        'An error occurred while changing the role of the user.'
      )
    );
  }
}

function* onContinuation(action: ChangeUserRoleAction) {
  yield put(fetchUserInvitationListAction(action.systemId));
}

export function* watchChangeUserRole() {
  yield withNavigationGate(CHANGE_USER_ROLE, changeUserRoleSaga, onContinuation, onError);
}
