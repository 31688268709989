import { DeleteButtonWithConfirmationModal } from 'Components/Util/Controls/DeleteButtonWithConfirmationModal';
import React from 'react';
import { ConnectionStatusComponent } from '../ConnectionStatus';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import { EnrollmentStateComponent } from '../EnrollmentStateComponent';
import ReactTooltip from 'react-tooltip';

type Props = {
  device: EnrolledDevice;
  deleteDevice: () => void;
  goToDeviceDetails: () => void;
};

export function DeviceRow(props: Props) {
  return (
    <tr
      key={props.device.serialNumber}
      className="hover-visibility-trigger cursor-pointer"
      onClick={props.goToDeviceDetails}
    >
      <td>{props.device.deviceName}</td>
      <td>{props.device.serialNumber}</td>
      <td>
        <EnrollmentStateComponent state={props.device.enrollmentState} />
      </td>
      <td>
        <ConnectionStatusComponent status={props.device.connectionStatus} />
      </td>
      <td>{formatLastConnectionDate(props.device.lastConnectionStatusUpdate)}</td>
      <td className="td-sm">
        <DeleteButtonWithConfirmationModal
          dataTip="Delete this device"
          delete={props.deleteDevice}
          message="Are you sure you want to delete the device?"
        />
        <ReactTooltip />
      </td>
    </tr>
  );
}

function formatLastConnectionDate(date?: Date): string {
  if (date) {
    return date.toLocaleString();
  } else {
    return 'N/A';
  }
}
