import {
  applicationInstallationUpdateParameterValuesAction,
  applicationInstallationBacktrackWizardAction,
  submitApplicationInstallationFormAction,
} from 'Actions/Form/ApplicationInstallationFormActions';
import Modal from 'Components/Util/Modal';
import { ApplicationArtifact } from 'Models/Application/ApplicationStore';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { DeploymentParametersView } from './Presentation/DeploymentParametersView';
import { GoBackButton } from 'Components/Util/Controls/GoBackButton';
import { DeployedResourceParameterDetails } from 'Models/Application/ApplicationDeployment';

type OwnProps = {
  close: () => void;
};

type Props = OwnProps & {
  goBack: () => void;
  submit: () => void;
  updateParameter: (scopedKey: string, value: string, isCustomValue: boolean) => void;
  artifact: ApplicationArtifact;
  isFetching: boolean;
  parameterValues: { [scopedKey: string]: DeployedResourceParameterDetails };
  canBacktrack: boolean;
};

export function _DeploymentParametersFormPage(props: Props) {
  return (
    <Modal
      show={true}
      title="Application Parameters"
      onCancel={props.close}
      large={true}
      canYes={!props.isFetching}
      yesLabel="Submit"
      onYes={props.submit}
    >
      {props.canBacktrack && <GoBackButton onClick={props.goBack} />}
      <DeploymentParametersView
        parameterValues={props.parameterValues}
        artifact={props.artifact}
        update={props.updateParameter}
      />
    </Modal>
  );
}

function mapStateToProps(state: RootState) {
  return {
    parameterValues: state.form.applicationInstallation.parameters,
    artifact: state.form.applicationInstallation.artifact!,
    isFetching: state.loading.length > 0,
    canBacktrack: state.form.applicationInstallation.canBacktrack,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    updateParameter: (scopedKey: string, value: string, isCustomValue: boolean) => {
      dispatch(
        applicationInstallationUpdateParameterValuesAction({
          [scopedKey]: {
            value,
            isCustomValue,
          },
        })
      );
    },
    goBack: () => dispatch(applicationInstallationBacktrackWizardAction()),
    submit: () => dispatch(submitApplicationInstallationFormAction()),
  };
}

export const DeploymentParametersFormPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(_DeploymentParametersFormPage);
