import { RECEIVE_FIRMWARE_IMAGES } from 'Actions/ActionTypes';
import { ReceiveFirmwareImagesAction } from 'Actions/Device/FirmwareImageActions';
import { FirmwareImage } from 'Models/Device/FirmwareImage';

type FirmwareImagesReducerState = FirmwareImage[];

type FirmwareImagesReducerActions = ReceiveFirmwareImagesAction;

const defaultState: FirmwareImagesReducerState = [];

export function firmwareImagesReducer(
  state: FirmwareImagesReducerState = defaultState,
  action: FirmwareImagesReducerActions
) {
  switch (action.type) {
    case RECEIVE_FIRMWARE_IMAGES:
      return action.firmwareImages;
    default:
      return state;
  }
}
