import classNames from 'classnames';
import { GoBackButton } from 'Components/Util/Controls/GoBackButton';
import { InstallableApplicationDetails } from 'Models/Application/ApplicationStore';
import React from 'react';
import styles from './ApplicationDetails.module.css';
import { ApplicationImagePlaceholder } from 'Components/Application/Presentation/ApplicationImagePlaceholder';
import { ApplicationVersionInformation } from 'Components/Application/Presentation/ApplicationVersionInformation';
import { ArtifactSelectorPanel } from 'Components/Application/ArtifactSelectorPanel';
import { oc } from 'ts-optchain';

type Props = {
  application: InstallableApplicationDetails;
  goBack?: () => void;
  selectedVersion: string;
  selectVersion: (_: string) => void;
  installedVersion?: string;
  systemId: string;
};

export function ApplicationDetailsView(props: Props) {
  const stage = oc(
    props.application.artifacts.find(m => m.version === props.installedVersion)
  ).stage();

  return (
    <div>
      {props.goBack && <GoBackButton onClick={props.goBack} />}
      <div className="horizontal-flex margin-top-lg">
        <div>
          <div className={styles.appDetailsImage}>
            <ApplicationImagePlaceholder text={props.application.displayName} />
          </div>
          <ApplicationVersionInformation
            appId={props.application.id}
            latest={props.application.latestInstallableVersion}
            latestStage={props.application.latestInstallableVersionStage}
            installed={props.installedVersion}
            installedStage={stage}
          />
        </div>

        <div className={classNames('flex-content', 'margin-left', styles.applicationDescription)}>
          <h1>{props.application.displayName}</h1>
          <p>{props.application.description}</p>
        </div>
      </div>
      <ArtifactSelectorPanel
        artifacts={props.application.artifacts}
        selectedVersion={props.selectedVersion}
        selectVersion={props.selectVersion}
        installedVersion={props.installedVersion}
        systemId={props.systemId}
      />
    </div>
  );
}
