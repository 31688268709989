import { EnrollmentState } from 'Models/Device/EnrolledDevice';
import React from 'react';

type Props = {
  state: EnrollmentState;
};

export function EnrollmentStateComponent({ state }: Props) {
  return (
    <span className={classNameForEnrollmentState(state)}>{formatEnrollmentState(state)} </span>
  );
}

const formattedEnrollmentStates = {
  enrolling: 'Enrolling',
  not_enrolled: 'Not Enrolled',
  enrolled: 'Enrolled',
  provisioned: 'Provisioned',
  error_enrolling: 'Error Enrolling',
  provisioning: 'Provisioning',
  error_provisioning: 'Error Provisioning',
  error_disenrolling: 'Error Disenrolling',
};

function formatEnrollmentState(enrollmentState: EnrollmentState): string {
  if (enrollmentState in formattedEnrollmentStates) {
    return formattedEnrollmentStates[enrollmentState];
  }
  return enrollmentState;
}

const classNameForEnrollmentStates = {
  not_enrolled: 'text-neutral',
  enrolling: 'text-warning',
  enrolled: 'text-primary',
  provisioned: 'text-success',
  error_enrolling: 'text-danger',
  provisioning: 'text-warning',
  error_provisioning: 'text-danger',
  error_disenrolling: 'text-danger',
};

function classNameForEnrollmentState(enrollmentState: EnrollmentState): string {
  if (enrollmentState in classNameForEnrollmentStates) {
    return classNameForEnrollmentStates[enrollmentState];
  }
  return 'text-neutral';
}
