import React from 'react';

type Props = {
  result: string;
};

export function UpgradeHistoryResult({ result }: Props) {
  return <span className={STYLE_CLASS[result]}>{TEXT[result]} </span>;
}

const TEXT = {
  Succeeded: 'Succeeded',
  Failed: 'Failed',
  Cancelled: 'Cancelled',
};

const STYLE_CLASS = {
  Succeeded: 'text-success',
  Failed: 'text-danger',
  Cancelled: '',
};
