import React from 'react';
import styles from './ApplicationImage.module.css';

type Props = {
  text: string;
};

export function ApplicationImagePlaceholder(props: Props) {
  return (
    <div className={`${styles.appImagePlaceholderBase} ${styles.appImageInstallable}`}>
      {props.text}
    </div>
  );
}
