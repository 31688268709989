import {
  PROMPT_FOR_CONFIRMATION,
  _ACCEPT_CONFIRMATION,
  _CANCEL_CONFIRMATION,
} from 'Actions/ActionTypes';

export type PromptForConfirmationAction = {
  type: typeof PROMPT_FOR_CONFIRMATION;
  message: string;
  callback: () => void;
};

export function promptForConfirmationAction(
  message: string,
  callback: () => void
): PromptForConfirmationAction {
  return {
    type: PROMPT_FOR_CONFIRMATION,
    message,
    callback,
  };
}

export type _AcceptConfirmationAction = {
  type: typeof _ACCEPT_CONFIRMATION;
};

export function _acceptConfirmationAction(): _AcceptConfirmationAction {
  return {
    type: _ACCEPT_CONFIRMATION,
  };
}

export type _CancelConfirmationAction = {
  type: typeof _CANCEL_CONFIRMATION;
};

export function _cancelConfirmationAction(): _CancelConfirmationAction {
  return {
    type: _CANCEL_CONFIRMATION,
  };
}
