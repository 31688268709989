import * as React from 'react';
import { SystemMenuItem } from './SystemMenuItem';
import { UserMenuItem } from './UserMenuItem';
import { ConnectedNavLink } from 'Components/Util/Browser/ConnectedNavLink';

type Props = {
  systemId: string;
  showUsersPage: boolean;
  showRolesPage: boolean;
};

function TopNav(props: Props) {
  return (
    <nav className="navbar navbar-default navbar-static-top no-margin-bottom">
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target="#header-nav"
          >
            <i className="ico-sc-tile3x3" />
          </button>
          <div className="navbar-brand hidden-xs" />
          {!props.systemId ? (
            <ul id="header-nav" className="nav nav-tabs collapse">
              <li className="divider" />
            </ul>
          ) : (
            <ul id="header-nav" className="nav nav-tabs collapse">
              <li className="divider" />

              <li
                className={
                  window.location.pathname === `/systems/${props.systemId}` ? 'active' : ''
                }
              >
                <ConnectedNavLink to={`/systems/${props.systemId}`}>Home</ConnectedNavLink>
              </li>
              <li
                className={
                  window.location.pathname.startsWith(`/systems/${props.systemId}/devices`)
                    ? 'active'
                    : ''
                }
              >
                <ConnectedNavLink to={`/systems/${props.systemId}/devices`}>
                  Devices
                </ConnectedNavLink>
              </li>
              {props.showUsersPage && (
                <li
                  className={
                    window.location.pathname.startsWith(`/systems/${props.systemId}/Users`)
                      ? 'active'
                      : ''
                  }
                >
                  <ConnectedNavLink to={`/systems/${props.systemId}/Users`}>Users</ConnectedNavLink>
                </li>
              )}
              {props.showRolesPage && (
                <li
                  className={
                    window.location.pathname.startsWith(`/systems/${props.systemId}/roles`)
                      ? 'active'
                      : ''
                  }
                >
                  <ConnectedNavLink to={`/systems/${props.systemId}/roles`}>Roles</ConnectedNavLink>
                </li>
              )}
            </ul>
          )}
          <ul className="nav nav-pills pull-right">
            <SystemMenuItem systemId={props.systemId} />
            <UserMenuItem />
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default TopNav;
