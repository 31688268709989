import config from 'react-global-configuration';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './Components/App';
import { store, sagaMiddleware } from 'store';
import { Provider } from 'react-redux';
import { rootSaga } from 'Sagas/RootSaga';
import { authenticationService } from 'Authentication/AuthenticationService';
import { FlagOverrideProvider } from 'Components/Util/FlagOverrider';
import { LDProvider } from 'Providers/LDProvider';

async function renderApp() {
  // Fetch the SPA settings
  const res = await fetch('/clientAppSettings.json');
  const clientAppSettings = await res.json();
  config.set(clientAppSettings, { freeze: true, assign: false });

  authenticationService.initialize();

  // This code starts up the React app when it runs in a browser. It sets up the routing configuration
  // and injects the app into a DOM element.
  ReactDOM.render(
    <Provider store={store}>
      <LDProvider>
        <FlagOverrideProvider>
          <App />
        </FlagOverrideProvider>
      </LDProvider>
    </Provider>,
    document.getElementById('root') as HTMLElement
  );
}

function main() {
  try {
    sagaMiddleware.run(rootSaga);
    renderApp();
  } catch (err) {
    throw err;
    // TODO: render an error page here.
  }
}

main();
