import * as React from 'react';
import Spinner from './Spinner';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';

interface LoadingProps {
  show: boolean;
}

export default function Loading(props: LoadingProps) {
  if (props.show) {
    return (
      <div className="alert alert-spin alert-sticky alert-dismissable fade in" role="alert">
        <Spinner>One moment please...</Spinner>
      </div>
    );
  }

  return <Fragment />;
}

function mapStateToProps(state: RootState) {
  return {
    show: state.loading.length > 0,
  };
}
export const ConnectedLoading = connect(mapStateToProps)(Loading);
