import React, { ElementType, useState } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import Constants from 'Constants';
import config from 'react-global-configuration';

export const LDProvider: React.FC = props => {
  const settings = config.get(Constants.launchDarklySettings);
  const [provider, setProvider] = useState<{ LDProvider: ElementType } | undefined>(undefined);

  React.useLayoutEffect(() => {
    const fetch = async () => {
      const innerLDProvider = await asyncWithLDProvider({
        clientSideID: settings.launchDarklyClientId,
        options: {
          fetchGoals: false,
          sendLDHeaders: false, // Required for IE to work
          allAttributesPrivate: true,
        },
        reactOptions: {
          useCamelCaseFlagKeys: false,
        },
      });
      setProvider({ LDProvider: innerLDProvider });
    };
    fetch();
  }, [settings.launchDarklyClientId]);

  if (!provider?.LDProvider) {
    return null;
  }

  return <provider.LDProvider>{React.Children.only(props.children)}</provider.LDProvider>;
};
