import _ from 'lodash';
import { ApplicationDeployment } from 'Models/Application/ApplicationDeployment';
import { ApplicationArtifact } from 'Models/Application/ApplicationStore';
import { pairsToDictionary } from 'Utilities/DictionaryManipulation';

export function constructParameterScopedKey(resourceName: string, parameterKey: string): string {
  return `${resourceName}:${parameterKey}`;
}

export function deconstructParameterScopedKey(parameterScopedKey: string): [string, string] {
  let parts: string[] = parameterScopedKey.split(':', 2);
  return [parts[0], parts[1]];
}

export function getNewlyRequiredParameters(
  currentVersion: ApplicationDeployment,
  artifact: ApplicationArtifact
): string[] {
  const currentlyDefinedParameters: { [resourceName: string]: string[] } = pairsToDictionary(
    _.chain(currentVersion.deployedResources)
      .map(
        resource =>
          [
            resource.name,
            resource.parameters.map(p => constructParameterScopedKey(resource.name, p.key)),
          ] as [string, any]
      )
      .value()
  );

  function parameterHasValue(resourceName: string, parameterScopedKey: string) {
    const definedParametersForResource = currentlyDefinedParameters[resourceName];
    if (!definedParametersForResource) {
      return false;
    }
    return definedParametersForResource.includes(parameterScopedKey);
  }

  const result: string[] = [];
  for (const resource of artifact.resources) {
    result.push(
      ...resource.parameters
        .filter(p => !parameterHasValue(resource.name, p.scopedKey))
        .map(p => p.scopedKey)
    );
  }

  return result;
}
