import Modal from '../Modal';
import React, { Fragment, useState } from 'react';
import { ActionButton } from './ActionButton';

type Props = {
  title: string;
  actionButtonClassName?: string;
  modalButtonClassName?: string;
  yesText?: string;
  children: React.ReactNode;
  disabled: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  testId?: string;
  icon?: string;
  text?: string;
};

export function ActionButtonWithConfirmationModal(props: Props) {
  const [showModal, setShowModal] = useState(false);

  const saveModal = () => {
    props.onConfirm();
    setShowModal(false);
  };

  function HandleCancel() {
    setShowModal(false);
    if (props.onCancel !== undefined) {
      props.onCancel();
    }
  }

  return (
    <Fragment>
      <ActionButton
        className={props.actionButtonClassName}
        icon={props.icon}
        onClick={() => setShowModal(true)}
        testId={props.testId || 'create-job-action-button'}
        text={props.text}
        disabled={props.disabled}
      />
      {showModal && (
        <div data-testid="modal-view">
          <Modal
            onCancel={HandleCancel}
            canYes={true}
            yesLabel={props.yesText || 'Yes'}
            yesButtonClass={props.modalButtonClassName}
            show={true}
            onYes={() => saveModal()}
            title={props.title}
          >
            {props.children}
          </Modal>
        </div>
      )}
    </Fragment>
  );
}
