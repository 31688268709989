import {
  TRIGGER_NAVIGATION,
  CONFIRM_NAVIGATION,
  CLEAR_PENDING_NAVIGATION,
} from 'Actions/ActionTypes';
import {
  TriggerNavigationAction,
  ConfirmNavigationAction,
  ClearPendingNavigationAction,
} from 'Actions/Navigation/NavigationActions';

export type NavigationState = {
  pendingNavigation: string;
  shouldSendNavigation: boolean; // RoutingUpdater watches this property to trigger history.push() calls.
  navigationCompletionCallback?: () => void;
};

type NavigationReducerActions =
  | TriggerNavigationAction
  | ConfirmNavigationAction
  | ClearPendingNavigationAction;

const defaultState = { pendingNavigation: '', shouldSendNavigation: false };

export function navigationReducer(
  state: NavigationState = defaultState,
  action: NavigationReducerActions
): NavigationState {
  switch (action.type) {
    case TRIGGER_NAVIGATION:
      return {
        ...state,
        pendingNavigation: action.path,
        navigationCompletionCallback: action.callback,
      };

    case CONFIRM_NAVIGATION:
      return { ...state, shouldSendNavigation: true };

    case CLEAR_PENDING_NAVIGATION:
      return defaultState;

    default:
      return state;
  }
}
