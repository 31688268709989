import React, { useState } from 'react';
import Modal from '../../Util/Modal';
import Input from 'Components/Util/Input';
import { Select } from 'Components/Util/Select';

type UserInvitationDialogProps = {
  submitUserRegistration: (username: string, role: string) => void;
  onClose: () => void;
  roles: { id: string; name: string }[];
};

export default function UserInvitationDialog(props: UserInvitationDialogProps) {
  // State
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [role, setRole] = useState('user');

  function canSubmit(): boolean {
    const trimmedUser = username.trim();
    return trimmedUser !== '' && trimmedUser.length <= 320;
  }

  function setUsernameValue(value: string) {
    setUsername(value);
    if (value.trim().length > 320) {
      setUsernameError('The username cannot contain more than 320 characters.');
    } else {
      setUsernameError('');
    }
  }

  function submitInvitation() {
    if (canSubmit()) {
      props.submitUserRegistration(username.trim(), role);
      props.onClose();
    }
  }

  return (
    <Modal
      title="Invite User"
      show={true}
      yesLabel="Invite"
      onYes={submitInvitation}
      canYes={canSubmit()}
      cancelLabel="Cancel"
      onCancel={props.onClose}
    >
      <div className="modal-body">
        <form
          className="form-horizontal row"
          onSubmit={e => {
            e.preventDefault();
            submitInvitation();
          }}
        >
          <div className="form-group">
            <div className="col-sm-12">
              <Input
                title="Email Address"
                placeholder="Ex.: john.doe@genetec.com"
                value={username}
                onChange={setUsernameValue}
                focusByDefault={true}
                errorMsg={usernameError}
                testId="username-input"
              />
            </div>
            <div className="col-sm-12">
              <Select
                title="Role"
                elems={props.roles}
                selectedElem={role}
                onSelect={elem => setRole(elem.id)}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
