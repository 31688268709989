import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import { Permissions } from 'Models/Authorization';

type PermissionItem = keyof Permissions;

export type Config = {
  permissions: PermissionItem[];
  requiresAll?: boolean;
};

const selectPermissions = (state: RootState) => state.user.permissions;

/**
 * Computes if the connected user has the given permissions.
 * `requiresAll` from the configuration toggles between requiring an access
 * for all the given permission or only one of them.
 * @param config the hook configuration
 * @returns {boolean | undefined} hasAccess
 *  (undefined means the user permissions are not currently available, hence a decision was not made.)
 */
export function usePermissionGate(config: Config): boolean | undefined {
  const userPermissions = useSelector(selectPermissions, isEqual);
  const permissionKeys = Object.keys(userPermissions);
  const requiresAll = config.requiresAll !== undefined ? config.requiresAll : false;
  const { permissions } = config;

  if (permissions.length === 0) {
    console.warn(`No required permission has been specified.
      This is the equivalent of having access, it might not be the intended behavior.
    `);
  }

  if (
    permissionKeys.length === 0 ||
    permissionKeys.every(permission => userPermissions[permission] === undefined)
  ) {
    // the permissions have not been populated yet
    // access cannot be determined
    return undefined;
  }

  const hasAccess = requiresAll
    ? permissions.every(permission => userPermissions[permission as any] === true)
    : permissions.some(permission => userPermissions[permission as any] === true);

  if (hasAccess || permissions.length === 0) {
    return true;
  }

  return false;
}
