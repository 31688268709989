import { LeanApplicationArtifact } from 'Models/Application/ApplicationStore';
import React, { useState } from 'react';
import { ArtifactList } from './Presentation/ArtifactList';
import { SectionExpandTrigger } from 'Components/Util/Controls/SectionExpandTrigger';

type Props = {
  artifacts: LeanApplicationArtifact[];
  selectedVersion: string;
  selectVersion: (_: string) => void;
  installedVersion?: string;
  systemId: string;
};

export function ArtifactSelectorPanel(props: Props) {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <div>
      <SectionExpandTrigger
        isExpanded={isExpanded}
        onClick={setExpanded}
        text="Previous versions"
        testId="artifact-expand"
      />
      {isExpanded && (
        <ArtifactList
          artifacts={props.artifacts}
          selectedVersion={props.selectedVersion}
          selectArtifact={props.selectVersion}
          installedVersion={props.installedVersion}
          systemId={props.systemId}
        />
      )}
    </div>
  );
}
