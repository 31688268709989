import { fetchFirmwareUpgradeHistoryAction } from 'Actions/Device/FirmwareUpgradeHistoryActions';
import { FirmwareUpgradeHistory } from 'Models/Device/FirmwareUpgradeHistory';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { FirmwareUpgradeHistoryList } from './FirmwareUpgradeHistoryList';
import styles from './FirmwareUpgradeHistoryWrapper.module.css';
import FirmwareUpgradeHistoryHeader from './FirmwareUpgradeHistoryHeader';

type OwnProps = {
  serialNumber: string;
  systemId: string;
};
type Props = OwnProps & {
  fetchFirmwareUpgradeHistory: (systemId: string, serialNumber: string) => void;
  firmwareUpgradeHistory: FirmwareUpgradeHistory[];
  isLoading: boolean;
};

export function _FirmwareUpgradeHistoryWrapper(props: Props) {
  useEffect(() => {
    props.fetchFirmwareUpgradeHistory(props.systemId, props.serialNumber);
  }, [props.serialNumber, props.systemId]);

  return (
    <div id="content" className={styles.overflowAuto}>
      <FirmwareUpgradeHistoryHeader systemId={props.systemId} serialNumber={props.serialNumber} />
      <div className="row padding-left-md padding-right-md">
        <div className="col-lg-12">
          {!props.isLoading && (
            <FirmwareUpgradeHistoryList
              firmwareUpgradeHistory={props.firmwareUpgradeHistory}
              onRefresh={() =>
                props.fetchFirmwareUpgradeHistory(props.systemId, props.serialNumber)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFirmwareUpgradeHistory: (systemId: string, serialNumber: string) => {
    dispatch(fetchFirmwareUpgradeHistoryAction(systemId, serialNumber));
  },
});

function mapStateToProps(state: RootState) {
  return {
    firmwareUpgradeHistory: state.firmwareUpgradeHistory,
    isLoading: state.loading.length > 0,
  };
}
export const FirmwareUpgradeHistoryWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(_FirmwareUpgradeHistoryWrapper);
