export type PermissionModel = {
  permissionName: string;
  hasAccess: boolean;
};

export type Permissions = {
  CreateInvitations?: boolean;
  AssignRole?: boolean;
  ViewRoles?: boolean;
  UnassignUser?: boolean;
  ViewRolePrincipals?: boolean;
  ViewRolePermissions?: boolean;
  ViewPermissions?: boolean;
};

export const Roles = {
  Admin: 'admin',
  User: 'user',
  ApplicationManager: 'application_manager',
} as const;

export type Role = typeof Roles[keyof typeof Roles];

export const RoleLabels = new Map<string, string>(
  Object.keys(Roles).map(name => [Roles[name], name])
);

export type RolePermission = {
  name: string;
};

export type RolePrincipalsModel = {
  role: Role;
  principals: string[];
};

export type RolePermissionsMap = {
  [permissionSet: string]: RolePermission[];
};
