import {
  clearPendingNavigationAction,
  confirmNavigationAction,
} from 'Actions/Navigation/NavigationActions';
import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { ConfirmationModal } from './Modal';

type ConnectedNavigationModalProps = {
  cancel: () => void;
  confirm: () => void;
  isVisible: boolean;
};

function _ConnectedNavigationModal(props: ConnectedNavigationModalProps) {
  return (
    <ConfirmationModal
      onCancel={props.cancel}
      onConfirm={props.confirm}
      show={props.isVisible}
      text={'You have unsaved changes. Are you sure you want to leave the page?'}
    />
  );
}

function mapStateToProps(state: RootState) {
  return {
    isVisible: state.navigation.pendingNavigation !== '' && state.pendingChanges.dirty,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    cancel: () => dispatch(clearPendingNavigationAction()),
    confirm: () => dispatch(confirmNavigationAction()),
  };
}

export const ConnectedNavigationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ConnectedNavigationModal);
