import { CREATE_SERVICE_REGISTRATION, CREATE_USER_INVITATION } from 'Actions/ActionTypes';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { fetchUserListAction } from 'Actions/Users/UserActions';
import {
  CreateUserInvitationAction,
  CreateServiceRegistrationAction,
  fetchUserInvitationListAction,
} from 'Actions/Users/UserInvitationActions';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select } from 'redux-saga/effects';
import {
  createServiceRegistrationRequest,
  createUserInvitationRequest,
} from 'Services/RegistrationProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { withNavigationGate } from '../WithNavigationGate';

export function* createUserInvitationSaga(action: CreateUserInvitationAction) {
  yield put(trackFetchAction('createInvitation'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    yield call(createUserInvitationRequest, token, action.systemId, action.email, action.role);
    yield put(sendToastNotificationAction('success', 'The user was successfully invited.'));
    return action;
  } finally {
    yield put(clearFetchAction('createInvitation'));
  }
}

export function* createServiceRegistrationSaga(action: CreateServiceRegistrationAction) {
  yield put(trackFetchAction('createRegistration'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    yield call(
      createServiceRegistrationRequest,
      token,
      action.systemId,
      action.clientId,
      action.serviceName,
      action.role.replace(' ', '_')
    );
    yield put(sendToastNotificationAction('success', 'The service was successfully registered.'));
    return action;
  } finally {
    yield put(clearFetchAction('createRegistration'));
  }
}

export function* onContinuation(action: CreateUserInvitationAction) {
  yield put(fetchUserInvitationListAction(action.systemId));
}

export function* onServiceRegistrationContinuation(action: CreateServiceRegistrationAction) {
  yield put(fetchUserListAction(action.systemId));
}

export function* onError(e: any) {
  if (e instanceof ServiceCallerError) {
    yield put(sendToastNotificationAction('warning', e.message));
  } else {
    yield put(
      sendToastNotificationAction('warning', 'An error occurred while creating the invitation.')
    );
  }
}

export function* watchCreateUserInvitation() {
  yield withNavigationGate(
    CREATE_USER_INVITATION,
    createUserInvitationSaga,
    onContinuation,
    onError
  );
}

export function* watchCreateServiceRegistration() {
  yield withNavigationGate(
    CREATE_SERVICE_REGISTRATION,
    createServiceRegistrationSaga,
    onServiceRegistrationContinuation,
    onError
  );
}
