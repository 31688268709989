import {
  PROMPT_FOR_CONFIRMATION,
  _ACCEPT_CONFIRMATION,
  _CANCEL_CONFIRMATION,
} from 'Actions/ActionTypes';
import {
  _CancelConfirmationAction,
  PromptForConfirmationAction,
  _AcceptConfirmationAction,
} from 'Actions/Modal/ModalActions';

type ModalReducerState = {
  message: string;
  completionCallback: () => void;
  visible: boolean;
};

type ModalReducerActions =
  | PromptForConfirmationAction
  | _AcceptConfirmationAction
  | _CancelConfirmationAction;

const defaultState: ModalReducerState = {
  message: '',
  completionCallback: () => undefined,
  visible: false,
};

export function confirmationModalReducer(
  state: ModalReducerState = defaultState,
  action: ModalReducerActions
) {
  switch (action.type) {
    case PROMPT_FOR_CONFIRMATION:
      return { message: action.message, completionCallback: action.callback, visible: true };
    case _ACCEPT_CONFIRMATION:
      return defaultState;
    case _CANCEL_CONFIRMATION:
      return defaultState;
    default:
      return state;
  }
}
