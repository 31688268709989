import * as React from 'react';
import { Dispatch } from 'redux';
import { RootState } from 'Reducers/RootReducer';
import { clearPageErrorAction } from 'Actions/Notification/PageErrorActions';
import { StickyMsg } from './Notifications';
import { connect } from 'react-redux';

type StickyFetchErrorContainerProps = {
  error: string;
  clearError: () => void;
};

function InnerStickyFetchErrorContainer(props: StickyFetchErrorContainerProps) {
  return (
    <StickyMsg
      type="warning"
      msg={props.error}
      show={props.error !== ''}
      onClose={() => props.clearError()}
    />
  );
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    clearError: () => dispatch(clearPageErrorAction()),
  };
}

function mapStateToProps(state: RootState) {
  return {
    error: state.pageError,
  };
}

export const StickyFetchErrorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InnerStickyFetchErrorContainer);
