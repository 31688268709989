import { call, put, takeLatest } from 'redux-saga/effects';
import { GetRolePrincipalsResponse, getRolePrincipals } from 'Services/AuthorizationProxy';
import { FETCH_ROLE_PRINCIPALS } from 'Actions/ActionTypes';
import {
  receiveRolePrincipals,
  FetchRolePrincipalsAction,
} from 'Actions/Authorization/AuthorizationActions';
import { genericSaga } from '../GenericSaga';

export function* fetchRolePrincipalsSaga(action: FetchRolePrincipalsAction) {
  const config = {
    argsBuilder: (token: string): [string, string, string] => [
      token,
      action.systemId,
      action.roleName,
    ],
    fn: getRolePrincipals,
    loadingSectionName: 'fetchRolePrincipals',
    errorMessage: "An error occurred while fetching the role's users.",
  };

  const result: GetRolePrincipalsResponse = yield call(genericSaga, config);
  yield put(receiveRolePrincipals(result.response.principals));
}

export function* watchFetchRolePrincipalsSaga() {
  yield takeLatest(FETCH_ROLE_PRINCIPALS, fetchRolePrincipalsSaga);
}
