import { FETCH_AUTHENTICATED_USER } from 'Actions/ActionTypes';
import { setTokenAction } from 'Actions/AuthenticationActions';
import {
  endFetchUserAction,
  setAuthenticatedUserAction,
  setErrorFetchingSystems,
  setSystemsForUser,
} from 'Actions/User/UserActions';
import { AuthenticatedUser, authenticationService } from 'Authentication/AuthenticationService';
import { call, put } from 'redux-saga/effects';
import { fetchSystemsForUser, GetSystemsResponse } from 'Services/SystemUsersProxy';
import { cancelOnNavigationEffect } from './CancelOnNavigationSaga';

function getAuthenticatedUser(): Promise<AuthenticatedUser> {
  return authenticationService.getAuthenticatedUser();
}

export function* getUserSystemsSaga() {
  try {
    put(setErrorFetchingSystems(''));

    // Get authenticated user
    let response = yield call(getAuthenticatedUser);
    const authUser = response as AuthenticatedUser;

    yield put(setAuthenticatedUserAction(authUser));
    yield put(setTokenAction(authUser.bearerToken));

    if (!authUser.isAuthenticated) {
      return;
    }

    if (!authUser.id) {
      yield put(setErrorFetchingSystems('Error occured while fetching the systems of the user'));
      return;
    }

    // Get systems
    response = yield call(fetchSystemsForUser, authUser.bearerToken, authUser.id);
    const systems = (response as GetSystemsResponse).response.map(system => {
      return { id: system.id, name: system.systemName };
    });
    yield put(setSystemsForUser(systems));
  } catch (error) {
    yield put(setErrorFetchingSystems('Error occured while fetching the systems of the user'));
  } finally {
    yield put(endFetchUserAction());
  }
}

export function* watchGetUserSystemsSaga() {
  yield cancelOnNavigationEffect(FETCH_AUTHENTICATED_USER, getUserSystemsSaga);
}
