import { DeviceEnrollmentRequest } from './DeviceIdentityProxy';
import { DeviceEnrollmentFormReducerState } from 'Reducers/Form/DeviceEnrollmentFormReducer';

export function formToDeviceEnrollmentRequest(
  form: DeviceEnrollmentFormReducerState
): DeviceEnrollmentRequest {
  return {
    deviceName: form.deviceName.trim(),
    serialNumber: form.serialNumber.trim(),
    activationCode: form.activationCode.trim(),
  };
}
