import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { promptForConfirmationAction } from 'Actions/Modal/ModalActions';
import {
  fetchUserListAction,
  removeUserFromSystemAction,
  clearUsersAndInvitationsAction,
} from 'Actions/Users/UserActions';
import {
  deleteUserInvitationAction,
  fetchUserInvitationListAction,
  createUserInvitationAction,
  createServiceRegistrationAction,
} from 'Actions/Users/UserInvitationActions';
import { FlexPageContent } from 'Components/Util/FlexPageContent';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { UserInvitation } from 'Models/Users/UserInvitation';
import UserInvitationsList from './UserInvitationsList';
import ServicePrincipalsList from './ServicePrincipalsList';
import { LightPrincipal } from '../Types';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import UserInvitationDialog from '../InvitationDialog/UserInvitationDialog';
import ServiceRegistrationDialog from '../InvitationDialog/ServiceRegistrationDialog';
import { changeUserRoleAction } from 'Actions/User/UserActions';
import { RouteComponentProps } from 'react-router';
import { useFeatureFlagToggle } from 'Hooks/useFeatureFlagToggle';
import { fetchSystemFeatureFlagsAction } from 'Actions/FeatureFlags/FeatureFlagsActions';

type RouteMatch = {
  systemId: string;
};

type Props = RouteComponentProps<RouteMatch> & {
  fetchUsers: () => void;
  fetchFeatureFlags: (systemId: string) => void;
  deleteUser: (username: string, userId: string) => void;
  fetchInvitations: () => void;
  users: LightPrincipal[];
  services: LightPrincipal[];
  invitations: UserInvitation[];
  roles: { id: string; name: string }[];
  canInviteUsers: boolean;
  canAssignRoles: boolean;
  canRemoveUsers: boolean;
  canViewDetails: boolean;

  deleteUserInvitation: (username: string) => void;
  copyToClipboard: (text: string) => void;
  clearUsersAndInvitations: () => void;
  createUserInvitation: (username: string, role: string) => void;
  createServiceRegistration: (servicename: string, role: string, clientId: string) => void;
  changeUserRole: (username: string, userId: string, newRole: string) => void;
};

function _UsersListPage(props: Props) {
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const displayServicePrincipalsFeatureFlag = useFeatureFlagToggle(
    'DisplayExternalServicePrincipals'
  );

  useEffect(() => {
    fetchUsersAndInvitations();
    props.fetchFeatureFlags(props.match.params.systemId);
    return props.clearUsersAndInvitations;
  }, [props.match.params.systemId]);

  function fetchUsersAndInvitations(): void {
    props.fetchUsers();
    props.fetchInvitations();
  }

  return (
    <FlexPageContent>
      {showInvitationModal && (
        <UserInvitationDialog
          submitUserRegistration={props.createUserInvitation}
          roles={props.roles}
          onClose={() => setShowInvitationModal(false)}
        />
      )}
      {showRegistrationModal && (
        <ServiceRegistrationDialog
          submitServiceRegistration={props.createServiceRegistration}
          roles={props.roles}
          onClose={() => setShowRegistrationModal(false)}
        />
      )}
      <UserInvitationsList
        users={props.users}
        roles={props.roles}
        invitations={props.invitations}
        systemId={props.match.params.systemId}
        canInviteUsers={props.canInviteUsers}
        canAssignRoles={props.canAssignRoles}
        canRemoveUsers={props.canRemoveUsers}
        canViewDetails={props.canViewDetails}
        onInviteUserButtonClick={() => setShowInvitationModal(true)}
        onCopyToClipboardButtonClick={props.copyToClipboard}
        onRefreshButtonClick={fetchUsersAndInvitations}
        onDeleteUserInvitation={props.deleteUserInvitation}
        onDeleteUser={props.deleteUser}
        onChangeUserRole={props.changeUserRole}
      />
      {displayServicePrincipalsFeatureFlag && (
        <ServicePrincipalsList
          services={props.services}
          roles={props.roles}
          systemId={props.match.params.systemId}
          canInviteServices={props.canInviteUsers}
          canAssignRoles={props.canAssignRoles}
          canRemoveServices={props.canRemoveUsers}
          canViewDetails={props.canViewDetails}
          onRegisterServiceButtonClick={() => setShowRegistrationModal(true)}
          onCopyToClipboardButtonClick={props.copyToClipboard}
          onRefreshButtonClick={fetchUsersAndInvitations}
          onDeleteServiceInvitation={props.deleteUserInvitation}
          onDeleteService={props.deleteUser}
          onChangeServiceRole={props.changeUserRole}
        />
      )}
    </FlexPageContent>
  );
}

function mapStateToProps(state: RootState) {
  return {
    users: state.pages.users.users.filter(str => str.type !== 'service'),
    services: state.pages.users.users.filter(str => str.type === 'service'),
    roles: state.pages.users.roles,
    invitations: state.pages.users.invitations,
    canInviteUsers: state.user.permissions.CreateInvitations!,
    canAssignRoles: state.user.permissions.AssignRole!,
    canRemoveUsers: state.user.permissions.UnassignUser!,
    canViewDetails: state.user.permissions.ViewPermissions!,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: RouteComponentProps<RouteMatch>) {
  const {
    match: { params },
  } = ownProps;
  return {
    fetchUsers: () => dispatch(fetchUserListAction(params.systemId)),
    deleteUser: (username: string, userId: string) =>
      dispatch(
        promptForConfirmationAction(`Are you sure you wish to delete the user ${username}?`, () =>
          dispatch(removeUserFromSystemAction(params.systemId, userId))
        )
      ),
    fetchInvitations: () => dispatch(fetchUserInvitationListAction(params.systemId)),
    deleteUserInvitation: (username: string) =>
      dispatch(
        promptForConfirmationAction(
          `Are you sure you wish to delete the invitation to ${username}?`,
          () => dispatch(deleteUserInvitationAction(params.systemId, username))
        )
      ),
    fetchFeatureFlags: (systemId: string) => dispatch(fetchSystemFeatureFlagsAction(systemId)),
    copyToClipboard: (text: string) => {
      navigator.clipboard.writeText(text);
      dispatch(sendToastNotificationAction('info', 'Registration URL copied to clipboard'));
    },
    clearUsersAndInvitations: () => {
      dispatch(clearUsersAndInvitationsAction());
    },
    createUserInvitation: (username: string, role: string) => {
      dispatch(createUserInvitationAction(params.systemId, username, role));
    },
    createServiceRegistration: (servicename: string, role: string, clientId: string) => {
      dispatch(createServiceRegistrationAction(params.systemId, clientId, servicename, role));
    },
    changeUserRole: (username: string, userId: string, newRole: string) => {
      dispatch(
        promptForConfirmationAction(
          `Are you sure you wish to change the role of ${username} to ${newRole}?`,
          () => {
            dispatch(changeUserRoleAction(params.systemId, userId, newRole));
            dispatch(fetchUserListAction(params.systemId));
          }
        )
      );
    },
  };
}

export const UsersListPage = connect(mapStateToProps, mapDispatchToProps)(_UsersListPage);
