import { fetchAuthenticatedUserAction } from 'Actions/User/UserActions';
import * as React from 'react';
import { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Switch } from 'react-router-dom';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { triggerNavigationAction } from '../Actions/Navigation/NavigationActions';
import { AuthenticatedUser, authenticationService } from '../Authentication/AuthenticationService';
import { PrivateRoute } from './PrivateRoute';
import { PublicErrorPage } from './PublicErrorPage';
import { SystemSelectionLandingPage } from './System/SystemSelectionLandingPage';
import { ToastNotificationContainer } from './ToastNotificationContainer';
import TopNav from './Topnav/TopNav';
import { LeavePagePrompt } from './Util/Browser/LeavePagePrompt';
import { ConnectedNavigationModal } from './Util/ConnectedNavigationModal';
import { ConnectedLoading } from './Util/Loading';
import { RoutingUpdater } from './Util/RoutingUpdater';
import { StickyFetchErrorContainer } from './Util/StickyFetchErrorContainer';
import { SystemPathRouter } from './System/SystemPathRouter';
import { ConnectedConfirmationModal } from './Util/Interaction/ConnectedConfirmationModal';

type PrivateAppProps = RouteComponentProps & {
  errorFetchingSystem: boolean;
  authUser?: AuthenticatedUser;
  systems?: { id: string; name: string }[];
  loginError: string;
  changeSystem: (newId: string) => void;
  isAuthenticationFlowCompleted: boolean;
  onLoad: () => void;
  userHasSystems: boolean;
};

function _PrivateApp(props: PrivateAppProps) {
  useEffect(() => {
    props.onLoad();
  }, []);

  if (props.loginError) {
    return <PublicErrorPage />;
  }

  if (!props.authUser || !props.isAuthenticationFlowCompleted) {
    return null;
  }

  if (!props.authUser.isAuthenticated) {
    authenticationService.redirectToLogin(props.location.pathname);
    return null;
  }

  return (
    <Fragment>
      <RoutingUpdater {...props} />
      <ConnectedNavigationModal />
      <ConnectedConfirmationModal />
      <ToastNotificationContainer />
      <LeavePagePrompt />
      <ConnectedLoading />
      <StickyFetchErrorContainer />

      <div id="wrapper">
        {!props.userHasSystems ? (
          <Fragment>
            <TopNav systemId="" showUsersPage={false} showRolesPage={false} />
            <div id="content">
              <div className={'alert alert-danger'}>
                {props.errorFetchingSystem
                  ? 'There was an error when retrieving your systems. Please try again later.'
                  : 'There is no system ID associated with your user account.'}
              </div>
            </div>
          </Fragment>
        ) : (
          <Switch>
            <PrivateRoute exact path="/" component={SystemSelectionLandingPage} />
            <PrivateRoute path="/systems/:systemId" component={SystemPathRouter} />
          </Switch>
        )}
      </div>
    </Fragment>
  );
}

function mapStateToProps(state: RootState) {
  return {
    errorFetchingSystem: state.user.systemsError !== '',
    authUser: state.user.authUser,
    systems: state.user.systems,
    loginError: state.user.loginError,
    isAuthenticationFlowCompleted: state.user.isAuthenticationFlowCompleted,
    userHasSystems: state.user.systems.length > 0,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    changeSystem: (systemId: string) => {
      dispatch(triggerNavigationAction(`/systems/${systemId}`));
    },

    onLoad: () => dispatch(fetchAuthenticatedUserAction()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(_PrivateApp);
