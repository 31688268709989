import { AnyAction, Reducer } from 'redux';

type ActionSetter<T> = {
  stateField: keyof T | 'Root';
  actionValueField: string;
};

type ConstantSetter<T> = {
  stateField: keyof T | 'Root';
  constantValue: any;
};

type ReducerSetter<T> = ActionSetter<T> | ConstantSetter<T>;

interface ReducerConfigMap<T> {
  initialState: T;
  setters: { [key: string]: ReducerSetter<T> };
}

function isConstantSetter<T>(setter: ReducerSetter<T>): setter is ConstantSetter<T> {
  return (setter as ConstantSetter<T>).constantValue !== undefined;
}

/**
 * Generates the boilerplate for a classic redux reducer
 * @param config the reducer configuration
 */
export function makeReducer<TState>(config: ReducerConfigMap<TState>): Reducer<TState, AnyAction> {
  return function reducer(state: TState = config.initialState, action: AnyAction) {
    const {
      setters: { [action.type]: setterConfig },
    } = config;

    if (setterConfig === undefined) {
      return state;
    }

    const value = isConstantSetter(setterConfig)
      ? setterConfig.constantValue
      : action[setterConfig.actionValueField];

    if (setterConfig.stateField === 'Root') {
      return value;
    }

    return { ...state, [setterConfig.stateField]: value };
  };
}
