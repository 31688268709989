import { put, takeLatest, call } from 'redux-saga/effects';
import { GetRolePermissionsResponse, getRolePermissions } from 'Services/AuthorizationProxy';
import { FETCH_ROLE_PERMISSIONS } from 'Actions/ActionTypes';
import {
  receiveRolePermissions,
  FetchRolePermissionsAction,
} from 'Actions/Authorization/AuthorizationActions';
import { genericSaga } from '../GenericSaga';

export function* fetchRolePermissionsSaga(action: FetchRolePermissionsAction) {
  const config = {
    argsBuilder: (token: string): [string, string, string] => [
      token,
      action.systemId,
      action.roleName,
    ],
    fn: getRolePermissions,
    loadingSectionName: 'fetchRolePermissions',
    errorMessage: "An error occurred while fetching the role's permissions.",
  };

  const apiResponse: GetRolePermissionsResponse = yield call(genericSaga, config);
  formatPermissions(apiResponse);
  yield put(receiveRolePermissions(apiResponse.response));
}

const formatPermissions = (rolePermissions: GetRolePermissionsResponse) => {
  for (const [, value] of Object.entries(rolePermissions.response)) {
    value.forEach(
      permission => (permission.name = permission.name.replace(/([A-Z])/g, ' $1').trim())
    );
  }
};

export function* watchFetchRolePermissionsSaga() {
  yield takeLatest(FETCH_ROLE_PERMISSIONS, fetchRolePermissionsSaga);
}
