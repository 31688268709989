import * as _ from 'lodash';
import * as React from 'react';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import '../ClientPortal.css';

type SelectProps = {
  elems: { id: string; name: string }[];
  selectedElem?: string;
  onSelect: (elem: { id: string; name: string }) => void;
  createElemTxt?: string;
  createElemLink?: string;
  onCreateElementClick?: () => void;
  title?: string;
};

export function Select(props: SelectProps) {
  const idSelected = props.selectedElem ? props.selectedElem : '';
  const title = elementNameForId(idSelected);

  function elementNameForId(elementId: string): string {
    return (
      _.chain(props.elems)
        .filter(x => x.id === elementId)
        .map(x => x.name)
        .first()
        .value() || elementId
    );
  }

  return (
    <Fragment>
      {props.title && <label>{props.title}</label>}

      <div className="client-portal-select btn-group bootstrap-select form-control">
        <button className="btn dropdown-toggle btn-list" type="button" data-toggle="dropdown">
          <span className="filter-option pull-left">{title}</span>
          <span className="caret" />
        </button>
        <div className="dropdown-menu open">
          <ul className="dropdown-menu inner" role="menu">
            {props.elems.map(e => (
              <li
                className={e.id === idSelected ? 'Selected' : ''}
                key={e.id}
                onClick={() => props.onSelect(e)}
              >
                <a>
                  <span className="text">{e.name}</span>
                </a>
              </li>
            ))}
          </ul>

          {props.createElemLink && (
            <Fragment>
              <div className="divider" data-optgroup="2div" />
              <div className="footer">
                <NavLink to={props.createElemLink}>
                  <span>{props.createElemTxt}</span>
                </NavLink>
              </div>
            </Fragment>
          )}

          {!props.createElemLink && props.onCreateElementClick && (
            <Fragment>
              <div className="divider" data-optgroup="2div" />
              <div className="footer">
                <a onClick={props.onCreateElementClick}>
                  <span>{props.createElemTxt}</span>
                </a>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}

type DisabledSelectProps = {
  msg: string;
};

export function DisabledSelect(props: DisabledSelectProps) {
  return (
    <div className="client-portal-select btn-group bootstrap-select form-control">
      <button className="btn dropdown-toggle btn-list" type="button" data-toggle="dropdown">
        <span className="filter-option pull-left" />
        <span className="caret" />
      </button>
      <div className="dropdown-menu open">
        <ul className="dropdown-menu inner" role="menu">
          <li className="dropdown-header">{props.msg}</li>
        </ul>
      </div>
    </div>
  );
}
