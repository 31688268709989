import React, { useState } from 'react';
import { ActionButtonWithConfirmationModal } from '../Util/Controls/ActionButtonWithConfirmationModal';
import { CheckBox } from '../Util/Controls/CheckBox';
import {
  CreateDeviceJob,
  FactoryResetDeviceJob,
  JobType,
} from '../../Models/DeviceJobs/DeviceJobs';
import styles from './Buttons.module.css';

type Props = {
  serialNumber: string;
  systemId: string;
  disabled: boolean;
  createDeviceJob: (systemId: string, serialNumber: string, deviceJob: CreateDeviceJob) => void;
};

export function FactoryResetButton(props: Props) {
  const [keepNetworking, setKeepNetworking] = useState(false);

  function clear() {
    setKeepNetworking(false);
  }

  function onConfirm() {
    const job: FactoryResetDeviceJob = {
      type: JobType.FactoryReset,
      body: {
        keepNetworkSettings: keepNetworking,
      },
    };

    props.createDeviceJob(props.systemId, props.serialNumber, job);
    clear();
  }

  return (
    <ActionButtonWithConfirmationModal
      actionButtonClassName={`${props.disabled ? styles.buttonDisabled : styles.buttonError}`}
      modalButtonClassName="btn btn-danger"
      onConfirm={onConfirm}
      onCancel={clear}
      yesText="Reset device"
      title={`Reset device ${props.serialNumber} to factory defaults`}
      text="Factory Reset"
      disabled={props.disabled}
      testId="factory-reset-button"
    >
      <div>
        <p>The device will be reset to its factory settings.</p>
        <p>
          <b>Note</b>: The device will automatically reboot after this operation.
        </p>
        <CheckBox
          id="chk1"
          label="Preserve the device network configuration"
          testId="checkbox-keep-networking"
          checked={keepNetworking}
          onChange={setKeepNetworking}
        />
      </div>
    </ActionButtonWithConfirmationModal>
  );
}
