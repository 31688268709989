import { createLiteralTypeCaster } from 'Utilities/TypeScriptUtils';

export type ApplicationIdentity = {
  id: string;
  displayName: string;
  description: string;
  latestRelease: string;
  stage: AppStage;
};

export type LeanApplicationArtifact = {
  version: string;
  description: string;
  stage: ArtifactStage;
};

export type ApplicationArtifact = {
  version: string;
  description: string;
  stage: ArtifactStage;
  resources: DeployableResource[];
};

export type DeployableResource = {
  name: string;
  displayName: string;
  parameters: ApplicationArtifactParameter[];
};

export type ApplicationArtifactParameter = {
  scopedKey: string;
  displayName: string;
  description: string;
  defaultValue?: string;
};

export type InstallableApplicationDetails = {
  id: string;
  displayName: string;
  description: string;
  latestRelease: string;
  latestInstallableVersion: string;
  latestInstallableVersionStage: ArtifactStage;
  artifacts: LeanApplicationArtifact[];
};

const artifactStageValues = [
  'development',
  'stable',
  'publishing',
  'error_publishing',
  'review',
  'release',
  'deleted',
  'unknown',
] as const;
export type ArtifactStage = typeof artifactStageValues[number];
export const toArtifactStage = createLiteralTypeCaster<ArtifactStage>(
  artifactStageValues,
  'unknown'
);

const appStageValues = ['development', 'production', 'unknown'] as const;
export type AppStage = typeof appStageValues[number];
export const toAppStage = createLiteralTypeCaster<AppStage>(appStageValues, 'unknown');
