import { combineReducers } from 'redux';
import { formReducer } from './Form/FormReducer';
import { userReducer } from './User/UserReducer';
import { systemReducer } from './System/SystemReducer';
import { deviceReducer } from './Device/DeviceReducer';
import { loadingReducer } from './Loading/LoadingReducer';
import { deviceJobReducer } from './DeviceJobs/DeviceJobReducer';
import { deviceListReducer } from './Device/DeviceListReducer';
import { pageErrorReducer } from './Notification/PageErrorReducer';
import { navigationReducer } from './Navigation/NavigationReducer';
import { applicationReducer } from './Application/ApplicationReducer';
import { pendingChangesReducer } from './Changes/PendingChangesReducer';
import { notificationReducer } from './Notification/NotificationReducer';
import { applicationListReducer } from './Application/ApplicationListReducer';
import { authenticationReducer } from './Authentication/AuthenticationReducer';
import { confirmationModalReducer } from './Modal/ConfirmationModalReducer';
import { pagesReducer } from './Pages/PagesReducer';
import { featureFlagReducer } from './FeatureFlags/FeatureFlagsReducer';
import { rolesReducer } from './Authorization/RolesReducer';
import { firmwareImagesReducer } from './Device/FirmwareImageReducer';
import { firmwareUpgradeHistoryReducer } from './Device/FirmwareUpgradeHistoryReducer';

export const rootReducer = combineReducers({
  notifications: notificationReducer,
  pageError: pageErrorReducer,
  loading: loadingReducer,
  authentication: authenticationReducer,
  navigation: navigationReducer,
  pendingChanges: pendingChangesReducer,
  deviceList: deviceListReducer,
  device: deviceReducer,
  firmwareUpgradeHistory: firmwareUpgradeHistoryReducer,
  deviceJobs: deviceJobReducer,
  form: formReducer,
  user: userReducer,
  system: systemReducer,
  applicationList: applicationListReducer,
  application: applicationReducer,
  confirmationModal: confirmationModalReducer,
  pages: pagesReducer,
  featureFlags: featureFlagReducer,
  roles: rolesReducer,
  firmwareImages: firmwareImagesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
