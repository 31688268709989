import {
  FETCH_USER_LIST,
  REMOVE_USER_FROM_SYSTEM,
  RECEIVE_USER_LIST_FROM_API,
  CLEAR_USERS_AND_INVITATIONS,
  FETCH_USER_DETAILS,
  CLEAR_USER_DETAILS,
  RECEIVE_USER_DETAILS,
  RECEIVE_ROLE_PRINCIPALS,
} from 'Actions/ActionTypes';
import { LightPrincipal } from 'Components/Users/Types';
import { UserDetails } from 'Models/Users/UserDetails';

export type FetchUserListAction = {
  type: typeof FETCH_USER_LIST;
  systemId: string;
};

export function fetchUserListAction(systemId: string): FetchUserListAction {
  return {
    type: FETCH_USER_LIST,
    systemId,
  };
}

export type RemoveUserFromSystemAction = {
  type: typeof REMOVE_USER_FROM_SYSTEM;
  systemId: string;
  userId: string;
};

export function removeUserFromSystemAction(
  systemId: string,
  userId: string
): RemoveUserFromSystemAction {
  return {
    type: REMOVE_USER_FROM_SYSTEM,
    systemId,
    userId,
  };
}

export type ReceiveUserListFromApiAction = {
  type: typeof RECEIVE_USER_LIST_FROM_API;
  users: LightPrincipal[];
};

export function receiveUserListFromApiAction(
  users: LightPrincipal[]
): ReceiveUserListFromApiAction {
  return {
    type: RECEIVE_USER_LIST_FROM_API,
    users,
  };
}

export type ReceiveRolesForSystemAction = {
  type: typeof RECEIVE_ROLE_PRINCIPALS;
  roles: { id: string; name: string }[];
};

export function receiveRolesForSystemAction(
  roles: { id: string; name: string }[]
): ReceiveRolesForSystemAction {
  return {
    type: RECEIVE_ROLE_PRINCIPALS,
    roles,
  };
}

export type ClearUsersAndInvitationsAction = {
  type: typeof CLEAR_USERS_AND_INVITATIONS;
};

export function clearUsersAndInvitationsAction() {
  return {
    type: CLEAR_USERS_AND_INVITATIONS,
  };
}

export type ClearUserDetailsAction = {
  type: typeof CLEAR_USER_DETAILS;
};

export function clearUsersDetailsAction() {
  return {
    type: CLEAR_USER_DETAILS,
  };
}

export type FetchUserDetailsAction = {
  type: typeof FETCH_USER_DETAILS;
  systemId: string;
  userId: string;
};

export function fetchUserDetailsAction(systemId: string, userId: string): FetchUserDetailsAction {
  return {
    type: FETCH_USER_DETAILS,
    systemId,
    userId,
  };
}

export type ReceiveUserDetailsAction = {
  type: typeof RECEIVE_USER_DETAILS;
  userDetails: UserDetails;
};

export function receiveUserDetailsAction(userDetails: UserDetails): ReceiveUserDetailsAction {
  return {
    type: RECEIVE_USER_DETAILS,
    userDetails,
  };
}
