import {
  ReceiveApplicationAction,
  ClearApplicationAction,
} from 'Actions/Application/ApplicationActions';
import { InstallableApplicationDetails } from 'Models/Application/ApplicationStore';
import { RECEIVE_APPLICATION, CLEAR_APPLICATION } from 'Actions/ActionTypes';

type ApplicationReducerState = InstallableApplicationDetails | null;

type ApplicationReducerActions = ReceiveApplicationAction | ClearApplicationAction;

const defaultState: ApplicationReducerState = null;

export function applicationReducer(
  state: ApplicationReducerState = defaultState,
  action: ApplicationReducerActions
) {
  switch (action.type) {
    case RECEIVE_APPLICATION:
      return action.application;
    case CLEAR_APPLICATION:
      return defaultState;
    default:
      return state;
  }
}
