import { TrackFetchAction, ClearFetchAction } from 'Actions/Loading/LoadingActions';
import { TRACK_FETCH, CLEAR_FETCH } from 'Actions/ActionTypes';

type LoadingState = string[];
type LoadingReducerActions = TrackFetchAction | ClearFetchAction;

export function loadingReducer(state: LoadingState = [], action: LoadingReducerActions) {
  switch (action.type) {
    case TRACK_FETCH:
      return state.concat(action.name);
    case CLEAR_FETCH:
      const copy = [...state];
      const toDelete = copy.findIndex(t => t === action.name);
      if (toDelete !== -1) {
        copy.splice(toDelete, 1);
      }
      return copy;
  }
  return state;
}
