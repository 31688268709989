import React, { useEffect, useState } from 'react';
import styles from './ApplicationImage.module.css';
import {
  ApplicationDeploymentDetails,
  DeploymentStatus,
} from '../../../Models/Application/ApplicationDeployment';
import Spinner from '../../Util/Spinner';
import Popover from 'Components/Util/Popover';

type Props = {
  text: string;
  deployment: ApplicationDeploymentDetails;
};

export function InstalledApplicationPlaceholder(props: Props) {
  const [errorCount, setErrorCount] = useState(
    props.deployment.deployedResources.filter(
      r => r.type === 'container' && r.workloadStatus === 'error'
    ).length
  );

  const [stoppedCount, setStoppedCount] = useState(
    props.deployment.deployedResources.filter(
      r => r.type === 'container' && r.workloadStatus === 'stopped'
    ).length
  );

  const [transientCount, setTransientCount] = useState(
    props.deployment.deployedResources.filter(
      r =>
        r.type === 'container' &&
        (r.workloadStatus === 'starting' || r.workloadStatus === 'stopping')
    ).length
  );

  useEffect(() => {
    setErrorCount(
      props.deployment.deployedResources.filter(
        r => r.type === 'container' && r.workloadStatus === 'error'
      ).length
    );
    setStoppedCount(
      props.deployment.deployedResources.filter(
        r => r.type === 'container' && r.workloadStatus === 'stopped'
      ).length
    );
    setTransientCount(
      props.deployment.deployedResources.filter(
        r =>
          r.type === 'container' &&
          (r.workloadStatus === 'starting' || r.workloadStatus === 'stopping')
      ).length
    );
  }, [props.deployment.deployedResources]);

  return (
    <Popover
      disabled={!shouldDisplayDetails(props.deployment, errorCount, stoppedCount)}
      placement="right"
      content={
        <ul>
          {props.deployment.deploymentStatusReason && (
            <li>{props.deployment.deploymentStatusReason}</li>
          )}
          {errorCount > 0 && (
            <li>
              The following resources are in error:
              <ul>
                {props.deployment.deployedResources
                  .filter(r => r.type === 'container' && r.workloadStatus === 'error')
                  .map(r => {
                    return <li key={`${r.type}-${r.name}`}>{r.displayName}</li>;
                  })}
              </ul>
            </li>
          )}
          {stoppedCount > 0 && (
            <li>
              The following resources are stopped:
              <ul>
                {props.deployment.deployedResources
                  .filter(r => r.type === 'container' && r.workloadStatus === 'stopped')
                  .map(r => {
                    return <li key={`${r.type}-${r.name}`}>{r.displayName}</li>;
                  })}
              </ul>
            </li>
          )}
        </ul>
      }
    >
      <div
        className={`${styles.appImagePlaceholderBase} ${getPlaceholderStyleClass(
          props.deployment.deploymentStatus,
          transientCount,
          stoppedCount,
          errorCount
        )}`}
      >
        {props.text}
        <div className={styles.appTextStatus}>
          {getStatusContent(
            props.deployment.deploymentStatus,
            transientCount,
            stoppedCount,
            errorCount
          )}
        </div>
      </div>
    </Popover>
  );
}

function shouldDisplayDetails(
  deployment: ApplicationDeploymentDetails,
  errorCount: number,
  stoppedCount: number
) {
  return (
    errorCount + stoppedCount > 0 ||
    ((deployment.deploymentStatus === 'error_deploying' ||
      deployment.deploymentStatus === 'error_removing') &&
      deployment.deploymentStatusReason)
  );
}

function getPlaceholderStyleClass(
  deploymentStatus: DeploymentStatus,
  transientCount: number,
  warningCount: number,
  errorCount: number
) {
  switch (deploymentStatus) {
    case 'error_deploying':
    case 'error_download_authentication':
    case 'error_removing':
      return styles.appImageError;
    case 'pending':
    case 'deploying':
      return styles.appImageDeploying;
    case 'pending_removal':
    case 'removing':
      return styles.appImageRemoving;
    case 'deployed':
      if (errorCount > 0) return styles.appImageError;
      else if (warningCount > 0) return styles.appImageWarning;
      else if (transientCount > 0) return styles.appImageDeploying;
      else return styles.appImageDeployed;
    default:
      return styles.appImageUnknown;
  }
}

function getStatusContent(
  deploymentStatus: DeploymentStatus,
  transientCount: number,
  warningCount: number,
  errorCount: number
) {
  switch (deploymentStatus) {
    case 'deploying':
      return <Spinner />;
    case 'deployed':
      if (transientCount > 0) return <Spinner />;
      else {
        if (errorCount > 0) return <i>Execution error</i>;
        else if (warningCount > 0) return <i>Warning</i>;
        else return '';
      }
    default:
      return <i>{FORMATTED_STATUSES[deploymentStatus]}</i>;
  }
}

const FORMATTED_STATUSES = {
  none: '???',
  unknown: 'Unknown',
  pending: 'Pending...',
  deploying: 'Installing...',
  error_deploying: 'Error installing',
  error_download_authentication: 'Download error (authentication)',
  pending_removal: 'Pending removal...',
  removing: 'Removing',
  error_removing: 'Error removing',
};
