import React, { Fragment } from 'react';
import { DeviceEnrollmentModal } from './Enrollment/DeviceEnrollmentModal';
import { DeviceRow } from './Enrollment/DeviceRow';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import ReactTooltip from 'react-tooltip';

type Props = {
  systemId: string;
  devices: EnrolledDevice[];
  deleteDevice: (serialNumber: string) => void;
  refresh: () => void;
  goToDeviceDetails: (serialNumber: string) => void;
};

export function DeviceList(props: Props) {
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="client-portal-table-header">
            <h2>Devices ({props.devices.length})</h2>

            <div className="pull-right">
              <a data-tip="Refresh" onClick={props.refresh}>
                <i className="ico-sc-refresh" />
              </a>
              <DeviceEnrollmentModal systemId={props.systemId} />
              <ReactTooltip />
            </div>
          </div>

          <table className="client-portal-table table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Serial Number</th>
                <th>Enrollment Status</th>
                <th>Connection Status</th>
                <th>Last Update Time</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.devices.map(device => (
                <DeviceRow
                  device={device}
                  key={device.serialNumber}
                  deleteDevice={() => props.deleteDevice(device.serialNumber)}
                  goToDeviceDetails={() => props.goToDeviceDetails(device.serialNumber)}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
}
