import {
  END_FETCH_SYSTEM,
  SET_CURRENT_SYSTEM,
  SET_CURRENT_SYSTEM_ERROR,
} from 'Actions/ActionTypes';
import { SystemActions } from 'Actions/System/SystemActions';
import { SystemInfo } from 'Models/System/System';

export type SystemReducerState = {
  info: SystemInfo | null;
  infoError?: string;
  infoRetrieved: boolean;
};

const emptyState = {
  info: null,
  infoError: '',
  infoRetrieved: false,
};

export function systemReducer(state: SystemReducerState = emptyState, action: SystemActions) {
  switch (action.type) {
    case SET_CURRENT_SYSTEM:
      return { ...state, info: action.system };
    case END_FETCH_SYSTEM:
      return { ...state, infoRetrieved: true };
    case SET_CURRENT_SYSTEM_ERROR:
      return { ...state, infoError: action.error };
    default:
      return state;
  }
}
