import React, { Fragment, ReactElement } from 'react';

type Props = {
  children: ReactElement<PageProps>[];
  page: string;
};

export function PageSwitch(props: Props) {
  return (
    <Fragment>
      {React.Children.map(props.children, child =>
        React.cloneElement(child, { _visible: child.props.name === props.page })
      )}
    </Fragment>
  );
}

type PageProps = {
  name: string;
  _visible?: boolean;
  children: any;
};

export function Page(props: PageProps) {
  if (props._visible) {
    return props.children;
  }
  return <Fragment />;
}
