import React from 'react';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import { RebootDeviceButton } from '../../DeviceJobs/RebootDeviceButton';
import { FactoryResetButton } from 'Components/DeviceJobs/FactoryResetButton';
import { CreateDeviceJob } from 'Models/DeviceJobs/DeviceJobs';
import styles from './DeviceSummary.module.css';

type Props = {
  device: EnrolledDevice | null;
  isFirmwareUpgradeOngoing: boolean;
  createDeviceJob: (systemId: string, serialNumber: string, job: CreateDeviceJob) => void;
};

export function DeviceSummaryActions({ device, createDeviceJob, isFirmwareUpgradeOngoing }: Props) {
  return (
    <div data-testid="device-summary-actions-component">
      <div className={styles.otherHeaders}>
        <h2>Actions</h2>
      </div>
      <div className={styles.flexHorizontal}>
        {device && (
          <div>
            <RebootDeviceButton
              serialNumber={device.serialNumber}
              systemId={device.systemId}
              disabled={device.connectionStatus === 'not_connected' || isFirmwareUpgradeOngoing}
            />
          </div>
        )}
        {device && (
          <div>
            <FactoryResetButton
              serialNumber={device.serialNumber}
              systemId={device.systemId}
              disabled={device.connectionStatus === 'not_connected' || isFirmwareUpgradeOngoing}
              createDeviceJob={createDeviceJob}
            />
          </div>
        )}
      </div>
    </div>
  );
}
