import React from 'react';
import { ConnectionStatusComponent } from '../ConnectionStatus';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import { EnrollmentStateComponent } from '../EnrollmentStateComponent';
import { SystemInfo } from 'Models/System/System';
import { ToolingLevel } from '../ToolingLevel';
import { FirmwareInfo } from 'Models/Device/FirmwareInfo';
import styles from './DeviceSummary.module.css';

type Props = {
  time: string | undefined;
  systemInfo: SystemInfo | null;
  device: EnrolledDevice | null;
  firmwareInfo: FirmwareInfo | null;
};

export function DeviceSummaryGeneralComponent({
  systemInfo: info,
  device,
  firmwareInfo,
  time: time,
}: Props) {
  return (
    <div data-testid="device-summary-general">
      <div className={styles.generalHeader}>
        <h2>General</h2>
      </div>
      <div className="row">
        <div className="col-lg-2 noWrap">
          <label>Product Code</label>
          <div>{device ? device.productCode : <br />}</div>
        </div>
        <div className="col-lg-2 noWrap">
          <label>Connection Status</label>
          <div>
            {device ? <ConnectionStatusComponent status={device.connectionStatus} /> : <br />}
          </div>
        </div>
        <div className="col-lg-3 noWrap">
          <label>Last Connection</label>
          <div>
            {device === null || device.lastConnectionStatusUpdate === undefined ? (
              <br />
            ) : (
              device.lastConnectionStatusUpdate.toUTCString()
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2 noWrap">
          <label>Serial Number</label>
          <div>{device ? device.serialNumber : <br />}</div>
        </div>
        <div className="col-lg-2 noWrap">
          <label>Enrollment Status</label>
          <div>{device ? <EnrollmentStateComponent state={device.enrollmentState} /> : <br />}</div>
        </div>

        <div className="col-lg-2 noWrap">
          <label>Enrollment Time</label>
          <div>{device ? device.enrollmentTime.toUTCString() : <br />}</div>
        </div>
        <div className="col-lg-2 noWrap">
          <label>Time Zone</label>
          <div>{time ? time : 'N/A'}</div>
        </div>
      </div>
      {firmwareInfo && (
        <div className="row">
          <div className="col-lg-2 noWrap">
            <label>Flavor</label>
            <div>{getFlavor(firmwareInfo) ? getFlavor(firmwareInfo) : <br />}</div>
          </div>
          <div className="col-lg-2 noWrap">
            <label>Device Class</label>
            <div>{getDeviceClass(firmwareInfo) ? getDeviceClass(firmwareInfo) : <br />}</div>
          </div>
          <ToolingLevel infoType={info && info.type} toolingLevel={getToolingLevel(firmwareInfo)} />
          <div className="col-lg-2 noWrap">
            <label>CPU Architecture</label>
            <div>{getCpuArch(firmwareInfo) ? getCpuArch(firmwareInfo) : <br />}</div>
          </div>
        </div>
      )}
    </div>
  );
}

function getDeviceClass(firmwareInfo: FirmwareInfo | null): string | null {
  return firmwareInfo && firmwareInfo.deviceClass;
}

function getFlavor(firmwareInfo: FirmwareInfo | null): string | null {
  return firmwareInfo && firmwareInfo.flavor;
}

function getCpuArch(firmwareInfo: FirmwareInfo | null): string | null {
  return firmwareInfo && firmwareInfo.cpuArch;
}

function getToolingLevel(firmwareInfo: FirmwareInfo | null): string | null {
  return firmwareInfo && firmwareInfo.toolingLevel;
}
