import { useState } from 'react';
import * as React from 'react';
import Modal from '../Util/Modal';
import '../ClientPortal.css';

type SystemSelectorProps = {
  show: boolean;
  systems: { id: string; name: string }[];
  systemId: string;
  onCancel?: () => void;
  onSelectSystem: (id: string) => void;
};

export function SystemSelectorModal(props: SystemSelectorProps) {
  const [filter, setFilter] = useState('');

  function selectSystem(systemId: string) {
    props.onSelectSystem(systemId);
    setFilter('');
  }

  function cancel() {
    if (props.onCancel) {
      props.onCancel();
    }
    setFilter('');
  }

  return (
    <Modal title="Choose a system" show={props.show} showFooter={false} onCancel={cancel}>
      <form className="form-horizontal row" onSubmit={e => e.preventDefault()}>
        <div className="form-group">
          <div className="col-lg-12 no-margin-top">
            <div className="input-search">
              <input
                className="form-control"
                type="text"
                placeholder="Search"
                value={filter}
                autoFocus={true}
                onChange={e => setFilter(e.target.value)}
              />
              <span className="ico-search">
                <i className="ico-sc-search" />
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="col-lg-12 no-margin-top">
            <div className="client-portal-table limit-table-height">
              <table className="table">
                <tbody>
                  {props
                    .systems!.filter(system =>
                      system.name.toLowerCase().match(filter.toLowerCase())
                    )
                    .map(system => {
                      return (
                        <tr
                          key={system.id}
                          className={system.id === props.systemId ? 'disabled' : ''}
                          onClick={() => selectSystem(system.id)}
                        >
                          <td>{system.name}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
