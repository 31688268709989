import {
  CreateDeviceJobAction,
  ReceiveDeviceJobAction,
} from '../../Actions/DeviceJobs/DeviceJobsActions';
import { DeviceJob, JobStatus, JobType } from '../../Models/DeviceJobs/DeviceJobs';
import { CREATE_DEVICE_JOB, RECEIVE_DEVICE_JOB } from '../../Actions/ActionTypes';

type DeviceJobAction = CreateDeviceJobAction | ReceiveDeviceJobAction;

const emptyJob: DeviceJob = {
  deviceSerialNumber: '',
  systemId: '',
  reason: '',
  type: JobType.None,
  status: JobStatus.None,
};

export function deviceJobReducer(state: any = emptyJob, action: DeviceJobAction) {
  switch (action.type) {
    case CREATE_DEVICE_JOB:
      return { ...action.deviceJob, jobStatus: JobStatus.Pending, reason: '' };
    case RECEIVE_DEVICE_JOB:
      return { ...action.deviceJob };

    default:
      return state;
  }
}
