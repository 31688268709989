import * as React from 'react';

export default function Spinner(props: any): React.ReactElement<any> {
  return (
    <div className="spin">
      <div className="spinner">
        <div className="spinner-container sc1">
          <div className="circle1" />
          <div className="circle2" />
          <div className="circle3" />
          <div className="circle4" />
        </div>
        <div className="spinner-container sc2">
          <div className="circle1" />
          <div className="circle2" />
          <div className="circle3" />
          <div className="circle4" />
        </div>
        <div className="spinner-container sc3">
          <div className="circle1" />
          <div className="circle2" />
          <div className="circle3" />
          <div className="circle4" />
        </div>
      </div>
      {props.children}
    </div>
  );
}
