import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import { DELETE_DEVICE } from 'Actions/ActionTypes';
import { DeleteDeviceAction } from 'Actions/Device/DeviceListActions';
import { trackFetchAction, clearFetchAction } from 'Actions/Loading/LoadingActions';
import { select, put, call } from 'redux-saga/effects';
import { RootState } from 'Reducers/RootReducer';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { deleteDeviceEnrollmentRequest } from 'Services/Device/DeviceIdentityProxy';
import { ServiceCallerError } from '../../Services/ServiceCaller';

export function* deleteDeviceSaga(action: DeleteDeviceAction) {
  yield put(trackFetchAction('deleteDevice'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);

    yield call(deleteDeviceEnrollmentRequest, token, action.systemId, action.serialNumber);
    yield put(sendToastNotificationAction('success', 'The device has been successfully deleted.'));
    yield call(action.callback);
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('danger', `Device deletion failed: '${e.message}'`));
    } else {
      yield put(
        sendToastNotificationAction(
          'danger',
          'An unexpected error occurred while deleting the device. Try again later.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('deleteDevice'));
  }
}

export function* watchDeleteDevice() {
  yield cancelOnNavigationEffect(DELETE_DEVICE, deleteDeviceSaga);
}
