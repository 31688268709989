import config from 'react-global-configuration';
import urljoin from 'url-join';
import { httpGet } from './ServiceCaller';

const applicationStoreApi = 'applicationStoreApiUrl';

export type GetApplicationIdentitiesResponse = {
  response: {
    id: string;
    displayName: string;
    description: string;
    latestRelease: string;
    stage: string;
  }[];
};

export function getApplicationIdentitiesRequest(
  token: string
): Promise<GetApplicationIdentitiesResponse> {
  const url = urljoin(config.get(applicationStoreApi), 'v1/applications');
  return httpGet('ApplicationStore', token, url);
}

export type GetApplicationIdentityResponse = {
  response: {
    id: string;
    displayName: string;
    description: string;
    latestRelease: string;
    stage: string;
  };
};

export function getApplicationIdentityRequest(
  token: string,
  appId: string
): Promise<GetApplicationIdentityResponse> {
  const url = urljoin(config.get(applicationStoreApi), 'v1/applications', encodeURI(appId));
  return httpGet('ApplicationStore', token, url);
}

export type GetApplicationArtifactsResponse = {
  response: {
    version: string;
    description: string;
    stage: string;
  }[];
};

export type ApplicationArtifactResource = {
  name: string;
  type: string;
  displayName: string;
  parameters: ApplicationArtifactResourceParameter[];
};

export type ApplicationArtifactResourceParameter = {
  key: string;
  displayName: string;
  description: string;
  defaultValue?: string;
};

export function getApplicationArtifactsRequest(
  token: string,
  appId: string
): Promise<GetApplicationArtifactsResponse> {
  const url = urljoin(
    config.get(applicationStoreApi),
    'v1/applications',
    encodeURI(appId),
    'artifacts'
  );
  return httpGet('ApplicationStore', token, url);
}

export type GetApplicationArtifactResponse = {
  response: {
    version: string;
    description: string;
    stage: string;
    resources: ApplicationArtifactResource[];
  };
};

export function getApplicationArtifactRequest(
  token: string,
  appId: string,
  appVersion: string
): Promise<GetApplicationArtifactResponse> {
  const url = urljoin(
    config.get(applicationStoreApi),
    'v1/applications',
    encodeURI(appId),
    'artifacts',
    encodeURI(appVersion)
  );

  return httpGet('ApplicationStore', token, url);
}
