import { fetchSystemAction } from 'Actions/System/SystemActions';
import { DevicePage } from 'Components/Device/DevicePage';
import { PrivateRoute } from 'Components/PrivateRoute';
import TopNav from 'Components/Topnav/TopNav';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Switch } from 'react-router';
import { RootState } from 'Reducers/RootReducer';
import { Dispatch } from 'redux';
import { SystemLandingPage } from './SystemLandingPage';
import { SystemSelectionLandingPage } from './SystemSelectionLandingPage';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { DeviceDetailsPage } from 'Components/Device/DeviceDetailsPage';
import { UsersPage } from 'Components/Users/UsersPage';
import { fetchUserSystemPermissionsAction } from 'Actions/User/UserActions';
import { oc } from 'ts-optchain';
import { RolesPage } from 'Components/Roles/RolesPage';
import { FirmwareUpgradeHistoryWrapper } from 'Components/Device/DeviceSummary/DeviceFirmwareUpgradeHistory/FirmwareUpgradeHistoryWrapper';

type RouteMatch = {
  systemId: string;
};

type Props = RouteComponentProps<RouteMatch> & {
  userId: string;
  systemId?: string;
  errorFetchingSystemInfo?: string;
  systemInfoFetched: boolean;
  showUsersPage: boolean;
  showRolesPage: boolean;
  onLoad: (systemId: string, userId: string) => void;
  sendErrorNotification: (message: string) => void;
};

export function _SystemPathRouter(props: Props) {
  useEffect(() => {
    if (!props.systemId) {
      props.sendErrorNotification(
        'The system you are trying to access is invalid. Select one of your systems before continuing.'
      );
    } else {
      props.onLoad(props.systemId, props.userId);
    }
  }, [props.systemId]);

  if (!props.systemId) {
    return (
      <Fragment>
        <TopNav systemId="" showUsersPage={false} showRolesPage={false} />
        <SystemSelectionLandingPage autoSelectFirst={false} />
      </Fragment>
    );
  }

  if (!props.systemInfoFetched) {
    return null;
  }

  return (
    <Fragment>
      <TopNav
        systemId={props.match.params.systemId}
        showUsersPage={props.showUsersPage}
        showRolesPage={props.showRolesPage}
      />
      {props.errorFetchingSystemInfo ? (
        <div id="content">
          <div className={'alert alert-danger'}>
            There was an error when retrieving your system info. Please try again later.
          </div>
        </div>
      ) : (
        <Switch>
          <PrivateRoute
            exact
            path="/systems/:systemId"
            render={props => <SystemLandingPage systemId={props.match.params.systemId} />}
          />
          <PrivateRoute
            exact
            path="/systems/:systemId/devices"
            render={props => <DevicePage systemId={props.match.params.systemId} />}
          />
          <PrivateRoute
            exact
            path="/systems/:systemId/devices/:serialNumber/firmwareUpgradeHistory"
            render={props => (
              <FirmwareUpgradeHistoryWrapper
                systemId={props.match.params.systemId}
                serialNumber={props.match.params.serialNumber}
              />
            )}
          />
          <PrivateRoute
            path="/systems/:systemId/devices/:serialNumber"
            render={props => (
              <DeviceDetailsPage
                systemId={props.match.params.systemId}
                serialNumber={props.match.params.serialNumber}
              />
            )}
          />
          {props.showUsersPage && (
            <PrivateRoute
              path="/systems/:systemId/Users"
              render={props => <UsersPage systemId={props.match.params.systemId} />}
            />
          )}
          {props.showRolesPage && (
            <PrivateRoute path="/systems/:systemId/roles" component={RolesPage} />
          )}
        </Switch>
      )}
    </Fragment>
  );
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps<RouteMatch>) {
  var userSystem = state.user.systems.find(s => s.id === ownProps.match.params.systemId);
  return {
    userId: oc(state).user.authUser.id() || '',
    systemId: userSystem ? userSystem.id : undefined,
    systemInfoFetched: state.system.infoRetrieved,
    errorFetchingSystemInfo: state.system.infoError,
    showUsersPage: state.user.permissions.ViewRoles || false,
    showRolesPage:
      state.user.permissions.ViewRolePermissions ||
      state.user.permissions.ViewRolePrincipals ||
      false,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onLoad: (systemId: string, userId: string) => {
      dispatch(fetchSystemAction(systemId));
      dispatch(fetchUserSystemPermissionsAction(systemId, userId));
    },
    sendErrorNotification: (text: string) => dispatch(sendToastNotificationAction('danger', text)),
  };
}

export const SystemPathRouter = connect(mapStateToProps, mapDispatchToProps)(_SystemPathRouter);
