import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import { SUBMIT_DEVICE_ENROLLMENT_FORM } from 'Actions/ActionTypes';
import {
  SubmitDeviceEnrollmentFormAction,
  setDeviceEnrollmentFormErrorsAction,
} from 'Actions/Form/DeviceEnrollmentFormActions';
import { put, select, call, cancel } from 'redux-saga/effects';
import { trackFetchAction, clearFetchAction } from 'Actions/Loading/LoadingActions';
import { RootState } from 'Reducers/RootReducer';
import { formToDeviceEnrollmentRequest } from 'Services/Device/DeviceEnrollmentAssembler';
import { enrollDeviceRequest } from 'Services/Device/DeviceIdentityProxy';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { fetchDevicesAction } from 'Actions/Device/DeviceListActions';
import {
  validateDeviceEnrollmentForm,
  hasErrors,
} from 'Services/Device/DeviceEnrollmentFormValidator';
import { ServiceCallerError } from 'Services/ServiceCaller';

export function* submitDeviceEnrollmentFormSaga(action: SubmitDeviceEnrollmentFormAction) {
  const errors = validateDeviceEnrollmentForm(action.form);
  yield put(setDeviceEnrollmentFormErrorsAction(errors));

  if (hasErrors(errors)) {
    yield put(sendToastNotificationAction('danger', 'Some fields require attention.'));
    yield cancel();
  }

  yield put(trackFetchAction('enrollDevice'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);
    const body = formToDeviceEnrollmentRequest(action.form);

    yield call(enrollDeviceRequest, token, action.systemId, body);
    yield put(sendToastNotificationAction('success', 'The device has been successfully enrolled.'));
    yield call(action.callback);
    yield put(fetchDevicesAction(action.systemId));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('danger', `Device enrollment failed: '${e.message}'`));
    } else {
      yield put(
        sendToastNotificationAction(
          'danger',
          'An unexpected error occurred while enrolling the device. Try again later.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('enrollDevice'));
  }
}

export function* watchSubmitDeviceEnrollmentForm() {
  yield cancelOnNavigationEffect(SUBMIT_DEVICE_ENROLLMENT_FORM, submitDeviceEnrollmentFormSaga);
}
