import {
  FETCH_SYSTEM,
  END_FETCH_SYSTEM,
  SET_CURRENT_SYSTEM,
  SET_CURRENT_SYSTEM_ERROR,
} from 'Actions/ActionTypes';
import { SystemInfo } from 'Models/System/System';

export type SystemActions =
  | FetchSystemAction
  | SetCurrentSystem
  | SetCurrentSystemError
  | EndFetchSystemAction;

export type FetchSystemAction = {
  type: typeof FETCH_SYSTEM;
  systemId: string;
};
export function fetchSystemAction(systemId: string): FetchSystemAction {
  return {
    type: FETCH_SYSTEM,
    systemId,
  };
}

export type SetCurrentSystem = {
  type: typeof SET_CURRENT_SYSTEM;
  system: SystemInfo;
};
export function setCurrentSystem(system: SystemInfo): SetCurrentSystem {
  return {
    type: SET_CURRENT_SYSTEM,
    system,
  };
}

export type SetCurrentSystemError = {
  type: typeof SET_CURRENT_SYSTEM_ERROR;
  error: string;
};
export function setCurrentSystemError(error: string): SetCurrentSystemError {
  return {
    type: SET_CURRENT_SYSTEM_ERROR,
    error,
  };
}

export type EndFetchSystemAction = {
  type: typeof END_FETCH_SYSTEM;
};
export function endFetchSystemAction(): EndFetchSystemAction {
  return {
    type: END_FETCH_SYSTEM,
  };
}
