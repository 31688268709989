import React from 'react';
import { ActionButtonWithConfirmationModal } from '../Util/Controls/ActionButtonWithConfirmationModal';
import {
  CreateDeviceJob,
  FirmwareUpgradeDeviceJob,
  JobType,
} from '../../Models/DeviceJobs/DeviceJobs';
import config from 'react-global-configuration';
import urljoin from 'url-join';
import styles from './Buttons.module.css';

type FirmwareUpgradeButtonProps = {
  serialNumber: string;
  systemId: string;
  disabled: boolean;
  firmwareName: string;
  firmwareVersion: string;
  imageName: string;
  createDeviceJob: (
    systemId: string,
    serialNumber: string,
    deviceJob: CreateDeviceJob,
    firmwareName: string
  ) => void;
};

export function FirmwareUpgradeButton(props: FirmwareUpgradeButtonProps) {
  const firmwareUrl: string = urljoin(
    config.get('firmwareRepoApiUrl'),
    '/v2/firmware',
    encodeURI(props.firmwareName),
    '/versions/',
    encodeURI(props.firmwareVersion),
    '/images',
    encodeURI(props.imageName),
    '/download'
  );

  function onConfirm() {
    const job: FirmwareUpgradeDeviceJob = {
      type: JobType.FirmwareUpgrade,
      body: {
        firmwareName: props.firmwareName,
        firmwareVersion: props.firmwareVersion,
        firmwareUrl: firmwareUrl,
      },
    };

    props.createDeviceJob(props.systemId, props.serialNumber, job, props.firmwareName);
  }

  return (
    <ActionButtonWithConfirmationModal
      actionButtonClassName={`${props.disabled ? styles.buttonDisabled : styles.buttonPrimary}`}
      modalButtonClassName="btn btn-primary"
      onConfirm={onConfirm}
      title={`Firmware Upgrade: ${props.serialNumber}`}
      text="Download update"
      disabled={props.disabled}
    >
      <div>
        <p>The device will be upgraded after reboot.</p>
        <p> Are you sure you want to continue? </p>
      </div>
    </ActionButtonWithConfirmationModal>
  );
}
