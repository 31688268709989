import {
  createDeviceJob,
  CreateDeviceJobResponse,
} from '../../Services/DeviceJobs/DeviceJobsProxy';
import {
  CreateDeviceJobAction,
  receiveDeviceJobAction,
} from '../../Actions/DeviceJobs/DeviceJobsActions';
import { RootState } from '../../Reducers/RootReducer';
import { CREATE_DEVICE_JOB } from '../../Actions/ActionTypes';
import { JobStatus } from '../../Models/DeviceJobs/DeviceJobs';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { fetchDeviceAction } from '../../Actions/Device/DeviceActions';
import { DeviceJobsAssembler } from '../../Services/DeviceJobs/DeviceJobsAssembler';
import { clearFetchAction, trackFetchAction } from '../../Actions/Loading/LoadingActions';
import { sendToastNotificationAction } from '../../Actions/Notification/NotificationActions';
import {
  ConflictError,
  GatewayTimeOutError,
  NotFoundError,
  ServiceCallerError,
} from 'Services/ServiceCaller';

export function* createDeviceJobSaga(action: CreateDeviceJobAction) {
  try {
    yield put(trackFetchAction('createDeviceJobSaga'));

    const token: string = yield select((state: RootState) => state.authentication.token);

    const apiJob = DeviceJobsAssembler.toApi(action.deviceJob);
    const response: CreateDeviceJobResponse = yield call(
      createDeviceJob,
      token,
      action.systemId,
      action.serialNumber,
      apiJob
    );
    const createDeviceJobResponse = response;

    if (
      createDeviceJobResponse.response.status === JobStatus.Completed ||
      createDeviceJobResponse.response.status === JobStatus.Accepted
    ) {
      const jobType = action.deviceJob.type.toString().replace('_', ' ');

      yield put(
        sendToastNotificationAction(
          'success',
          `Device's ${jobType} is starting, device will restart soon.`
        )
      );
    } else {
      yield put(
        sendToastNotificationAction('warning', `The device received the job, but the job failed.`)
      );
    }

    yield put(
      receiveDeviceJobAction(DeviceJobsAssembler.fromApi(createDeviceJobResponse.response))
    );
  } catch (e) {
    if (e instanceof NotFoundError) {
      yield put(
        sendToastNotificationAction(
          'warning',
          'Failed to send the job because the device is offline.'
        )
      );
    } else if (e instanceof ConflictError) {
      yield put(
        sendToastNotificationAction(
          'warning',
          'The device rejected the job because it cannot currently process it.'
        )
      );
    } else if (e instanceof GatewayTimeOutError) {
      yield put(
        sendToastNotificationAction(
          'warning',
          'The device did not respond within the expected time.'
        )
      );
    } else if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      console.log(e);
      yield put(
        sendToastNotificationAction('warning', 'An error occurred while creating the job.')
      );
    }
  } finally {
    yield put(clearFetchAction('createDeviceJobSaga'));
    // update the device status
    yield put(fetchDeviceAction(action.systemId, action.serialNumber));
  }
}

export function* watchDeviceJobsCreateJob() {
  yield takeEvery(CREATE_DEVICE_JOB, createDeviceJobSaga);
}
