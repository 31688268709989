import { useState } from 'react';
import * as React from 'react';

type InputComponentProps = {
  title: string;
  value: string;
  errorMsg?: string;
  focusByDefault?: boolean;
  placeholder?: string;
  onChange: (e: any) => void;
  onBlur?: () => void;
  testId?: string;
};

function Input(props: InputComponentProps) {
  const [hasFocus, setFocus] = useState(false);
  return (
    <React.Fragment>
      <label className={hasFocus ? 'focus' : ''}>{props.title}</label>
      <input
        className="form-control"
        value={props.value}
        autoFocus={props.focusByDefault}
        onChange={e => props.onChange(e.target.value)}
        onFocus={() => setFocus(true)}
        placeholder={props.placeholder}
        onBlur={() => {
          setFocus(false);
          props.onBlur && props.onBlur();
        }}
        data-testid={props.testId}
      />
      <span className={'help-block' + (props.errorMsg ? ' has-error' : '')}>
        {props.errorMsg || ''}
      </span>
    </React.Fragment>
  );
}

export default Input;
