import { SILENTLY_FETCH_DEVICE_LIST } from 'Actions/ActionTypes';
import {
  SilentlyFetchDeviceListAction,
  _receiveDeviceListAction,
} from 'Actions/Device/DeviceListActions';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  GetEnrolledDeviceResponse,
  getEnrolledDevicesRequest,
} from 'Services/Device/DeviceIdentityProxy';
import { fromDeviceListApiResponse } from 'Services/Device/DeviceListAssembler';

export function* silentlyFetchDeviceListSaga(action: SilentlyFetchDeviceListAction) {
  const token: string = yield select((state: RootState) => state.authentication.token);

  try {
    const response: GetEnrolledDeviceResponse[] = yield call(
      getEnrolledDevicesRequest,
      token,
      action.systemId
    );

    yield put(_receiveDeviceListAction(fromDeviceListApiResponse(response)));
  } catch {
    // Ignored
  }
}

export function* watchSilentlyFetchDeviceList() {
  yield takeLatest(SILENTLY_FETCH_DEVICE_LIST, silentlyFetchDeviceListSaga);
}
