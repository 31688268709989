import React from 'react';
import { ConfirmationModal } from '../Modal';
import { RootState } from 'Reducers/RootReducer';
import { _acceptConfirmationAction, _cancelConfirmationAction } from 'Actions/Modal/ModalActions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

type Props = {
  visible: boolean;
  message: string;
  accept: () => void;
  cancel: () => void;
  callback: () => void;
};

export function _ConnectedConfirmationModal(props: Props) {
  return (
    <ConfirmationModal
      text={props.message}
      show={props.visible}
      onCancel={props.cancel}
      onConfirm={() => {
        props.accept();
        props.callback();
      }}
    />
  );
}

function mapStateToProps(state: RootState) {
  return {
    visible: state.confirmationModal.visible,
    message: state.confirmationModal.message,
    callback: state.confirmationModal.completionCallback,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    accept: () => dispatch(_acceptConfirmationAction()),
    cancel: () => dispatch(_cancelConfirmationAction()),
  };
}

export const ConnectedConfirmationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ConnectedConfirmationModal);
