import React from 'react';
import { ConnectedNavLink } from 'Components/Util/Browser/ConnectedNavLink';
import styles from './Roles.module.css';
import { BasicPage } from 'Components/Util/Page';
import { Role } from 'Models/Authorization';

type RoleListItem = {
  id: Role;
  name: string;
};

type Props = {
  systemId: string;
  roles: RoleListItem[];
};

export function RolesList(props: Props) {
  return (
    <BasicPage>
      <div className="client-portal-section" data-testid="system-roles-list">
        <div className="client-portal-table-header">
          <h2>System Roles ({props.roles.length})</h2>
        </div>

        <table className="client-portal-table table">
          <thead>
            <tr>
              <th>Role name</th>
            </tr>
          </thead>
          <tbody>
            {props.roles.map(role => (
              <tr data-testid={`system-role-row-${role.id}`} key={role.id}>
                <td className="td-xs">
                  <ConnectedNavLink
                    to={`/systems/${props.systemId}/roles/${role.id}/details`}
                    className={styles.roleListLink}
                  >
                    {role.name}
                  </ConnectedNavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </BasicPage>
  );
}
