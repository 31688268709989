import * as React from 'react';
import { Route, RouteProps } from 'react-router';
import { AuthenticatedUser, authenticationService } from '../Authentication/AuthenticationService';
import { RootState } from 'Reducers/RootReducer';
import { connect } from 'react-redux';

type Props = RouteProps & {
  authUser?: AuthenticatedUser;
};

export function _PrivateRoute({ authUser, ...rest }: Props) {
  return authUser && authUser.isAuthenticated ? <Route {...rest} /> : <LoginRedirect {...rest} />;
}

const LoginRedirect = (props: RouteProps) => {
  authenticationService.redirectToLogin(props.location ? props.location.pathname : '/');
  return null;
};

function mapStateToProps(state: RootState) {
  return {
    authUser: state.user.authUser,
  };
}

export const PrivateRoute = connect(mapStateToProps)(_PrivateRoute);
