import * as React from 'react';
import styles from './ActionButton.module.css';

type Props = {
  className?: string;
  onClick: () => void;
  disabled: boolean;
  testId?: string;
  text?: string;
  icon?: string;
};

export function ActionButton(props: Props) {
  const className = `${styles.actionButton} ${props.className ? props.className : ''}`;
  return (
    <button
      className={className}
      onClick={e => {
        props.onClick();
        e.stopPropagation();
      }}
      data-testid={props.testId}
      disabled={props.disabled}
    >
      {props.text}
      {props.icon && <i className={props.icon} />}
    </button>
  );
}
