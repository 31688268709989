import * as React from 'react';
import { RolePermissionsMap } from 'Models/Authorization';
import styles from './Roles.module.css';

type Props = {
  permissions: RolePermissionsMap;
};

export function RolePermissions(props: Props) {
  return (
    <>
      {Object.keys(props.permissions).map(permissionSet => (
        <div className={styles.rolePermissionRow} key={`permissions-${permissionSet}`}>
          <h4 className={styles.permissionSetTitle} data-testid={`permission-set-${permissionSet}`}>
            {permissionSet}
          </h4>
          <ul className="permission-list">
            {props.permissions[permissionSet].map(permission => (
              <li
                className="permission-list-item has-access"
                key={permission.name}
                data-testid={`permission-${permissionSet}-${permission.name}`}
              >
                {permission.name}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
}
