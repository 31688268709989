import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Reducers/RootReducer';
import { TimedAlert } from './Util/TimedAlert';
import { ToastNotification } from 'Reducers/Notification/NotificationReducer';

type Props = {
  notifications: ToastNotification[];
};

export const ToastNotificationContainer = connect(mapStateToProps)((props: Props) => {
  const storeNotifications = props.notifications.map(n => (
    <TimedAlert message={n.message} type={n.notificationType} delay={5000} key={n.id} />
  ));

  return (
    <div
      style={{
        position: 'fixed',
        right: '10px',
        width: '350px',
        zIndex: 50000,
      }}
      className="toaster"
      id="toaster"
    >
      {storeNotifications}
    </div>
  );
});

function mapStateToProps(state: RootState) {
  return {
    notifications: state.notifications,
  };
}
