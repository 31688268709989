import { AuthenticationActions } from 'Actions/AuthenticationActions';
import { SET_TOKEN } from 'Actions/ActionTypes';

export type AuthenticationReducerState = {
  token: string;
};

const emptyState = {
  token: '',
};

export function authenticationReducer(
  state: AuthenticationReducerState = emptyState,
  action: AuthenticationActions
) {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, token: action.token };
    default:
      return state;
  }
}
