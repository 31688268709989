import { UNINSTALL_APPLICATION } from 'Actions/ActionTypes';
import { UnistallApplicationAction } from 'Actions/Application/ApplicationActions';
import { fetchDeviceAction } from 'Actions/Device/DeviceActions';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select } from 'redux-saga/effects';
import { withNavigationGate } from 'Sagas/WithNavigationGate';
import { deleteApplicationDeploymentRequest } from 'Services/AppDeploymentProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';

export function* uninstallApplicationSaga(action: UnistallApplicationAction) {
  yield put(trackFetchAction('deleteApplication'));
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);

    yield call(
      deleteApplicationDeploymentRequest,
      token,
      action.systemId,
      action.serialNumber,
      action.deploymentId
    );
    yield put(sendToastNotificationAction('success', 'The application will be removed shortly.'));
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      console.log(e);
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occurred while uninstalling the application.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('deleteApplication'));
  }
  return action;
}

function* afterCompletion(action: UnistallApplicationAction) {
  yield put(fetchDeviceAction(action.systemId, action.serialNumber));
}

export function* watchUninstallApplication() {
  yield withNavigationGate(
    UNINSTALL_APPLICATION,
    uninstallApplicationSaga,
    afterCompletion,
    () => undefined
  );
}
