import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { RootState } from 'Reducers/RootReducer';
import { Role, RolePermissionsMap } from 'Models/Authorization';
import {
  fetchRolePermissions as fetchRolePermissionsAction,
  fetchRolePrincipals as fetchRolePrincipalsAction,
} from 'Actions/Authorization/AuthorizationActions';
import { LightPrincipal } from 'Components/Users/Types';
import { RoleDetailsPageView } from './RoleDetailsPageView';
import { RoleDetailsTab } from './Types';

type RouteParams = {
  systemId: string;
  roleName: Role;
};

type RoleDetailsPageProps = RouteComponentProps<RouteParams> & {
  permissions: RolePermissionsMap;
  principals: string[];
  systemUsers: LightPrincipal[];
  canViewRoleUsers: boolean;
  canViewRolePermissions: boolean;
  onLoad: (systemId: string, role: Role) => void;
};

export function _RoleDetailsPage(props: RoleDetailsPageProps) {
  const {
    match: { params },
  } = props;

  const [activeTab, setActiveTab] = useState<RoleDetailsTab>('members');

  useEffect(() => {
    props.onLoad(params.systemId, params.roleName);
  }, [params.systemId, params.roleName]);

  return (
    <RoleDetailsPageView
      activeTab={activeTab}
      canViewRolePermissions={props.canViewRolePermissions}
      canViewRoleUsers={props.canViewRoleUsers}
      permissions={props.permissions}
      systemUsers={props.systemUsers}
      principals={props.principals}
      roleName={params.roleName}
      systemId={params.systemId}
      selectTab={setActiveTab}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  permissions: state.roles.permissions,
  principals: state.roles.principals,
  systemUsers: state.pages.users.users,
  canViewRoleUsers: state.user.permissions.ViewRolePrincipals!,
  canViewRolePermissions: state.user.permissions.ViewRolePermissions!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLoad: (systemId: string, role: Role) => {
    dispatch(fetchRolePermissionsAction(systemId, role));
    dispatch(fetchRolePrincipalsAction(systemId, role));
  },
});

export const RoleDetailsPage = connect(mapStateToProps, mapDispatchToProps)(_RoleDetailsPage);
