import React, { Fragment, useState } from 'react';
import { SearchBox } from 'Components/Util/Controls/SearchBox';
import { InstallableApplicationCard } from './InstallableApplicationCard';
import styles from './ApplicationList.module.css';
import { ApplicationIdentity } from 'Models/Application/ApplicationStore';

type Props = {
  applications: ApplicationIdentity[];
  onAppClick: (appId: string) => void;
};

export function ApplicationList(props: Props) {
  const [searchFilter, setSearchFilter] = useState('');
  return (
    <Fragment>
      <div className="limit-width">
        <SearchBox
          value={searchFilter}
          onChange={setSearchFilter}
          placeholder="Search"
          autoFocus={true}
        />
      </div>
      <div className={styles.applicationList}>
        {props.applications
          .filter(
            app =>
              app.displayName.toLowerCase().includes(searchFilter.toLowerCase()) ||
              app.id.includes(searchFilter.toLowerCase())
          )
          .map(app => (
            <InstallableApplicationCard
              key={app.id}
              application={app}
              onClick={() => props.onAppClick(app.id)}
            />
          ))}
      </div>
    </Fragment>
  );
}
