import {
  FETCH_USER_INVITATION_LIST,
  RECEIVE_USER_INVITATION_LIST_FROM_API,
  DELETE_USER_INVITATION,
  CREATE_USER_INVITATION,
  CREATE_SERVICE_REGISTRATION,
} from 'Actions/ActionTypes';
import { UserInvitation } from 'Models/Users/UserInvitation';

export type FetchUserInvitationListAction = {
  type: typeof FETCH_USER_INVITATION_LIST;
  systemId: string;
};

export function fetchUserInvitationListAction(systemId: string): FetchUserInvitationListAction {
  return {
    type: FETCH_USER_INVITATION_LIST,
    systemId,
  };
}

export type ReceiveUserInvitationListFromApiAction = {
  type: typeof RECEIVE_USER_INVITATION_LIST_FROM_API;
  invitations: UserInvitation[];
};

export function receiveUserInvitationListFromApiAction(
  invitations: UserInvitation[]
): ReceiveUserInvitationListFromApiAction {
  return {
    type: RECEIVE_USER_INVITATION_LIST_FROM_API,
    invitations,
  };
}

export type DeleteUserInvitationAction = {
  type: typeof DELETE_USER_INVITATION;
  username: string;
  systemId: string;
};

export function deleteUserInvitationAction(
  systemId: string,
  username: string
): DeleteUserInvitationAction {
  return {
    type: DELETE_USER_INVITATION,
    username,
    systemId,
  };
}

export type CreateUserInvitationAction = {
  type: typeof CREATE_USER_INVITATION;
  systemId: string;
  email: string;
  role: string;
};

export type CreateServiceRegistrationAction = {
  type: typeof CREATE_SERVICE_REGISTRATION;
  systemId: string;
  clientId: string;
  serviceName: string;
  role: string;
};

export function createUserInvitationAction(
  systemId: string,
  email: string,
  role: string
): CreateUserInvitationAction {
  return {
    type: CREATE_USER_INVITATION,
    systemId,
    email,
    role,
  };
}

export function createServiceRegistrationAction(
  systemId: string,
  clientId: string,
  serviceName: string,
  role: string
): CreateServiceRegistrationAction {
  return {
    type: CREATE_SERVICE_REGISTRATION,
    systemId,
    clientId,
    serviceName,
    role,
  };
}
