import * as React from 'react';

export type MsgType = 'success' | 'info' | 'warning' | 'danger';

type MsgProps = {
  type?: MsgType;
  show: boolean;
  onClose: () => void;
  msg: string;
};

export function StickyMsg(props: MsgProps) {
  let type = 'success';
  if (props.type !== undefined && props.type !== null) {
    type = props.type;
  }

  if (props.show) {
    return (
      <div
        className={'alert alert-' + type + ' alert-sticky alert-dismissable fade in'}
        role="alert"
      >
        <button type="button" className="close" onClick={() => props.onClose()} aria-hidden="true">
          x
        </button>
        {props.msg}
      </div>
    );
  } else {
    return null;
  }
}

export function AlertMsg(props: MsgProps) {
  let type = 'success';
  if (props.type !== undefined && props.type !== null) {
    type = props.type;
  }

  if (props.show) {
    return (
      <div className={'alert alert-' + type + ' alert-dismissable'}>
        <button type="button" className="close" onClick={() => props.onClose()} aria-hidden="true">
          x
        </button>
        {props.msg}
      </div>
    );
  } else {
    return null;
  }
}
