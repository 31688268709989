import config from 'react-global-configuration';
import { httpGet } from './ServiceCaller';
import urljoin from 'url-join';

export type GetSystemResponse = {
  response: {
    id: string;
    name: string;
    systemType: string;
  };
};

export type GetFeatureFlagsResponse = {
  response: Map<string, boolean>;
};

export type FeatureFlags = string;

const systemsApi = 'systemsApiUrl';

export async function getSystemRequest(
  token: string,
  systemId: string
): Promise<GetSystemResponse> {
  const url = urljoin(config.get(systemsApi), '/v1/systems', systemId);
  return await httpGet('GetSystemById', token, url);
}

export function getSystemFeatureFlags(token: string, systemId: string) {
  const url = urljoin(config.get(systemsApi), 'v1/systems', encodeURI(systemId), 'features/states');
  return httpGet('GetSystemFeatureFlags', token, url);
}
