import * as React from 'react';

type Props = {
  id: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => any;
  testId?: string;
};

export function CheckBox(props: Props) {
  return (
    <div className="checkbox checkbox-primary">
      <input
        id={props.id}
        className="styled filled"
        type="checkbox"
        onChange={event => props.onChange(event.target.checked)}
        checked={props.checked}
        disabled={props.disabled}
        data-testid={props.testId || 'checkbox'}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  );
}
