import * as React from 'react';
import { useEffect, useState } from 'react';
import { AlertMsg, MsgType } from './Notifications';

type TimedAlertProps = {
  message: string;
  type: MsgType;
  delay: number;
};

type TimedAlertVisibilityState = 'created' | 'visible' | 'destroying' | 'destroyed';

export function TimedAlert(props: TimedAlertProps) {
  const [visibilityState, setVisibilityState] = useState<TimedAlertVisibilityState>('created');

  useEffect(() => {
    // Change the visibility state to "visible" after the component has been created to cause a fading animation.
    setVisibilityState('visible');
    setTimeout(() => {
      setVisibilityState('destroying');
    }, props.delay);
  }, []);

  useEffect(() => {
    if (visibilityState === 'destroying') {
      setTimeout(() => {
        setVisibilityState('destroyed');
      }, 200);
    }
  }, [visibilityState]);

  return (
    <div className={`fade ${visibilityState === 'visible' ? 'in' : ''}`}>
      <AlertMsg
        msg={props.message}
        show={visibilityState !== 'destroyed'}
        onClose={() => setVisibilityState('destroying')}
        type={props.type}
      />
    </div>
  );
}
