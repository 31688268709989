import React from 'react';

type Props = {
  field: string; // the compared field. (attribute on object)
  displayName: string; // The name shown inside the table cell
  sorter: {
    toggleComparator: (field: string) => void;
    comparedField: string;
    direction: 'ascending' | 'descending';
  };
  className?: string;
};

export function SortableTh(props: Props) {
  const directionClass =
    props.sorter.comparedField === props.field
      ? styleClasses[props.sorter.direction]
      : styleClasses['none'];

  return (
    <th
      onClick={() => props.sorter.toggleComparator(props.field)}
      className={`cursor-pointer ${props.className || ''} ${directionClass}`}
    >
      {props.displayName}
    </th>
  );
}
const styleClasses = {
  ascending: 'sorting_asc',
  descending: 'sorting_desc',
  none: 'sorting',
};
