import { RECEIVE_FIRMWARE_UPGRADE_HISTORY } from 'Actions/ActionTypes';
import { ReceiveFirmwareUpgradeHistoryAction } from 'Actions/Device/FirmwareUpgradeHistoryActions';
import { FirmwareUpgradeHistory } from 'Models/Device/FirmwareUpgradeHistory';

type FirmwareUpgradeHistoryReducerState = FirmwareUpgradeHistory[];

type FirmwareUpgradeHistoryReducerActions = ReceiveFirmwareUpgradeHistoryAction;

const defaultState: FirmwareUpgradeHistoryReducerState = [];

export function firmwareUpgradeHistoryReducer(
  state: FirmwareUpgradeHistoryReducerState = defaultState,
  action: FirmwareUpgradeHistoryReducerActions
) {
  switch (action.type) {
    case RECEIVE_FIRMWARE_UPGRADE_HISTORY:
      return action.firmwareUpgradeHistory;
    default:
      return state;
  }
}
