import {
  APPLICATION_INSTALLATION_BACKTRACK_WIZARD,
  APPLICATION_INSTALLATION_SELECT_APPLICATION,
  APPLICATION_INSTALLATION_UPDATE_PARAMETER_VALUES,
  CLEAR_APPLICATION_INSTALLATION_FORM,
  APPLICATION_INSTALLATION_RECEIVE_SELECTED_ARTIFACT,
  SHOW_APPLICATION_INSTALLATION_FORM,
  SHOW_APPLICATION_INSTALLATION_UPDATE_FORM,
} from 'Actions/ActionTypes';
import {
  ApplicationEditParameter,
  ApplicationInstallationBacktrackWizardAction,
  ApplicationInstallationSelectApplicationAction,
  ReceiveSelectedApplicationArtifactAction,
  FetchSelectedArtifactAction,
  ApplicationInstallationUpdateParameterValuesAction,
  ClearApplicationInstallationFormAction,
  ShowApplicationInstallationFormAction,
  ShowApplicationInstallationUpdateFormAction,
} from 'Actions/Form/ApplicationInstallationFormActions';
import {
  ApplicationDeploymentDetails,
  DeployedResourceParameterDetails,
} from 'Models/Application/ApplicationDeployment';
import { ApplicationIdentity, ApplicationArtifact } from 'Models/Application/ApplicationStore';
import { constructParameterScopedKey } from '../../Services/AppDeployment/DeploymentParameterUtil';
import { APPLICATION_EDIT_PARAMETER } from 'Actions/ActionTypes';

export type ApplicationInstallaltionFormStage = 'hidden' | 'list' | 'details' | 'parameters';

export type ApplicationInstallationFormState = {
  stage: ApplicationInstallaltionFormStage;
  application: ApplicationIdentity | null;
  currentlyInstalled: ApplicationDeploymentDetails | null;
  artifact: ApplicationArtifact | null;
  canBacktrack: boolean;
  parameters: { [key: string]: DeployedResourceParameterDetails };
};

type Actions =
  | ShowApplicationInstallationFormAction
  | ShowApplicationInstallationUpdateFormAction
  | ApplicationInstallationBacktrackWizardAction
  | ApplicationInstallationSelectApplicationAction
  | FetchSelectedArtifactAction
  | ApplicationInstallationUpdateParameterValuesAction
  | ApplicationEditParameter
  | ReceiveSelectedApplicationArtifactAction
  | ClearApplicationInstallationFormAction;

const defaultState: ApplicationInstallationFormState = {
  stage: 'hidden',
  application: null,
  artifact: null,
  currentlyInstalled: null,
  canBacktrack: true,
  parameters: {},
};

export function applicationInstallationFormReducer(
  state: ApplicationInstallationFormState = defaultState,
  action: Actions
): ApplicationInstallationFormState {
  switch (action.type) {
    case SHOW_APPLICATION_INSTALLATION_FORM:
      return { ...state, stage: 'list', canBacktrack: true };
    case SHOW_APPLICATION_INSTALLATION_UPDATE_FORM:
      return {
        ...state,
        stage: 'details',
        currentlyInstalled: action.currentlyInstalled,
        canBacktrack: true,
      };
    case APPLICATION_INSTALLATION_BACKTRACK_WIZARD:
      if (state.stage === 'details') {
        return { ...state, stage: 'list', application: null };
      } else if (state.stage === 'parameters') {
        return { ...state, stage: 'details', artifact: null };
      } else {
        return state;
      }
    case APPLICATION_INSTALLATION_SELECT_APPLICATION:
      return { ...state, application: action.application, stage: 'details' };
    case APPLICATION_INSTALLATION_RECEIVE_SELECTED_ARTIFACT:
      return {
        ...state,
        artifact: action.artifact,
        stage: 'parameters',
        parameters: computeDefaultParameterValues(state.currentlyInstalled),
      };
    case APPLICATION_EDIT_PARAMETER: {
      return {
        ...state,
        artifact: action.artifact,
        stage: 'parameters',
        currentlyInstalled: action.currentlyInstalled,
        parameters: computeDefaultParameterValues(action.currentlyInstalled),
        canBacktrack: false,
      };
    }
    case APPLICATION_INSTALLATION_UPDATE_PARAMETER_VALUES:
      const parameters = computeUpdateParameterValues(action.values, state.parameters);
      return { ...state, parameters: { ...state.parameters, ...parameters } };
    case CLEAR_APPLICATION_INSTALLATION_FORM:
      return defaultState;
    default:
      return state;
  }
}

function computeDefaultParameterValues(
  currentlyInstalled: ApplicationDeploymentDetails | null
): { [key: string]: DeployedResourceParameterDetails } {
  var deployedParameterValues: { [scopedKey: string]: DeployedResourceParameterDetails } = {};

  if (currentlyInstalled !== null)
    (currentlyInstalled.deployedResources || []).forEach(resource => {
      resource.parameters.forEach(parameter => {
        deployedParameterValues[
          constructParameterScopedKey(resource.name, parameter.key)
        ] = parameter;
      });
    });
  return deployedParameterValues;
}

function computeUpdateParameterValues(
  values: { [key: string]: { value: string; isCustomValue: boolean } },
  stateParameters: { [key: string]: DeployedResourceParameterDetails }
) {
  const parameters = stateParameters;
  Object.entries(values).forEach(([key, parameter]) => {
    if (!parameters[key]) {
      stateParameters[key] = {
        key: key,
        value: parameter.value,
        isCustomValue: parameter.isCustomValue,
      };
    } else {
      parameters[key].isCustomValue = parameter.isCustomValue;
      parameters[key].value = parameter.value;
    }
  });
  return parameters;
}
